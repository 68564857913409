import React, { useContext } from 'react';
import BasePhoneInput, { type PhoneInputProps } from 'react-phone-input-2';
import GlobalContext from '@konsus/lego/src/contexts/GlobalContext';
import { cn } from '../../lib';
import './phone-input.css';
import { baseClasses } from '../TextInput/styles';

export const PhoneInput: React.FC<PhoneInputProps & { className?: string }> = ({
  className,
  inputClass,
  buttonClass,
  dropdownClass,
  ...rest
}) => {
  const { useGeoLocation } = useContext(GlobalContext) || {};
  const geoLocation = useGeoLocation?.();
  const countryCode = geoLocation && geoLocation.country;

  return (
    <BasePhoneInput
      country={countryCode?.toLowerCase() || ''}
      containerClass={cn('tel-input relative w-full [&_*]:box-border', className)}
      inputClass={cn(
        baseClasses,
        'relative z-20 !block h-auto w-full max-w-[80%] !rounded border py-5 pl-14',
        inputClass
      )}
      buttonClass={cn(
        'absolute top-0 bottom-0 w-full max-w-full border-0 bg-transparent',
        '[&.open]:z-10 [&.open]:border-0 [&.open]:bg-transparent',
        '[&.open.selected-flag]:border-0',
        '[&_.selected-flag]:relative [&_.selected-flag]:z-30 [&_.selected-flag]:h-full [&_.selected-flag]:w-12 [&_.selected-flag]:pl-3',
        '[&_.selected-flag_.flag]:absolute [&_.selected-flag_.flag]:top-[calc(50%-5px)]',
        '[&_.arrow]:border-y-bor-neutral-400 [&_.arrow]:border-b-none [&_.arrow]:relative [&_.arrow]:top-[calc(50%-2px)] [&_.arrow]:left-6 [&_.arrow]:h-0 [&_.arrow]:w-0 [&_.arrow]:border-x-4 [&_.arrow]:border-t-4 [&_.arrow]:border-x-transparent [&_.arrow.up]:border-t-0 [&_.arrow.up]:border-b-4',
        buttonClass
      )}
      dropdownClass={cn(
        'text-bor-foreground bg-bor-background 1280:text-lg relative top-0 bottom-0 my-2 -ml-px max-h-[300px] w-full max-w-full overflow-y-scroll rounded-b-md p-0',
        'hover:[&_li]:bg-bor-neutral-200/50 [&_li.highlight]:bg-bor-neutral-200 [&_.flag]:mr-2 [&_.flag]:mt-px [&_.flag]:inline-block [&_.dial-code]:text-xs [&_.dial-code]:font-bold [&_li]:cursor-pointer [&_li]:p-2 [&_li.highlight]:pointer-events-none [&_.country-name]:mr-2',
        dropdownClass
      )}
      specialLabel=''
      {...rest}
    />
  );
};

PhoneInput.displayName = 'TextInput';

export default PhoneInput;
