export interface ComponentStyles {
  className?: string | string[];
  style?: { [prop: string]: string };
}

export type StyleFn = (props: any, theme: any) => ComponentStyles;

export const composeStyles = (...styles: StyleFn[]) => {
  const handlers = Object.values(styles);

  return (props, theme) =>
    handlers.reduce<ComponentStyles>(
      (acc, fn) => {
        const { style, className } = fn(props, theme);

        if (!className) {
          return acc;
        }

        return {
          style: {
            ...acc.style,
            ...style
          },
          className:
            typeof className === 'string'
              ? [...acc.className, className]
              : [...acc.className, ...className]
        };
      },
      { style: {}, className: [] }
    );
};
