import React from 'react';
import { LazyMotion, domAnimation } from 'framer-motion';
import { GlobalStyle, BrowserFeaturesUpdater, ScreenSizeUpdater } from '@konsus/superside-kit';
import Loader from '@superside/ui/src/Utilities/Loader';
import { useAnalytics } from '@konsus/analytics';
import { LegoGlobalContextProvider, blockByType } from '@konsus/lego';
import { useGeoLocation, usePagesTransitionTracker, useBackgroundImage } from '@konsus/hooks';
import { useScrollRestoration } from '@konsus/utils';
import ChilipiperLoader from '@konsus/lego/src/components/ChilipiperLoader';
import { setupDatadog } from '@konsus/apps-common';
import { ThirdParty } from '../components/scripts/ThirdParty';
import { config } from '../constants/config';
import '@superside/ui/tailwind/main.css';
import '@konsus/apps-common/src/BackgroundStyles.css';

setupDatadog({ service: 'superside-website', version: '2.28.5' });

const NextApp = (props: any) => {
  const { Component, pageProps, router } = props;
  const { track } = useAnalytics();
  const { pageTransitionInProgress } = usePagesTransitionTracker({ track });

  useBackgroundImage(pageProps);
  useScrollRestoration(router);

  return (
    <>
      <BrowserFeaturesUpdater />
      <ScreenSizeUpdater />
      <ThirdParty />
      <GlobalStyle />
      <ChilipiperLoader />
      <LazyMotion features={domAnimation}>
        <LegoGlobalContextProvider
          source='desktop'
          utm={config.utm}
          gclid={config.gclid}
          useGeoLocation={useGeoLocation}
          track={track}
          blockByType={blockByType}
          {...pageProps.legoBlocksData}
        >
          <Component {...pageProps} />
          {pageTransitionInProgress ? <Loader /> : null}
        </LegoGlobalContextProvider>
      </LazyMotion>
    </>
  );
};

export default NextApp;
