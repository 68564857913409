import { cva } from 'class-variance-authority';
import { cn } from '../../lib';
import * as buttonStyles from './Button.module.css';

export const baseClasses = 'group/button overflow-hidden rounded font-semibold text-center';

export const disabledClasses =
  'disabled:text-bor-neutral-300 disabled:cursor-not-allowed disabled:dark:hover:shadow-none disabled:after';

export const disabledClassesWithBackground = cn(disabledClasses, 'disabled:bg-bor-neutral-100');

export const primaryClasses = cn(
  'bg-bor-primary text-bor-background dark:hover:shadow-blurry text-sm leading-5 transition-shadow',
  disabledClassesWithBackground
);

export const secondaryClasses = cn(
  'text-bor-foreground bg-bor-secondary-100 hover:bg-bor-secondary-200 dark:bg-bor-light/20 dark:hover:bg-bor-light/30',
  disabledClassesWithBackground
);

export const ghostClasses = cn(
  'text-bor-foreground disabled:text-bor-neutral-300 relative flex flex-row items-center gap-1 py-2 pl-4 pr-3 transition-all disabled:cursor-not-allowed',
  disabledClasses
);

export const outlineClasses = cn([
  'text-bor-foreground rounded border-2 bg-transparent text-center text-sm leading-5 transition-colors duration-200',
  disabledClasses
]);

export const isolatedClasses =
  'hover:bg-bor-dark hover:text-bor-light dark:hover:text-dark dark:hover:border-bor-primary dark:hover:bg-bor-primary disabled:bg-transparent disabled:dark:bg-transparent';

export const glowyClasses = `${buttonStyles.glowyBorder} text-bor-background hover:shadow-blurry bg-bor-primary relative overflow-visible p-0.5 leading-5 transition-shadow text-sm`;

const iconButtonClasses = 'flex items-center justify-center';

export const iconFillClasses = cn(
  'dark:hover:shadow-blurry text-dark bg-bor-tertiary-100 hover:bg-bor-tertiary-200 dark:bg-bor-primary dark:hover:bg-bor-primary-400 transition-colors duration-200',
  disabledClassesWithBackground
);

export const iconGhostClasses =
  'dark:hover:bg-bor-light/20 text-bor-foreground disabled:text-bor-neutral-300 bg-transparent transition-colors duration-200 hover:bg-bor-tertiary-100/50 disabled:cursor-not-allowed disabled:bg-transparent disabled:dark:bg-transparent';

export const lgPaddingClasses = 'px-6 py-4';
export const mdPaddingClasses = 'px-6 py-2';
export const basePaddingClasses = 'px-4 py-2';
export const smPaddingClasses = 'px-2 py-1';
export const ourlineLgPaddingClasses = 'px-6 py-3.5';

export const lgIconBtnSizeClasses = 'p-4 text-4xl';
export const mdIconBtnSizeClasses = 'p-4 text-2xl';
export const baseIconBtnSizeClasses = 'p-2 text-base';
export const smIconBtnSizeClasses = 'p-2 text-xs';

export const buttonWrapCva = cva(baseClasses, {
  variants: {
    variant: {
      primary: `${primaryClasses}`,
      outline: `${outlineClasses}`,
      isolated: `${outlineClasses} ${isolatedClasses}`,
      secondary: `${ghostClasses} ${secondaryClasses}`,
      ghost: `${ghostClasses}`,
      iconFill: `${iconButtonClasses} ${iconFillClasses}`,
      iconGhost: `${iconButtonClasses} ${iconGhostClasses}`,
      secondaryGhost: `text-bor-foreground w-fit`,
      glowy: `${glowyClasses}`,
      flashy: `${glowyClasses} transition-all hover:-translate-y-1`
    },
    size: {
      lg: '',
      md: '',
      base: '',
      sm: ''
    }
  },
  compoundVariants: [
    {
      variant: 'primary',
      size: 'lg',
      className: `${lgPaddingClasses}`
    },
    {
      variant: 'primary',
      size: 'md',
      className: `${mdPaddingClasses}`
    },
    {
      variant: 'primary',
      size: 'base',
      className: `${basePaddingClasses}`
    },
    {
      variant: 'primary',
      size: 'sm',
      className: `${smPaddingClasses}`
    },
    {
      variant: 'outline',
      size: 'lg',
      className: `${ourlineLgPaddingClasses}`
    },
    {
      variant: 'outline',
      size: 'md',
      className: `${mdPaddingClasses}`
    },
    {
      variant: 'outline',
      size: 'base',
      className: `${basePaddingClasses}`
    },
    {
      variant: 'outline',
      size: 'sm',
      className: `${smPaddingClasses}`
    },
    {
      variant: 'isolated',
      size: 'lg',
      className: `${lgPaddingClasses}`
    },
    {
      variant: 'isolated',
      size: 'md',
      className: `${mdPaddingClasses}`
    },
    {
      variant: 'isolated',
      size: 'base',
      className: `${basePaddingClasses}`
    },
    {
      variant: 'isolated',
      size: 'sm',
      className: `${smPaddingClasses}`
    },
    {
      variant: 'secondary',
      size: 'lg',
      className: `text-lg`
    },
    {
      variant: 'secondary',
      size: 'md',
      className: `text-base`
    },
    {
      variant: 'secondary',
      size: 'sm',
      className: `text-sm`
    },
    {
      variant: 'ghost',
      size: 'lg',
      className: `text-lg`
    },
    {
      variant: 'ghost',
      size: 'md',
      className: `text-base`
    },
    {
      variant: 'ghost',
      size: 'sm',
      className: `text-sm`
    },
    {
      variant: 'iconFill',
      size: 'lg',
      className: `${lgIconBtnSizeClasses}`
    },
    {
      variant: 'iconFill',
      size: 'md',
      className: `${mdIconBtnSizeClasses}`
    },
    {
      variant: 'iconFill',
      size: 'base',
      className: `${baseIconBtnSizeClasses}`
    },
    {
      variant: 'iconFill',
      size: 'sm',
      className: `${smIconBtnSizeClasses}`
    },
    {
      variant: 'iconGhost',
      size: 'lg',
      className: `${lgIconBtnSizeClasses}`
    },
    {
      variant: 'iconGhost',
      size: 'md',
      className: `${mdIconBtnSizeClasses}`
    },
    {
      variant: 'iconGhost',
      size: 'base',
      className: `${baseIconBtnSizeClasses}`
    },
    {
      variant: 'iconGhost',
      size: 'sm',
      className: `${smIconBtnSizeClasses}`
    }
  ],
  defaultVariants: {
    variant: 'primary',
    size: 'lg'
  }
});

const spanUnderlineClasses =
  'before:content-empty before:bg-bor-foreground relative text-left before:absolute before:bottom-[2px] before:h-[1px] before:w-full before:opacity-0 before:transition-all before:duration-300 group-hover/button:before:opacity-100 group-hover/button:before:transition-opacity group-hover/button:before:duration-300 aria-disabled:before:bg-bor-neutral-300';

const textSlideUpDownClasses =
  'group-hover/button:animate-[slideUp_0.2s_ease-in-out,_slideDown_0.1s_ease-in-out_0.2s]';

export const spanCva = cva('', {
  variants: {
    variant: {
      primary: `${textSlideUpDownClasses}`,
      outline: `${textSlideUpDownClasses}`,
      isolated: `${textSlideUpDownClasses}`,
      secondaryMd: `${spanUnderlineClasses}`,
      secondary: `${spanUnderlineClasses}`,
      ghost: `leading-7 ${spanUnderlineClasses}`,
      secondaryGhost: 'text-bor-foreground relative text-left leading-6',
      glowy: 'bg-bor-primary m-auto block rounded-sm',
      flashy: 'bg-bor-primary relative m-auto block rounded-sm'
    },
    size: {
      lg: '',
      md: '',
      base: '',
      sm: ''
    }
  },
  compoundVariants: [
    {
      variant: 'glowy',
      size: 'lg',
      className: 'px-5.5 py-3.5'
    },
    {
      variant: 'glowy',
      size: 'md',
      className: 'px-5.5 py-1.5'
    },
    {
      variant: 'glowy',
      size: 'base',
      className: 'px-3.5 py-1.5'
    },
    {
      variant: 'glowy',
      size: 'sm',
      className: 'px-1.5 py-0.5'
    },
    {
      variant: 'flashy',
      size: 'lg',
      className: 'px-5.5 py-3.5'
    },
    {
      variant: 'flashy',
      size: 'md',
      className: 'px-5.5 py-1.5'
    },
    {
      variant: 'flashy',
      size: 'base',
      className: 'px-3 py-1.5'
    },
    {
      variant: 'flashy',
      size: 'sm',
      className: 'px-1.5 py-0.5'
    }
  ],
  defaultVariants: {
    variant: 'primary',
    size: 'lg'
  }
});
