import { cva } from 'class-variance-authority';

export const customToastCva = cva(
  [
    '<1024:flex-col <1024:items-start <1024:gap-2 bg-bor-tertiary relative flex h-full w-full items-center justify-between rounded-lg font-normal '
  ],
  {
    variants: {
      type: {
        success: 'bg-bor-success-400',
        error: 'bg-bor-error-500 dark',
        assetInternal: 'bg-bor-tertiary dark',
        assetExternal: 'bg-bor-tertiary dark'
      }
    }
  }
);

export const closeIconCva = cva(['cursor-pointer '], {
  variants: {
    type: {
      success: 'text-dark',
      error: 'text-light',
      assetInternal: 'text-light',
      assetExternal: 'text-light'
    }
  }
});

export const textCva = cva('flex-shrink-1 text-base', {
  variants: {
    type: {
      success: 'text-dark',
      error: 'text-light max-w-[80%]',
      assetInternal: 'text-light max-w-[700px]',
      assetExternal: 'text-light max-w-[700px]'
    }
  }
});
