import atom from '@konsus/atoms';
import { type AnalyticClient } from './types';
import { createGtmClient } from './createGtmClient';

const clients = [];

const analyticsState = {
  track(event, props) {
    clients.forEach((client) => client.track(event, props));
  }
};

clients.push(createGtmClient());

export const analyticsAtom = atom<AnalyticClient>(analyticsState);
