import React, { type CSSProperties } from 'react';
import { cn } from '../../lib';
import { textCva } from './styles';
import { type TextSize, type UnderlineProperties, type UnderlineStyle } from './types';

// 1px causes visual bugs on low zoom levels
const DEFAULT_UNDERLINE_HEIGHT = '1.3px';
const DEFAULT_UNDERLINE_TRANSITION = '500ms';

export interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
  underline?: UnderlineStyle | UnderlineProperties;
  size?: TextSize;
  as?: 'span' | 'p';
}

export const Text: React.FC<TextProps> = ({
  className,
  underline,
  size = 'base',
  as: Component = 'span',
  ...rest
}) => {
  const {
    style: underlineStyle,
    height: underlineHeight = DEFAULT_UNDERLINE_HEIGHT,
    speed: underlineSpeed = DEFAULT_UNDERLINE_TRANSITION
  } = typeof underline === 'object' ? underline : { style: underline };

  return (
    <Component
      className={cn(textCva({ underline: underlineStyle, size }), className)}
      style={
        {
          ...(underline && {
            '--underline-height': underlineHeight,
            '--underline-speed': underlineSpeed
          })
        } as CSSProperties
      }
      {...rest}
    />
  );
};
