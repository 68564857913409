import React, { useEffect, useState } from 'react';
import { Animation } from '@konsus/lego/src/components/Animation';

export const Loader: React.FC<{ delay?: number }> = ({ delay = 0 }) => {
  const [hide, setHide] = useState(delay > 0);

  useEffect(() => {
    if (delay) {
      const timeoutId = setTimeout(() => setHide(false), delay);

      return () => clearTimeout(timeoutId);
    }
  }, [delay]);

  if (hide) {
    return null;
  }

  return (
    <div className='bg-darker/80 fixed inset-0 z-[999999999] h-full w-full' data-testid='loader'>
      <div className='absolute inset-0 m-auto h-32 w-32'>
        <Animation animationUrl={`/loadingState.json`} aspectRatio={1} />
      </div>
    </div>
  );
};

export default Loader;
