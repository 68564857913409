class Api {
  static _baseUrl: string = '/api';

  static _commonParams: {} = {};

  static get baseUrl(): string {
    return this._baseUrl;
  }

  static set baseUrl(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  static get commonParams(): {} {
    return this._commonParams;
  }

  static set commonParams(commonParams: {}) {
    this._commonParams = commonParams;
  }

  static buildUrl(route: string): string {
    return `${this.baseUrl}/${route}`;
  }

  static prepareParams(params = {}): {} {
    return { ...this.commonParams, ...params };
  }

  static processResponse(response) {
    if (response.status < 200 && response.status >= 400) {
      throw new Error('Bad response from server');
    }

    return response.json();
  }

  static checkResponseDataForErrors(data) {
    if (data.error) {
      throw data.error;
    }

    return data;
  }

  static get(route: string, params?: {}): Promise<any> {
    const url = params
      ? `${this.buildUrl(route)}?${new URLSearchParams(this.prepareParams(params))}`
      : this.buildUrl(route);

    return fetch(url, {
      method: 'GET'
    })
      .then(this.processResponse)
      .then(this.checkResponseDataForErrors);
  }

  // @todo
  // static post() {
  //
  // }
}

export default Api;
