import getBrightness from 'getbrightness';

export const normalizeColor = (color, theme, dark?) => {
  const colorSpec =
    theme.global && theme.global.colors[color] !== undefined ? theme.global.colors[color] : color;
  // If the color has a light or dark object, use that
  let result = colorSpec;

  if (colorSpec) {
    if ((dark === true || (dark === undefined && theme.dark)) && colorSpec.dark !== undefined) {
      result = colorSpec.dark;
    } else if ((dark === false || !theme.dark) && colorSpec.light !== undefined) {
      result = colorSpec.light;
    }
  }
  // allow one level of indirection in color names
  if (result && theme.global && theme.global.colors[result] !== undefined) {
    result = normalizeColor(result, theme, dark);
  }

  return result;
};

export const isDark = (color) => {
  if (color) {
    if (typeof color === 'object' && 'dark' in color) {
      return color.dark;
    }

    if (color === 'transparent-on-dark') {
      return true;
    }
    if (color === 'transparent-on-light') {
      return false;
    }

    return getBrightness(color) <= 0.6;
  }

  return false;
};

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export const hexToRgbaString = (hex, opacity = 1) => {
  const rgb = hexToRgb(hex);

  return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})` : null;
};
