import type React from 'react';
import { useState, useEffect } from 'react';
import { useScreenSize } from '@konsus/superside-kit';

const OBSERVER_THRESHOLD = 0.65;

export const useScrollPosition = (
  cardsWrapperRef: React.RefObject<HTMLDivElement>,
  initialScrollPosition?: number
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const { breakpoints } = useScreenSize();

  useEffect(() => {
    const cardsWrapperElement = cardsWrapperRef?.current;
    let observer: IntersectionObserver;

    function setScrollPosition() {
      if (cardsWrapperElement) {
        const secondChild = cardsWrapperElement.children[2] as HTMLElement;
        const left =
          initialScrollPosition !== undefined ? initialScrollPosition : secondChild?.offsetLeft;

        cardsWrapperElement?.scroll({
          left
        });

        observer = new IntersectionObserver(
          ([e]) => {
            if (
              e.isIntersecting &&
              e.intersectionRatio >= OBSERVER_THRESHOLD &&
              !breakpoints.medium
            ) {
              setIsIntersecting(true);
            }
          },
          { threshold: [OBSERVER_THRESHOLD] }
        );

        observer.observe(cardsWrapperElement);
      }
    }

    setScrollPosition();

    window.addEventListener('resize', setScrollPosition);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', setScrollPosition);
    };
  }, []);

  return isIntersecting;
};

export default useScrollPosition;
