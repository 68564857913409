import { cva } from 'class-variance-authority';

export const headingCva = cva('font-heading box-border leading-normal tracking-[0.1px]', {
  variants: {
    size: {
      responsive: '',
      sm: '',
      md: '',
      lg: ''
    },
    level: {
      mega: '',
      epic: '',
      h1: '',
      h2: '',
      h3: '',
      h4: '',
      h5: '',
      h6: ''
    }
  },
  compoundVariants: [
    // size: responsive
    {
      size: 'responsive',
      level: 'mega',
      className: 'text-bor-7xl 768:text-bor-10xl 1280:text-bor-11xl'
    },
    {
      size: 'responsive',
      level: 'epic',
      className: 'text-bor-6xl 768:text-bor-9xl 1280:text-bor-10xl'
    },
    {
      size: 'responsive',
      level: 'h1',
      className: 'text-bor-5xl 768:text-bor-7xl 1280:text-bor-9xl'
    },
    {
      size: 'responsive',
      level: 'h2',
      className: 'text-bor-4xl 768:text-bor-6xl 1280:text-bor-8xl'
    },
    {
      size: 'responsive',
      level: 'h3',
      className: 'text-bor-3xl 768:text-bor-5xl 1280:text-bor-7xl'
    },
    {
      size: 'responsive',
      level: 'h4',
      className: 'text-bor-2xl 768:text-bor-4xl 1280:text-bor-6xl'
    },
    {
      size: 'responsive',
      level: 'h5',
      className: 'text-bor-1.5xl 768:text-bor-3xl 1280:text-bor-4xl'
    },
    {
      size: 'responsive',
      level: 'h6',
      className: 'text-bor-xl 768:text-bor-2xl 1280:text-bor-2xl'
    },
    // size: sm
    {
      size: 'sm',
      level: 'mega',
      className: 'text-bor-7xl'
    },
    {
      size: 'sm',
      level: 'epic',
      className: 'text-bor-6xl'
    },
    {
      size: 'sm',
      level: 'h1',
      className: 'text-bor-5xl'
    },
    {
      size: 'sm',
      level: 'h2',
      className: 'text-bor-4xl'
    },
    {
      size: 'sm',
      level: 'h3',
      className: 'text-bor-3xl'
    },
    {
      size: 'sm',
      level: 'h4',
      className: 'text-bor-2xl'
    },
    {
      size: 'sm',
      level: 'h5',
      className: 'text-bor-1.5xl'
    },
    {
      size: 'sm',
      level: 'h6',
      className: 'text-bor-xl'
    },
    // size: md
    {
      size: 'md',
      level: 'mega',
      className: 'text-bor-10xl'
    },
    {
      size: 'md',
      level: 'epic',
      className: 'text-bor-9xl'
    },
    {
      size: 'md',
      level: 'h1',
      className: 'text-bor-7xl'
    },
    {
      size: 'md',
      level: 'h2',
      className: 'text-bor-6xl'
    },
    {
      size: 'md',
      level: 'h3',
      className: 'text-bor-5xl'
    },
    {
      size: 'md',
      level: 'h4',
      className: 'text-bor-4xl'
    },
    {
      size: 'md',
      level: 'h5',
      className: 'text-bor-3xl'
    },
    {
      size: 'md',
      level: 'h6',
      className: 'text-bor-2xl'
    },
    // size: lg
    {
      size: 'lg',
      level: 'mega',
      className: 'text-bor-11xl'
    },
    {
      size: 'lg',
      level: 'epic',
      className: 'text-bor-10xl'
    },
    {
      size: 'lg',
      level: 'h1',
      className: 'text-bor-9xl'
    },
    {
      size: 'lg',
      level: 'h2',
      className: 'text-bor-8xl'
    },
    {
      size: 'lg',
      level: 'h3',
      className: 'text-bor-7xl'
    },
    {
      size: 'lg',
      level: 'h4',
      className: 'text-bor-6xl'
    },
    {
      size: 'lg',
      level: 'h5',
      className: 'text-bor-4xl'
    },
    {
      size: 'lg',
      level: 'h6',
      className: 'text-bor-2xl'
    }
  ],
  defaultVariants: {
    size: 'responsive',
    level: 'h1'
  }
});
