import atom from '@konsus/atoms';
import type { IVisualAsset, SlideType } from '@konsus/lego/src/components/VisualAsset/types';
import type { CtaProps } from '@konsus/lego/src/types/ctaButton';
import { type SanityAsset } from '@konsus/image';

export const previewMediaValueAtom = atom<{
  youtubeVideo?: any;
  wistiaVideo?: any;
  inlineVideo?: any;
  image?: SanityAsset;
  alt?: string;
  title?: string;
  url?: string;
  hashtags?: string[];
  cta?: CtaProps;
  visualAsset?: IVisualAsset;
  slides?: SlideType[];
  activeIndex?: number;
}>({});
