import { sleep } from './sleep';

function createHelperElement() {
  const el = document.createElement('div');

  el.style.display = 'flex';
  el.style.flexDirection = 'column';
  el.style.gap = '1px';
  el.style.paddingTop = '1px';
  el.appendChild(document.createElement('div'));
  el.appendChild(document.createElement('div'));

  // append to the DOM (needed to obtain clientHeight)
  document.body.appendChild(el);

  return el;
}

export function detectFlexGapSupport(onDetect) {
  if (typeof document !== 'object') {
    return;
  }

  const detectSupport = async () => {
    if (document.readyState === 'complete') {
      const MAX_RETRIES = 10;
      const SLEEP_TIME = 100;
      const el = createHelperElement();

      let isTestElementReady = false;

      for (let i = 0; i < MAX_RETRIES; i++) {
        isTestElementReady = el.clientHeight > 0;
        if (isTestElementReady) {
          break;
        } else {
          await sleep(SLEEP_TIME);
        }
      }

      if (isTestElementReady) {
        // flex container should be 1px high from the row-gap in addition there is 1px from paddingTop to ensure
        // that document is ready and our measurements are correct
        const isFlexGapSupported = el.clientHeight === 2;

        onDetect(isFlexGapSupported);
      }

      el.parentNode.removeChild(el);

      document.removeEventListener('readystatechange', detectSupport);
    }
  };

  if (document.readyState === 'complete') {
    detectSupport();
  } else {
    document.addEventListener('readystatechange', detectSupport);
  }
}
