import { cva } from 'class-variance-authority';

export const gridSelectCva = cva('grid', {
  variants: {
    mobileColumns: {
      1: 'grid-cols-1',
      2: 'grid-cols-2',
      3: 'grid-cols-3',
      4: 'grid-cols-4',
      5: 'grid-cols-5',
      6: 'grid-cols-6'
    },
    desktopColumns: {
      1: 'lg:grid-cols-1',
      2: 'lg:grid-cols-2',
      3: 'lg:grid-cols-3',
      4: 'lg:grid-cols-4',
      5: 'lg:grid-cols-5',
      6: 'lg:grid-cols-6'
    },
    size: {
      md: 'gap-3',
      sm: 'gap-2'
    }
  }
});

export const gridSelectOptionCva = cva(
  'overflow-hidden rounded text-left font-semibold transition-colors',
  {
    variants: {
      size: {
        md: 'text-base',
        sm: 'p-2 text-sm'
      },
      isSelected: {
        true: 'text-bor-background bg-bor-secondary-400 hover:bg-bor-secondary-300 dark:bg-bor-light/100 dark:hover:bg-bor-light/80',
        false:
          'text-bor-foreground dark:bg-bor-light/10 hover:dark:bg-bor-light/20 bg-bor-secondary-100 hover:bg-bor-secondary-200'
      },
      hasImage: {
        true: '',
        false: ''
      }
    },
    compoundVariants: [
      {
        hasImage: false,
        size: 'md',
        className: `p-4`
      },
      {
        hasImage: true,
        size: 'md',
        className: `p-3`
      }
    ]
  }
);

export const gridSelectOptionImageContainerCva = cva('flex', {
  variants: {
    size: {
      md: 'mr-1',
      sm: ''
    }
  }
});

export const gridSelectOptionImageSize = {
  md: 72,
  sm: 32
};
