import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import SanityClient from '@sanity/client';
import { previewSanityConfig } from '@konsus/sanity-client';
import { type MaybePageProps } from '@konsus/utils';

const sanityClient = new SanityClient(previewSanityConfig);

type Data = NonNullable<MaybePageProps['data']>['content'] & {
  blocks?: any;
  items?: any;
};

export function usePreviewSubscription(
  query,
  params,
  initialData = undefined
): {
  data: Data | undefined;
  error: { message: string } | null;
} {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (params.key || params.projectKey) {
      const stop =
        query.length < 9000
          ? createQueryPreviewListener(query, params, setData)
          : createQueryPreviewPolling(query, params, setData);

      return () => {
        if (typeof stop === 'function') {
          stop();
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params), query]);

  return {
    data,
    error: null
  };
}

function createQueryPreviewListener(query, params, setData) {
  const listenParams = {};

  Object.entries(params).forEach((param) => {
    if (Array.isArray(param[1])) {
      listenParams[param[0]] = [];
    } else {
      listenParams[param[0]] = param[1];
    }
  });

  const listener = sanityClient.listen(query, listenParams).subscribe(() => {
    setTimeout(() => {
      sanityClient.fetch(query, params).then((result) => {
        if (result) {
          setData(result);
        }
      });
    }, 1000);
  });

  return () => {
    listener.unsubscribe();
  };
}

function createQueryPreviewPolling(query, params, setData) {
  const fetchData = throttle(
    () => {
      return sanityClient.fetch(query, params).then((result) => {
        setData(result);
      });
    },
    500,
    {
      trailing: true,
      leading: true
    }
  );

  return createEventSource(previewSanityConfig, fetchData);
}

async function createEventSource(config, callback) {
  const { projectId, dataset, token } = config;
  const url = `https://${projectId}.api.sanity.io/v1/data/listen/${dataset}?query=*&effectFormat=mendoza`;
  const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

  // @ts-ignore
  const es = new EventSource(url, { withCredentials: true, headers });

  const handleMutation = (ev) => {
    const data = JSON.parse(ev.data);
    const isListenerMutation = /\.listeners\./.test(data.eventId);

    if (!isListenerMutation) {
      callback();
    }
  };

  es.addEventListener('mutation', handleMutation, false);

  return () => {
    es.removeEventListener('mutation', handleMutation, false);
  };
}
