import { useCallback } from 'react';
import { useRouter } from 'adapter/next/router';
import { useAtomMutator } from '@konsus/atoms';
import { MediaTypes } from '@konsus/lego/src/components';
import { previewMediaValueAtom } from '@konsus/superside-kit';

export type UseVideoHandlerProps = {
  mediaType?: MediaTypes;
  title?: string;
  [key: string]: any;
  youtubeVideoId?: string;
  wistiaVideoId?: string;
};

export const useVideoHandler = ({
  mediaType,
  title,
  youtubeVideoId,
  wistiaVideoId,
  rest
}: UseVideoHandlerProps) => {
  const setPreviewMediaValue = useAtomMutator(previewMediaValueAtom);
  const { push } = useRouter();

  const onCardClickHandler = useCallback(() => {
    if (
      mediaType !== MediaTypes.YOUTUBE &&
      mediaType !== MediaTypes.WISTIA &&
      mediaType !== MediaTypes.INLINEVIDEO
    ) {
      return false;
    }

    const mediaValue = {
      [mediaType]: {
        mediaType,
        title,
        youtubeVideoId,
        wistiaVideoId,
        ...rest
      }
    };

    const videoIdList: { [key in MediaTypes]?: string } = {
      [MediaTypes.YOUTUBE]: youtubeVideoId,
      [MediaTypes.WISTIA]: wistiaVideoId
    };

    const newQuery = videoIdList[mediaType] ?? null;

    if (newQuery) {
      push(
        {
          query: {
            [mediaType]: newQuery
          }
        },
        undefined,
        { shallow: true }
      );
    }

    setPreviewMediaValue({ title, ...mediaValue });
  }, [mediaType, push, rest, setPreviewMediaValue, title, wistiaVideoId, youtubeVideoId]);

  return onCardClickHandler;
};
