import { composeStyles, normalizeColor } from '../utils';
import styles from './useBorderStyles.module.css';

const getBorderStyle = (data: any, theme: any) => {
  const themeBorderSize = theme.global.borderSize || {};
  const side = typeof data === 'string' ? data : data.side || 'all';
  const style = data.style || 'solid';
  const borderSize = themeBorderSize[data.size] || data.size || themeBorderSize.xsmall;

  const color = normalizeColor(data.color || 'border', theme);

  return [side, style, borderSize, color];
};

const getBorderStyleSide = (border: any, theme: any) => {
  const [side, style, borderSize, color] = getBorderStyle(border, theme);

  switch (side) {
    case 'top':
      return {
        style: {
          '--bt-w': borderSize,
          '--bt-s': style,
          '--bt-c': color
        },
        className: styles.borderTop
      };
    case 'right':
      return {
        style: {
          '--br-w': borderSize,
          '--br-s': style,
          '--br-c': color
        },
        className: styles.borderRight
      };
    case 'bottom':
      return {
        style: {
          '--bb-w': borderSize,
          '--bb-s': style,
          '--bb-c': color
        },
        className: styles.borderBottom
      };
    case 'left':
      return {
        style: {
          '--bl-w': borderSize,
          '--bl-s': style,
          '--bl-c': color
        },
        className: styles.borderLeft
      };
    case 'vertical':
      return {
        style: {
          '--bl-w': borderSize,
          '--bl-s': style,
          '--bl-c': color,
          '--br-w': borderSize,
          '--br-s': style,
          '--br-c': color
        },
        className: [styles.borderLeft, styles.borderRight]
      };
    case 'horizontal':
      return {
        style: {
          '--bt-w': borderSize,
          '--bt-s': style,
          '--bt-c': color,
          '--bb-w': borderSize,
          '--bb-s': style,
          '--bb-c': color
        },
        className: [styles.borderTop, styles.borderBottom]
      };
  }

  return styles;
};

export const useBorderStyles = (props: any, theme: any) => {
  if (props.border) {
    if (Array.isArray(props.border)) {
      return composeStyles(props.border.map((border: any) => getBorderStyleSide(border, theme)));
    }

    return getBorderStyleSide(props.border, theme);
  }

  return {};
};
