import { createCache } from './sanityCache';
import { errorToState, NotFoundError } from './errors';
import { getCacheKey } from './getCacheKey';
import {
  type CreateSanityClientParams,
  type RawSanityClient,
  type SanityCache,
  type SanityClient
} from './typing';

async function createSanitySdkClient(
  sanityClientOrConfig: CreateSanityClientParams | RawSanityClient | SanityClient
) {
  if ('fetch' in sanityClientOrConfig) {
    // already initialized
    return sanityClientOrConfig;
  }

  const createSanityClient = (await import(/* webpackChunkName: "sanityClient" */ '@sanity/client'))
    .default;

  return createSanityClient(sanityClientOrConfig);
}

export function createAsyncClient(
  sanityClientOrConfig: CreateSanityClientParams | RawSanityClient | SanityClient,
  userCache?: SanityCache
): SanityClient {
  if ('cache' in sanityClientOrConfig) {
    return sanityClientOrConfig;
  }

  const cache = userCache || createCache();
  let sanitySdkClient: any = null;

  async function fetch<QueryParams, ReturnValue>(
    query: string,
    params: QueryParams
  ): Promise<ReturnValue> {
    const cacheKey = getCacheKey(query, params);
    const hit = cache.get(cacheKey);

    if (hit) {
      const { result, error, promise } = hit;

      if (result) {
        return result;
      } else if (promise) {
        return promise;
      } else {
        throw error;
      }
    }

    if (sanitySdkClient == null) {
      sanitySdkClient = await createSanitySdkClient(sanityClientOrConfig);
    }

    const sanityQuery: Promise<ReturnValue> = sanitySdkClient.fetch(query, params);
    const promise: Promise<ReturnValue> = sanityQuery
      .then(handleFetchSuccess(cache, cacheKey))
      .catch(handleFetchError(cache, cacheKey));

    cache.set(cacheKey, {
      result: undefined,
      error: undefined,
      promise
    });

    return promise;
  }

  function handleFetchSuccess<ReturnValue>(cache: SanityCache, cacheKey: string) {
    return (result: ReturnValue): ReturnValue => {
      if (result === null) {
        throw new NotFoundError('Not found');
      }
      cache.set(cacheKey, {
        result,
        error: null,
        promise: null
      });

      return result;
    };
  }

  const handleFetchError =
    (cache: SanityCache, cacheKey: string) =>
    (error): Promise<any> => {
      cache.set(cacheKey, {
        result: null,
        error: errorToState(error),
        promise: null
      });
      throw error;
    };

  return {
    cache,
    fetch,
    clientConfig: {
      apiHost: 'https://cdn.sanity.io',
      ...getConfigFromSanityClient(sanityClientOrConfig)
    }
  };
}

function getConfigFromSanityClient(
  sanityClientOrConfig: CreateSanityClientParams | RawSanityClient | SanityClient
) {
  if ('clientConfig' in sanityClientOrConfig) {
    return sanityClientOrConfig.clientConfig;
  }
  if ('projectId' in sanityClientOrConfig) {
    // is config
    return sanityClientOrConfig;
  }

  return {};
}
