import { errorToState } from './errors';
import { type SanityCache } from './typing';

export function createCache(): SanityCache {
  const cache = new Map();

  const restore = (data): void => {
    data.forEach((entry) => {
      cache.set(entry.k, entry.v);
    });
  };

  const clear = () => {
    cache.clear();
  };

  const getCacheDump = () => {
    const dump: any[] = [];

    cache.forEach((v, k) => {
      dump.push({ k, v });
    });

    return dump;
  };

  const handleRequestError = (error) => ({ result: null, error: errorToState(error) });

  const getPromises = (): Promise<any>[] => {
    return getCacheDump()
      .map(({ v }) => v.promise)
      .filter((promise) => promise !== null && typeof promise !== 'undefined')
      .map((promise) => promise.catch(handleRequestError));
  };

  const serialize = async (): Promise<object> => {
    await Promise.all(getPromises());

    return getCacheDump().map(({ k, v, e }) => ({
      k,
      v: { result: v.result, error: v.error },
      e
    }));
  };

  return {
    getPromises,
    cache,
    clear,
    get: (key) => cache.get(key),
    restore,
    serialize,
    set: (key: string, value: any) => cache.set(key, value)
  };
}
