import { type AnimationSegment, type AnimationItem, type LottiePlayer } from 'lottie-web';

let lottieModule: LottiePlayer;

export const loadLottieModule = (): Promise<LottiePlayer> => {
  if (lottieModule) {
    return Promise.resolve(lottieModule);
  }

  return import('lottie-web/build/player/lottie_svg').then((mod) => {
    lottieModule = mod.default as LottiePlayer;

    return lottieModule;
  });
};

export type SegmentMarker = { name: string; reverse?: boolean };
type Marker = { name?: string; time: number };

// FIXME when correct type definition is available. markers exists in object, but not in definition
export type UpdatedAnimationItem = AnimationItem & {
  markers?: {
    payload?: {
      name: string;
    };
    time: number;
  }[];
};

export const getSegmentsFromMarker = (
  lottie: UpdatedAnimationItem | null | undefined,
  segmentMarkerToPlay: SegmentMarker | undefined
): AnimationSegment => {
  if (!lottie || !segmentMarkerToPlay) {
    return [0, 0];
  }

  const markers: Marker[] =
    lottie.markers?.map((marker) => ({
      name: marker.payload?.name,
      time: marker.time
    })) || [];

  const segmentIndex = markers.findIndex((marker) => marker.name === segmentMarkerToPlay.name);

  if (segmentIndex < 0) {
    return [0, 0];
  }

  const lastSegment = isLastSegment(markers, segmentIndex)
    ? lottie.totalFrames
    : markers[segmentIndex + 1].time;

  return segmentMarkerToPlay.reverse
    ? [lastSegment, markers[segmentIndex].time]
    : [markers[segmentIndex].time, lastSegment];
};

const isLastSegment = (markers: Marker[], segmentIndex: number) =>
  segmentIndex === markers.length - 1;

export const getScrollProgress = (element: HTMLElement | null | undefined) => {
  if (!element) {
    return 0;
  }

  const elementClientHeight = element.clientHeight;
  const { scrollY, pageYOffset, innerHeight } = window;
  const elementDistanceFromTop = element.getBoundingClientRect().top + pageYOffset;

  return (scrollY + innerHeight - elementDistanceFromTop) / elementClientHeight;
};
