import { cn } from '@superside/ui';

export interface MaybePageProps {
  data?: {
    content?: {
      isNewWebsite: boolean;
      backgroundVariant?: string;
      key?: string;
    };
  };
}

const isHeaderPresent = (pageProps) => {
  const { _type, showHeader = false, hideHeader = false } = pageProps?.data?.content || {};

  if (_type === 'landingPage' || _type === 'supersideLandingPage') {
    return showHeader;
  }

  return !hideHeader;
};

export function getBodyClassNames(pageProps: MaybePageProps): string {
  const { isNewWebsite, backgroundVariant, key } = pageProps?.data?.content || {};
  const { _type, template } = pageProps?.data?.post || {};
  const isNewPostLayout = _type === 'localizedBlogPostEntry' && template === 'new';

  const withHeader = isHeaderPresent(pageProps);

  const classList = [];

  if (!(isNewWebsite || isNewPostLayout) || key === 'samples') {
    classList.push('legacy');
  }

  if (backgroundVariant) {
    classList.push(backgroundVariant);
  }

  if (!withHeader) {
    classList.push('without-header');
  }

  return cn(classList);
}
