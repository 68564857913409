export const textSize = {
  xxsmall: {
    size: '9px',
    height: '1.5'
  },
  xsmall: {
    size: '11px',
    height: '1.5'
  },
  small: {
    size: '14px',
    height: '1.5'
  },
  medium: {
    size: '16px',
    height: '1.5'
  },
  large: {
    size: '19px',
    height: '1.5'
  },
  xlarge: {
    size: '20px',
    height: '1.6'
  },
  xxlarge: {
    size: '24px',
    height: '1.6'
  },
  xxxlarge: {
    size: '28px',
    height: '1.6'
  }
};

type TextSizeWithCssProps = {
  [key in keyof typeof textSize]: {
    fontSize: string;
    lineHeight: string;
  };
};

export const textSizeWithCssProps = Object.entries(textSize).reduce((acc, [key, item]) => {
  acc[key] = {
    fontSize: item.size,
    lineHeight: item.height
  };

  return acc;
}, {}) as TextSizeWithCssProps;

export default textSize;
