import React, { type CSSProperties } from 'react';
import { cn } from '../../../lib';

export interface StarProps extends React.SVGProps<SVGSVGElement> {
  twinkleDelay?: `${string}ms` | `${string}s`;
}

export const Star: React.FC<StarProps> = ({ className, twinkleDelay = '0ms', ...rest }) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn(
        'animate-twinkle pointer-events-none absolute transition-all duration-300',
        className
      )}
      style={{ '--twinkle-duration-3fc0': '3s', animationDelay: twinkleDelay } as CSSProperties}
      {...rest}
    >
      <g filter='url(#glow)'>
        <path
          className='fill-bor-primary'
          d='M16.9109 16.0519L18 12L19.0891 16.0519C19.2041 16.4712 19.5287 16.8027 19.9481 16.9109L24 18L19.9481 19.0891C19.5287 19.204 19.1973 19.5288 19.0891 19.9481L18 24L16.9109 19.9481C16.7959 19.5288 16.4713 19.1973 16.0519 19.0891L12 18L16.0519 16.9109C16.4713 16.7959 16.8027 16.4712 16.9109 16.0519Z'
        />
      </g>
      <defs>
        <filter
          id='glow'
          x='0'
          y='0'
          width='36'
          height='36'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='white' result='coloredFlood' />
          <feComposite in='coloredFlood' in2='SourceAlpha' operator='in' result='coloredAlpha' />
          <feOffset dx='2' dy='2' result='offset' />
          <feGaussianBlur in='coloredAlpha' stdDeviation='6' result='blur' />
          <feBlend in='SourceGraphic' in2='blur' mode='normal' />
        </filter>
      </defs>
    </svg>
  );
};
