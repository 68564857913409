export const Themes = {
  aurora: 'aurora',
  superads: 'superads',
  marble: 'marble'
} as const;

export type Theme = keyof typeof Themes;

export const ThemeOptions = Object.keys(Themes).map((themeKey) => ({
  title: themeKey.charAt(0).toUpperCase() + themeKey.slice(1), // capitalize
  value: themeKey
}));

export const SpecialBackgrounds = {
  none: 'url()',
  stars: 'url(/images/stars-background.svg)'
} as const;

export type SpecialBackground = (typeof SpecialBackgrounds)[keyof typeof SpecialBackgrounds];

export const SpecialBackgroundOptions = Object.entries(SpecialBackgrounds).map(
  ([title, value]) => ({
    title,
    value
  })
);
