import React, { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { cn } from '../../lib';

export interface SliderProps {
  min?: number;
  max?: number;
  disabled?: boolean;
  step?: number;
  value?: number;
  prefix?: string;
  suffix?: string;
  isPercentage?: boolean;
  setValue?: (newValue: number) => void;
  onChange?: (value: number) => void;
}

// TODO support multiple
export const Slider: React.FC<SliderProps> = ({
  min = 0,
  max = 100,
  step = 1,
  disabled = false,
  value: externalValue = (min + max) / 2,
  setValue: externalSetValue,
  prefix = '-',
  suffix = '+',
  isPercentage = false,
  onChange
}) => {
  const [internalValue, setInternalValue] = useState<number>(externalValue);

  const isControlled = !isNaN(externalValue) && externalSetValue;
  const value = isControlled ? externalValue : internalValue;
  const setValue = isControlled ? externalSetValue : setInternalValue;

  useEffect(() => {
    onChange?.(value);
  }, [value]);

  return (
    <div className='bg-dark dark:bg-light mb-8 flex h-16 items-center gap-4 rounded-lg bg-opacity-10 p-4 dark:bg-opacity-10'>
      {Boolean(prefix) && (
        <span className='dark:text-light text-dark whitespace-nowrap text-2xl'>{prefix}</span>
      )}
      <ReactSlider<number>
        className='flex w-full items-center'
        trackClassName='h-3 rounded-full'
        renderTrack={({ className, ...rest }, { index }) => (
          <div
            {...rest}
            className={cn(
              className,
              index === 0
                ? 'bg-bor-secondary mr-2'
                : 'bg-dark dark:bg-light bg-opacity-10 dark:bg-opacity-10'
            )}
          ></div>
        )}
        ariaLabel={'Value'}
        renderThumb={(props, { valueNow }) => (
          <div className='relative flex items-center'>
            <div
              {...props}
              className={cn(
                'bg-light border-bor-secondary h-10 w-10 rounded-full border-8 focus:outline-none',
                !disabled && 'cursor-pointer'
              )}
            ></div>
            <div
              // eslint-disable-next-line react/prop-types
              style={props.style}
              className='pointer-events-none absolute top-10 flex w-10 justify-center'
            >
              <span className='dark:text-light text-dark text-xl font-bold'>{`${valueNow}${
                isPercentage ? '%' : ''
              }`}</span>
            </div>
          </div>
        )}
        pearling
        minDistance={1}
        min={min}
        max={max}
        disabled={disabled}
        step={step}
        defaultValue={value}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        snapDragDisabled={disabled}
      />
      {Boolean(suffix) && (
        <span className='dark:text-light text-dark whitespace-nowrap text-2xl'>{suffix}</span>
      )}
    </div>
  );
};
