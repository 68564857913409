/* eslint-disable camelcase */
import { get as getCookie } from 'js-cookie';

export const FORM_TRACKING_PROVIDERS = {
  INTERNAL_PROGRESSIVE: 'internal_progressive',
  INTERNAL_INLINE: 'internal_inline',
  HUBSPOT_EMBED: 'hubspot_embed'
} as const;

export const TRACKING_EVENT_NAMES = {
  FORM_SUBMISSION: 'form.submitted',
  FORM_SUBMISSION_FAILURE: 'form_submission.failed',
  CALL_REQUEST_START: 'call_request.initiated',
  CALL_REQUEST_FAILURE: 'call_request.failed',
  CALL_REQUEST_CLOSED: 'call_request.closed',
  CALL_REQUEST_SUBMISSION: 'call_request.submitted'
} as const;

export const userEventsTracking = {
  hubspotutk: getCookie('hubspotutk'),
  ga_client_id: getCookie('_ga'),
  ajs_anonymous_id: getCookie('ajs_anonymous_id'),
  ajs_user_id: getCookie('ajs_user_id')
};

export type PromoEventType = {
  creativeName: string;
  creativeSlot: string;
  promotionName: string;
  promotionType: string;
  ecommerceItems: {
    itemName: string;
    itemIndex?: number;
  }[];
};

export const getPromoEventProps = (promoEvents: PromoEventType) => {
  const { creativeName, creativeSlot, promotionName, promotionType, ecommerceItems } =
    promoEvents || {};

  return {
    creative_name: creativeName,
    creative_slot: creativeSlot,
    promotion_name: promotionName,
    promotion_type: promotionType,
    items: ecommerceItems?.map(({ itemName, itemIndex }) => ({
      item_name: itemName,
      index: itemIndex
    }))
  };
};

export const baseTrackingFieldsGenerator = (
  eventId?: string,
  formId?: string,
  trackingEvents?: {
    form: string;
    form_type: string;
    item: string;
  },
  pagePath?: string
) => {
  const { form, form_type, item } = trackingEvents || {};

  const defaultValues = {
    event_id: eventId,
    user: {
      ...userEventsTracking
    },
    form_id: formId,
    page_path: pagePath
  };

  const hasDefaultFormTrackingValues = !form && !form_type && !item;

  if (hasDefaultFormTrackingValues) {
    return {
      ...defaultValues,
      form: 'call_request',
      form_type: 'call_request',
      item: 'default'
    };
  }

  return {
    ...defaultValues,
    ...trackingEvents,
    item: item ?? 'empty'
  };
};
