import { useEffect } from 'react';
import { useRouter } from 'adapter/next/router';
import { useAtomValue } from '@konsus/atoms';
import { MediaTypes } from '@konsus/lego/src/components';
import { previewMediaValueAtom } from '@konsus/superside-kit';

export type UseAutoPopupVideoProps = {
  mediaType?: MediaTypes;
  youtubeVideoId?: string;
  wistiaVideoId?: string;
  onCardClickHandler: () => false | undefined;
};

export const useAutoPopupVideo = ({
  mediaType,
  youtubeVideoId,
  wistiaVideoId,
  onCardClickHandler
}: UseAutoPopupVideoProps) => {
  const previewMediaValue = useAtomValue(previewMediaValueAtom);

  const { query } = useRouter();

  useEffect(() => {
    const [queryMediaType, queryVideoId] = Object.entries(query)[0] || [];

    if (
      Object.keys(previewMediaValue).length === 0 &&
      queryMediaType === mediaType &&
      ((mediaType === MediaTypes.YOUTUBE && youtubeVideoId === queryVideoId) ||
        (mediaType === MediaTypes.WISTIA && wistiaVideoId === queryVideoId))
    ) {
      onCardClickHandler();
    }
  }, [query, previewMediaValue, onCardClickHandler, mediaType, youtubeVideoId, wistiaVideoId]);
};
