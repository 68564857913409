import { type RefObject, useEffect, useState } from 'react';

export function useHover<T extends HTMLElement = HTMLElement>(elementRef: RefObject<T>): boolean {
  const [value, setValue] = useState<boolean>(false);

  const ref = elementRef.current;

  const handleMouseEnter = () => setValue(true);
  const handleMouseLeave = () => setValue(false);

  useEffect(() => {
    ref?.addEventListener('mouseenter', handleMouseEnter);
    ref?.addEventListener('mouseleave', handleMouseLeave);

    return () => ref?.removeEventListener('mouseenter', handleMouseEnter);
  }, [ref]);

  return value;
}
