import { cva } from 'class-variance-authority';

export const tagCva = cva(
  'text-bor-foreground flex w-fit items-center justify-center gap-2 rounded-full font-semibold',
  {
    variants: {
      size: {
        lg: 'px-6 py-4',
        md: 'px-4 py-2',
        sm: 'px-4 py-2',
        xs: 'px-2 py-0.5'
      },
      variant: {
        fill: 'backdrop-blur-sm',
        outline: 'border-2',
        glassy: ''
      },
      uppercase: {
        true: 'tracking-15 uppercase',
        false: ''
      },
      reverse: {
        true: 'flex-row-reverse',
        false: 'flex-row'
      },
      color: {
        primary: '',
        secondary: '',
        tertiary: '',
        light: '',
        dark: '',
        background: '',
        foreground: '',
        neutral: '',
        success: '',
        error: ''
      }
    },
    compoundVariants: [
      { variant: 'fill', color: 'primary', className: 'bg-bor-primary text-bor-background' },
      { variant: 'fill', color: 'secondary', className: 'bg-bor-secondary text-bor-background' },
      { variant: 'fill', color: 'tertiary', className: 'bg-bor-tertiary text-bor-background' },
      { variant: 'fill', color: 'light', className: 'bg-bor-light text-bor-dark' },
      { variant: 'fill', color: 'dark', className: 'bg-bor-dark text-bor-light' },
      { variant: 'fill', color: 'background', className: 'bg-bor-background text-bor-foreground' },
      { variant: 'fill', color: 'foreground', className: 'bg-bor-foreground text-bor-background' },
      { variant: 'fill', color: 'neutral', className: 'bg-bor-neutral text-bor-background' },
      { variant: 'fill', color: 'success', className: 'bg-bor-success text-bor-dark' },
      { variant: 'fill', color: 'error', className: 'bg-bor-error text-bor-dark' },
      { variant: 'outline', color: 'primary', className: 'border-bor-primary' },
      { variant: 'outline', color: 'secondary', className: 'border-bor-secondary' },
      { variant: 'outline', color: 'tertiary', className: 'border-bor-tertiary' },
      { variant: 'outline', color: 'light', className: 'border-bor-light' },
      { variant: 'outline', color: 'dark', className: 'border-bor-dark' },
      { variant: 'outline', color: 'background', className: 'border-bor-background' },
      { variant: 'outline', color: 'foreground', className: 'border-bor-foreground' },
      { variant: 'outline', color: 'neutral', className: 'border-bor-neutral' },
      { variant: 'outline', color: 'success', className: 'border-bor-success' },
      { variant: 'outline', color: 'error', className: 'border-bor-error' }
    ],
    defaultVariants: {
      color: 'neutral',
      variant: 'fill',
      size: 'md'
    }
  }
);

// Negative margin as icons have width of 1.5rem, instead of 1, like the height
// TODO Investigate this properly
export const iconCva = cva('-mx-1 -mt-0.5', {
  variants: {
    variant: {
      fill: 'text-current',
      outline: '',
      glassy: 'text-current'
    },
    color: {
      primary: '',
      secondary: '',
      tertiary: '',
      light: '',
      dark: '',
      background: '',
      foreground: '',
      neutral: '',
      success: '',
      error: ''
    }
  },
  compoundVariants: [
    {
      variant: 'outline',
      color: 'primary',
      className: 'text-bor-primary'
    },
    {
      variant: 'outline',
      color: 'secondary',
      className: 'text-bor-secondary'
    },
    {
      variant: 'outline',
      color: 'tertiary',
      className: 'text-bor-tertiary'
    },
    {
      variant: 'outline',
      color: 'light',
      className: 'text-bor-light'
    },
    {
      variant: 'outline',
      color: 'dark',
      className: 'text-bor-dark'
    },
    {
      variant: 'outline',
      color: 'background',
      className: 'text-bor-foreground'
    },
    {
      variant: 'outline',
      color: 'foreground',
      className: 'text-bor-foreground'
    },
    {
      variant: 'outline',
      color: 'neutral',
      className: 'text-bor-neutral'
    },
    {
      variant: 'outline',
      color: 'success',
      className: 'text-bor-success'
    },
    {
      variant: 'outline',
      color: 'error',
      className: 'text-bor-error'
    }
  ]
});
