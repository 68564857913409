import React from 'react';
import styles from './Gap.module.css';
/**
 * This component is used only as a client-side polyfill for browsers that
 * does not support flexbox-gap natively: https://caniuse.com/flexbox-gap
 */

export const Gap = (props: any) => (
  <div
    className={`base-gap ${styles.baseGapStyles}`}
    style={{
      // @ts-ignore
      '--size-token-6429': props.gap
    }}
  />
);

export default Gap;
export const baseGapStyles = 'base-gap';
