export const Shades = {
  '50': '50',
  '100': '100',
  '200': '200',
  '300': '300',
  '400': '400',
  '500': '500',
  '600': '600',
  '700': '700',
  '800': '800',
  '900': '900',
  strong: 'strong'
} as const;

export type Shade = keyof typeof Shades;

/* List of colors tokens available on Borealis */
export const Colors = {
  primary: {
    title: 'Primary',
    description: 'For main actions and CTAs',
    shades: ['100', '200', '300', '400', '500', '600', '700', '800', '900']
  },
  secondary: {
    title: 'Secondary',
    description: 'Complementary of primary',
    shades: ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900']
  },
  tertiary: {
    title: 'Tertiary',
    description: 'Complementary of primary and secondary',
    shades: ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900']
  },
  light: {
    title: 'Light',
    description: 'For text, borders and backgrounds that should always be dark',
    shades: ['strong']
  },
  dark: {
    title: 'Dark',
    description: 'For text, borders and backgrounds that should always be dark',
    shades: ['strong']
  },
  background: {
    title: 'Background',
    description: 'For backgrounds that will adapt based on light and dark modes',
    shades: ['strong']
  },
  foreground: {
    title: 'Foreground',
    description: 'For foregrounds (texts, borders) that will adapt based on light and dark modes',
    shades: ['strong']
  },
  neutral: {
    title: 'Neutral',
    description: 'Borders and disabled states',
    shades: ['100', '200', '300', '400', '500', '600', '700', '800', '900']
  },
  success: {
    title: 'Success',
    description: 'Messages and toasts that represent a successful action',
    shades: ['100', '200', '300', '400', '500', '600', '700', '800', '900']
  },
  error: {
    title: 'Error',
    description: 'Messages and toasts that represent an error happened',
    shades: ['100', '200', '300', '400', '500', '600', '700', '800', '900']
  }
} as const;

export type Color = keyof typeof Colors;
