import type { SanityImageCrop, SanityImageHotspot, SanityVisualAsset } from 'sanity.types';
import { type SanityImage } from '@konsus/image';

export enum MediaTypes {
  LOTTIE = 'lottie',
  IMAGE = 'image',
  INLINEVIDEO = 'inlineVideo',
  WISTIA = 'wistiaVideo',
  YOUTUBE = 'youtubeVideo',
  PROGRESS_BAR = 'progressBar',
  BREAKPOINT_LOTTIE = 'breakpointLottie',
  BREAKPOINT_IMAGE = 'breakpointImage',
  CAAS = 'caasPrism',
  SPLINE = 'spline',
  BEFORE_AFTER = 'beforeAfter',
  SKEWING_ASSETS = 'skewingAssets'
}

export type SanityAsset = {
  _type: 'file' | 'image';
  asset: {
    _ref: string;
    _type: string;
  };
};

export type SanityFileAsset = {
  asset?: {
    _ref: string;
    _type: 'reference';
    _weak?: boolean;
  };
  _type: 'file';
};

export type SanityImageAsset = {
  asset?: {
    _ref: string;
    _type: 'reference';
    _weak?: boolean;
  };
  hotspot?: SanityImageHotspot;
  crop?: SanityImageCrop;
  _type: 'image';
};

export type VisualAssetProps = IVisualAsset & {
  showPreviewModal?: boolean;
  isRte?: boolean;
  slides?: SlideType[];
  activeIndex?: number;
  previewModalTitle?: string;
};

declare type ImgElementStyle = NonNullable<JSX.IntrinsicElements['img']['style']>;
declare const VALID_LAYOUT_VALUES: readonly ['fill', 'fixed', 'intrinsic', 'responsive', undefined];

declare type LayoutValue = (typeof VALID_LAYOUT_VALUES)[number];

export interface IVisualAsset extends SanityVisualAsset {
  objectFit?: ImgElementStyle['objectFit'];
  objectFitMobile?: string;
  objectPosition?: string;
  objectPositionMobile?: string;
  layout?: LayoutValue;
  className?: string;
  hideLoadingBar?: boolean;
  requestWidth?: number;
  requestWidthMobile?: number;
  loading?: 'lazy' | 'eager';
}

export interface AssetTypeLottieBreakpoint {
  mediaType: MediaTypes.BREAKPOINT_LOTTIE;
  xlLottie?: SanityImageAsset;
  xlLottieAspectRatio?: number;
  lgLottie?: SanityImageAsset;
  lgLottieAspectRatio?: number;
  mdLottie?: SanityImageAsset;
  mdLottieAspectRatio?: number;
  smLottie?: SanityImageAsset;
  smLottieAspectRatio?: number;
}

export type SlideType = {
  _key: string;
  title?: string;
  slide: IVisualAsset | SanityImage;
  hashtags?: string[];
};
