import React from 'react';
import { cn } from '../../lib';
import Box, { type BoxProps } from '../../Layout/Box';
import { type Color } from '../../Foundations/Colors/colors';
import { Text } from '..';
import { type TagVariant, type TagSize, TagToTextSize, TagToTextIconSize } from './types';
import { iconCva, tagCva } from './styles';

export interface TagProps extends BoxProps {
  label?: React.ReactNode;
  iconPick?: { name?: string; svg: string };
  reverse?: boolean;
  size?: TagSize;
  variant?: TagVariant;
  uppercase?: boolean;
  iconClassName?: string;
  color?: Color;
}

export const Tag: React.FC<TagProps> = ({
  label,
  size = 'md',
  variant = 'fill',
  color = 'background',
  uppercase = true,
  iconPick,
  reverse = false,
  className,
  iconClassName,
  ...rest
}) => {
  const hasIcon = Boolean(iconPick?.svg);
  const isGlassy = variant === 'glassy';

  return (
    <Box
      className={cn(tagCva({ size, variant, uppercase, reverse, color }), className)}
      glassy={isGlassy ? 'fill' : undefined}
      {...rest}
    >
      {typeof label === 'string' ? (
        <Text size={TagToTextSize[size]} className={size === 'xs' ? '!text-bor-xs' : ''}>
          {label}
        </Text>
      ) : (
        label
      )}
      {hasIcon && (
        <Text
          size={TagToTextIconSize[size]}
          className={cn(iconCva({ color, variant }), iconClassName)}
          dangerouslySetInnerHTML={{ __html: iconPick!.svg }}
        />
      )}
    </Box>
  );
};
