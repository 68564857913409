import styles from './useColorStyles.module.css';

export const useColorStyles = (props: any, theme: any) => {
  if (props.color) {
    return {
      style: {
        '--color': theme.colors[props.color] || props.color
      },
      className: styles.text
    };
  }

  return {};
};

const getColor = (color: any, theme: any) => theme.colors[color] || color;

export const useBackgroundStyles = (props: any, theme: any) => {
  if (props.background) {
    let finalColor = props.background;
    let darkOrLight = null;

    if (typeof props.background === 'object') {
      const { dark, color } = props.background;

      if (color) {
        finalColor = color;
      }
      if (typeof dark === 'boolean') {
        darkOrLight = dark ? 'dark' : 'light';
      }
    }

    return {
      style: {
        '--bgColor': getColor(finalColor, theme)
      },
      className: [styles.bg, darkOrLight].filter(Boolean)
    };
  }

  return {};
};
