import { newBreakpoints } from '../../theme/breakpoints';
import { type BreakpointNames } from './types';

const breakpoints = Object.entries(newBreakpoints);

export const mediaQueries = breakpoints.map(([name, query]) => ({
  name,
  mediaQuery:
    typeof window !== 'undefined' && window.matchMedia
      ? window.matchMedia(`(min-width: ${query})`)
      : null
}));

export function getActiveBreakpoint(breakpoints) {
  return Object.keys(newBreakpoints)
    .reverse()
    .find((name) => breakpoints[name]) as BreakpointNames;
}
