import { cx, type CxOptions } from 'class-variance-authority';
import { extendTailwindMerge } from 'tailwind-merge';

// twMerge seems to not recognize extended values
// cc https://github.com/dcastil/tailwind-merge/issues/277#issuecomment-1734033274
const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      shadow: [{ shadow: ['glow'] }],
      'font-size': [
        {
          'text-bor': [
            'xs',
            'sm',
            'base',
            'lg',
            'xl',
            '2xl',
            '3xl',
            '4xl',
            '5xl',
            '6xl',
            '7xl',
            '8xl',
            '9xl',
            '10xl',
            '11xl'
          ]
        }
      ]
    }
  }
});

export function cn(...inputs: CxOptions) {
  return twMerge(cx(...inputs));
}
