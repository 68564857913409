const colorPalette = {
  primary: [
    '#DEFFE7',
    '#BEFFCE',
    '#9DFFB6',
    '#7DFF9D',
    '#5CFF85',
    '#05FF44',
    '#00AE2C',
    '#005716',
    '#004511'
  ],
  secondary: [
    '#BAD3EB',
    '#76A6D7',
    '#4C8BCA',
    '#234D78',
    '#0F2133',
    '#0D1D2D',
    '#0B1926',
    '#FAFAFA0A',
    '#FAFAFA14'
  ],
  constructive: [
    '#DCDCF4',
    '#B9B9E9',
    '#9595DE',
    '#7272D3',
    '#4F4FC8',
    '#3535AA',
    '#282880',
    '#1B1B55',
    '#0D0D2B'
  ],
  'constructive-alpha': ['#F1F1F7', '#E9E8F5', '#e9e9f0'],
  destructive: [
    '#FFE0DF',
    '#FFC1BF',
    '#FFA19F',
    '#FF827F',
    '#FF635F',
    '#FF1F19',
    '#D20500',
    '#8C0400',
    '#460200'
  ],
  neutral: [
    '#F1F1F2',
    '#E2E3E4',
    '#D4D5D7',
    '#C5C7C9',
    '#B7B9BC',
    '#919498',
    '#6B6E73',
    '#484A4D',
    '#242526'
  ],
  'aero-green': [
    '#EFFFF3',
    '#E5FFEB',
    '#D8FFE2',
    '#CBFFD8',
    '#B0FFC4',
    '#5AFF84',
    '#04FF43',
    '#00AC2C',
    '#005616'
  ],
  'midnight-green': [
    '#AAF3FF',
    '#55E7FF',
    '#00DAFE',
    '#0091A9',
    '#004854',
    '#003A43',
    '#002B32',
    '#001D22',
    '#000E11'
  ],
  'galaxy-blue': [
    '#E5EAFF',
    '#CBD6FE',
    '#B2C1FE',
    '#98ADFD',
    '#7E98FD',
    '#335CFC',
    '#0331E0',
    '#022095',
    '#01104B'
  ],
  'soft-coral': [
    '#FFF1EE',
    '#FFE2DD',
    '#FFD4CD',
    '#FFC5BC',
    '#FFB7AB',
    '#FF6E56',
    '#FF2501',
    '#AA1800',
    '#550C00'
  ],
  'alabaster-white': ['#FAFAFA', '#C8C8C8', '#969696', '#646464', '#323232', '#EFEFF8']
};

const colorLevels = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

interface Colors {
  [key: string]: string;
}

const colors: Colors = {
  black: '#000000',
  white: '#ffffff',
  space: '#1d1929',
  'space-black': '#0F2133',
  'space-black-low-opacity': 'rgba(15, 33, 51, 0.1)',
  primary: colorPalette.primary[4],
  secondary: colorPalette.secondary[4],
  neutral: colorPalette.neutral[4],
  constructive: colorPalette.constructive[4],
  destructive: colorPalette.destructive[4],
  'aero-green': colorPalette['aero-green'][4],
  'midnight-green': colorPalette['midnight-green'][4],
  'galaxy-blue': colorPalette['galaxy-blue'][4],
  'soft-coral': colorPalette['soft-coral'][4],
  'alabaster-white': colorPalette['alabaster-white'][0],
  'nebula-indigo': colorPalette.constructive[4],
  'nebula-indigo-light': '#F4F4F9',
  ...Object.entries(colorPalette).reduce(
    (colors, [name, palette]) => ({
      ...colors,
      ...palette.reduce(
        (colorPalette, color, index) => ({
          ...colorPalette,
          [`${name}-${colorLevels[index]}`]: color
        }),
        {}
      )
    }),
    {}
  )
};

export default colors;
