import { atom, useAtomValue } from '@konsus/atoms';
import { type ActiveBreakpoints, type ScreenSizeContextType } from './types';
import { getActiveBreakpoint, mediaQueries } from './getActiveBreakpoint';

export const initialState: ActiveBreakpoints = mediaQueries.reduce(
  (state, { name, mediaQuery }) => {
    return {
      ...state,
      [name]: mediaQuery?.matches
    };
  },
  {}
);

export const screenSizeAtom = atom<ScreenSizeContextType>({
  breakpoint: getActiveBreakpoint(initialState),
  breakpoints: initialState
});

export function useScreenSize() {
  return useAtomValue(screenSizeAtom);
}

export function useResponsiveContext() {
  return useAtomValue(screenSizeAtom).breakpoint;
}
