import React from 'react';
import { Star } from './Star';

export const Stars: React.FC = () => {
  return (
    <>
      {/* bottom-left stars */}
      <Star className='size-4 left-1 bottom-2 rotate-12 group-hover/button:-left-5 group-hover/button:-rotate-12' />
      <Star
        twinkleDelay='300ms'
        className='bottom-1 left-1 z-0 group-hover/button:-left-8 group-hover/button:-bottom-6 group-hover/button:rotate-180'
      />
      <Star
        twinkleDelay='600ms'
        className='size-4 bottom-1 left-6 -rotate-12 group-hover/button:-bottom-4 group-hover/button:rotate-12'
      />
      {/* top-right stars */}
      <Star
        twinkleDelay='900ms'
        className='size-4 top-1 right-3 rotate-12 group-hover/button:-top-4 group-hover/button:-rotate-12'
      />
      <Star
        twinkleDelay='1200ms'
        className='top-1 right-1 group-hover/button:-right-8 group-hover/button:-top-6 group-hover/button:rotate-180'
      />
      <Star
        twinkleDelay='1500ms'
        className='size-4 right-1 top-4 -rotate-12 group-hover/button:-right-5 group-hover/button:rotate-12'
      />
    </>
  );
};
