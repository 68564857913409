import { memoizeWith, identity } from 'ramda';
import { grommetRebaseSpacesMap } from '../theme/spaces';
import { textSizeWithCssProps } from '../theme/text-size';

const createPropertyUtility = (propName) => {
  return (property) => {
    const props = {};

    if (property === null) {
      return props;
    }

    if (typeof property !== 'object') {
      props[propName] = getPropValueWithPx(property);

      return props;
    }

    if (property.vertical) {
      const verticalMargin = getPropValueWithPx(property.vertical);

      props[`${propName}t`] = verticalMargin;
      props[`${propName}b`] = verticalMargin;
    } else {
      if (property.top) {
        props[`${propName}t`] = getPropValueWithPx(property.top);
      }
      if (property.bottom) {
        props[`${propName}b`] = getPropValueWithPx(property.bottom);
      }
    }

    if (property.horizontal) {
      const horizontalMargin = getPropValueWithPx(property.horizontal);

      props[`${propName}l`] = horizontalMargin;
      props[`${propName}r`] = horizontalMargin;
    } else {
      if (property.left) {
        props[`${propName}l`] = getPropValueWithPx(property.left);
      }
      if (property.right) {
        props[`${propName}r`] = getPropValueWithPx(property.right);
      }
    }

    return props;
  };
};

const getPropValueWithPx = (val) => {
  if (val.indexOf('px') !== -1 || val.indexOf('%') !== -1) {
    return val;
  }

  return grommetRebaseSpacesMap[val];
};

export const getFontSize = memoizeWith(identity, (value) => {
  if (typeof value === 'string') {
    const themeSize = textSizeWithCssProps[value];

    if (themeSize) {
      return themeSize;
    }

    return {
      fontSize: value
    };
  }

  return value;
});

export const getMargins = createPropertyUtility('m');
