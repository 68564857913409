export const spaces = [0, 1, 4, 8, 16, 24, 32, 48, 64, 88, 128, 168];

export const grommetRebaseSpacesMap = {
  none: 0,
  hair: 1,
  xxsmall: 2,
  xsmall: 3,
  small: 4,
  medium: 5,
  large: 6,
  xlarge: 7,
  xxlarge: 8,
  xxxlarge: 9,
  xxxxlarge: 10,
  xxxxxlarge: 11,
  auto: 'auto'
};

export default spaces;
