import React, { useEffect, useRef } from 'react';
import { cn } from '../../lib';
import { capProgressBarHeight, checkOverlappingItems, getScrollableParent } from './utils';
import { contentCva, gridCva, highlightColorCva, labelCva } from './styles';

export interface VerticalListProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode;
  items?: { label?: string | number | React.ReactNode; content?: React.ReactNode }[];
  labelType?: 'custom' | 'circle' | 'tag';
  // FIXME remove 'green' | 'blue' once all cases migrated
  highlightColor?: 'primary' | 'secondary' | 'green' | 'blue';
  withGlow?: boolean;
}

export const VerticalList: React.FC<VerticalListProps> = ({
  title,
  items = [],
  labelType = 'circle',
  highlightColor: highlightColorProp = 'secondary',
  withGlow = true,
  className,
  ...rest
}) => {
  // FIXME remove mapping once all cases migrated
  const highlightColor =
    highlightColorProp === 'blue'
      ? 'secondary'
      : highlightColorProp === 'green'
      ? 'primary'
      : highlightColorProp;

  const withTitle = Boolean(title);
  const withProgress = labelType !== 'custom';

  const activeBordersRef = useRef<HTMLDivElement[]>([]);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const progressThumbRef = useRef<HTMLDivElement>(null);
  const contentsRef = useRef<HTMLSpanElement[]>([]);

  useEffect(() => {
    if (!withProgress) {
      return () => {};
    }

    const scrollableParent = getScrollableParent(progressThumbRef.current);

    const checkOverlappingItemsBind = () =>
      checkOverlappingItems({ activeBordersRef, progressThumbRef });

    scrollableParent.addEventListener('scroll', checkOverlappingItemsBind);

    const capProgressBarHeightBind = () =>
      capProgressBarHeight({
        activeBordersRef,
        contentsRef,
        progressBarRef,
        callback: checkOverlappingItemsBind
      });

    scrollableParent.addEventListener('resize', capProgressBarHeightBind);

    capProgressBarHeightBind();

    return () => {
      scrollableParent.removeEventListener('scroll', checkOverlappingItemsBind);
      scrollableParent.removeEventListener('resize', capProgressBarHeightBind);
    };
  }, [items, withProgress]);

  return (
    <div
      className={cn(
        '768:flex-row 1280:gap-12 1920:gap-24 flex w-full flex-col items-start justify-center gap-10',
        className
      )}
      {...rest}
    >
      {withTitle && (
        <div className='text-bor-foreground 768:sticky 768:top-28 768:max-w-[50%] w-fit'>
          {title}
        </div>
      )}

      <div className={gridCva({ withProgress, withTitle })}>
        {withProgress && (
          <div
            className='bg-bor-neutral-200 col-start-1 row-start-1 mb-[var(--marginBottom)] flex w-0.5 items-start justify-self-center overflow-y-clip'
            style={{ gridRowEnd: items.length + 1, '--marginBottom': '0' } as React.CSSProperties}
            ref={progressBarRef}
          >
            <div className='sticky top-1/2 -mt-1'>
              <div
                className={cn(
                  highlightColorCva({ highlightColor, withGlow }),
                  'absolute bottom-0 h-screen w-0.5'
                )}
                ref={progressThumbRef}
              />
            </div>
          </div>
        )}

        {items.map((item, i) => (
          <React.Fragment key={`${item.content}-${i}`}>
            {labelType === 'custom' ? (
              item.label
            ) : (
              <span
                className='relative col-start-1 justify-self-center'
                style={{ gridRowStart: i + 1 }}
              >
                <div className={labelCva({ labelType })}>
                  {/* border-inactive */}
                  <div className='bg-bor-neutral-200 absolute inset-0 z-0 rounded-full' />
                  {/* border-active */}
                  {withProgress && (
                    <div
                      ref={(e: HTMLDivElement) => (activeBordersRef.current[i] = e)}
                      className={cn(
                        highlightColorCva({ highlightColor, withGlow }),
                        'z-1 absolute inset-0 origin-top scale-[var(--scale)] rounded-full'
                      )}
                      style={{ '--scale': '0' } as React.CSSProperties}
                    />
                  )}
                  {/* background */}
                  <div className='bg-bor-page-background-solid absolute inset-0.5 z-[2] rounded-full' />
                  <span className='text-nowrap z-3'>{item.label || i + 1}</span>
                </div>
              </span>
            )}
            <span
              ref={(e: HTMLSpanElement) => (contentsRef.current[i] = e)}
              className={contentCva({ withProgress })}
            >
              {item.content}
            </span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
