import React, { useEffect, useState } from 'react';
import { cn } from '../../lib';
import { GridSelectOption, type GridSelectOptionProps } from './GridSelectOption';
import { type GridSelectValue, type GridSelectSizeType, type GridSelectColumns } from './types';
import { gridSelectCva } from './styles';
import { isOptionInValue, toggleOptionInMultipleValue } from './utils';

export interface GridSelectProps extends Omit<React.HTMLAttributes<HTMLElement>, 'onChange'> {
  withMultiple?: boolean;
  size?: GridSelectSizeType;
  options?: Omit<GridSelectOptionProps, 'size'>[];
  desktopColumns?: GridSelectColumns;
  mobileColumns?: GridSelectColumns;
  value?: GridSelectValue;
  setValue?: React.Dispatch<React.SetStateAction<GridSelectValue>>;
  onChange?: (value: GridSelectValue) => void;
}

export const GridSelect: React.FC<GridSelectProps> = ({
  value: externalValue,
  setValue: externalSetValue,
  withMultiple = false,
  size = 'md',
  desktopColumns = 3,
  mobileColumns = desktopColumns,
  options = [],
  onChange,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<GridSelectValue>(externalValue);

  const isControlled = externalValue && externalSetValue;
  const value = isControlled ? externalValue : internalValue;
  const setValue = isControlled ? externalSetValue : setInternalValue;

  const onSelectValue = (value: string) => {
    if (withMultiple) {
      setValue((oldValues) => toggleOptionInMultipleValue(value, oldValues));
    } else {
      setValue((oldValue) => (oldValue === value ? undefined : value));
    }
  };

  useEffect(() => {
    onChange?.(value);
  }, [value]);

  return (
    <div className={cn(gridSelectCva({ mobileColumns, desktopColumns, size }))} {...rest}>
      {options.map((option) => (
        <GridSelectOption
          key={option.value}
          size={size}
          isSelected={isOptionInValue(option.value, value)}
          onSelectValue={onSelectValue}
          {...option}
        />
      ))}
    </div>
  );
};
