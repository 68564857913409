import React from 'react';
import NextImage, { type SanityImage } from '@konsus/image';
import { type GridSelectProps } from './GridSelect';
import {
  gridSelectOptionCva,
  gridSelectOptionImageContainerCva,
  gridSelectOptionImageSize
} from './styles';

export interface GridSelectOptionProps {
  size?: GridSelectProps['size'];
  title?: string | React.ReactNode;
  value?: string;
  isSelected?: boolean;
  onSelectValue?: (value: string) => void;
  children?: React.ReactNode;
  iconPick?: { name?: string; svg: string };
  image?: SanityImage;
  imageAlt?: string;
}

export const GridSelectOption: React.FC<GridSelectOptionProps> = ({
  value = '',
  isSelected = false,
  onSelectValue = () => {},
  size = 'md',
  children,
  title,
  iconPick,
  image,
  imageAlt = ''
}) => {
  const hasImage = Boolean(image);
  const hasIcon = Boolean(iconPick?.svg);

  return (
    <button
      className={gridSelectOptionCva({ size, isSelected, hasImage })}
      onClick={() => onSelectValue(value)}
      role='option'
      aria-selected={isSelected}
    >
      <div className='flex items-center gap-2'>
        {hasImage && (
          <div className={gridSelectOptionImageContainerCva({ size })}>
            <NextImage
              objectFit='cover'
              objectPosition='center'
              image={image!}
              layout='fixed'
              alt={imageAlt}
              height={gridSelectOptionImageSize[size]}
              width={gridSelectOptionImageSize[size]}
            />
          </div>
        )}

        {hasIcon && <span dangerouslySetInnerHTML={{ __html: iconPick!.svg }} />}

        {children || title}
      </div>
    </button>
  );
};
