import React from 'react';
import { cva } from 'class-variance-authority';
import Box, { type BoxProps } from '../Box';
import { useCardTilt } from './useCardTilt';

export const cardSizeOptions = {
  LARGE: 'lg',
  MEDIUM: 'md',
  SMALL: 'sm',
  NONE: 'none'
} as const;

export type CardSizes = (typeof cardSizeOptions)[keyof typeof cardSizeOptions];

export const cardSizes = cva('', {
  variants: {
    variant: {
      [cardSizeOptions.LARGE]: '1280:max-w-[656px] w-auto',
      [cardSizeOptions.MEDIUM]: '1280:max-w-[430px] w-auto',
      [cardSizeOptions.SMALL]: '1280:max-w-[352px] w-auto',
      [cardSizeOptions.NONE]: ''
    }
  }
});

export interface BentoCardProps {
  variant?: CardSizes;
  glassy?: BoxProps['glassy'];
  className?: string;
  disabledMobile?: boolean;
  children?: React.ReactNode;
}

export const BentoCard: React.FC<BentoCardProps> = ({
  variant = 'none',
  glassy = 'border',
  className,
  disabledMobile = false,
  children
}) => {
  const { cardRef, wrapperClassName } = useCardTilt({ disabledMobile });

  return (
    <div className={`${wrapperClassName} h-full w-full flex-1`}>
      <Box ref={cardRef} glassy={glassy} className={cardSizes({ variant, className })}>
        {children}
      </Box>
    </div>
  );
};

export default BentoCard;
