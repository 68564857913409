import { cva } from 'class-variance-authority';

export const gridCva = cva('1280:gap-y-24 1280:gap-x-8 grid w-full gap-x-3 gap-y-12', {
  variants: {
    withProgress: {
      true: 'grid-cols-[auto_auto]',
      false: '1280:grid-cols-[auto_auto] grid-cols-1'
    },
    withTitle: {
      true: '768:max-w-[50%]',
      false: '768:max-w-4xl w-full'
    }
  }
});

export const labelCva = cva('text-bor-foreground relative flex items-center justify-center', {
  variants: {
    labelType: {
      // had to add h-14 and w-14 as size-14 wasn't working on lego Storybook 🤷‍♂️
      circle: 'size-14 h-14 w-14 rounded-full text-2xl font-bold',
      tag: 'rounded-full px-4 py-2 text-xs font-semibold uppercase',
      image: ''
    }
  }
});

export const contentCva = cva('text-bor-foreground', {
  variants: {
    withProgress: {
      true: 'col-start-2',
      false: '1280:mt-0 -mt-8'
    }
  }
});

// UTILITY

export const highlightColorCva = cva('', {
  variants: {
    highlightColor: {
      secondary: 'bg-bor-secondary shadow-bor-secondary',
      primary: 'bg-bor-primary shadow-bor-primary'
    },
    withGlow: {
      false: '',
      true: 'shadow-glow'
    }
  }
});
