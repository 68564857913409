import { useEffect, useRef } from 'react';

export type UseCardTiltOptions = {
  disabled?: boolean;
  disabledMobile?: boolean;
  initialRotation?: string;
};

export const useCardTilt = ({
  disabledMobile = false,
  disabled,
  initialRotation
}: UseCardTiltOptions) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const wrapperClassName = '[perspective:1000px]';

  useEffect(() => {
    const card = cardRef.current;

    if (!card) {
      return;
    }

    const handleMouseMove = (e: MouseEvent) => {
      if ((disabledMobile && window.innerWidth <= 768) || disabled) {
        return;
      }

      const rect = card.getBoundingClientRect();
      const cardWidth = rect.width;
      const cardHeight = rect.height;
      const centerX = rect.left + cardWidth / 2;
      const centerY = rect.top + cardHeight / 2;
      const mouseX = e.clientX - centerX;
      const mouseY = e.clientY - centerY;
      const rotateX = ((+1 * mouseY) / (cardHeight / 2)).toFixed(2);
      const rotateY = ((-1 * mouseX) / (cardWidth / 2)).toFixed(2);

      card.style.transition = 'all 0.2s cubic-bezier(0.25,0.59,0.91,0.41)';
      card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const handleMouseLeave = () => {
      card.style.transform = 'perspective(1000px) rotateX(0deg) rotateY(0deg)';
      if (initialRotation) {
        card.style.transform = `rotate(${initialRotation}deg)`;
      }
    };

    card.addEventListener('mousemove', handleMouseMove);
    card.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      card.removeEventListener('mousemove', handleMouseMove);
      card.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [disabledMobile, disabled, initialRotation]);

  return { cardRef, wrapperClassName };
};
