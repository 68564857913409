import { type StyleFn, getFontSize } from '../utils';

// Text align
export type TextAlign = keyof typeof textAlignStyles;

const textAlignStyles = {
  center: 'text-center',
  start: 'text-left',
  end: 'text-end'
};

export const useTextAlignStyles: StyleFn = (props) => {
  if (props.textAlign && textAlignStyles[props.textAlign]) {
    return {
      className: textAlignStyles[props.textAlign]
    };
  }

  return {};
};

export const useTextSizeStyles: StyleFn = (props) => {
  if (props.size) {
    const { fontSize, lineHeight } = getFontSize(props.size);

    return {
      className:
        'leading-[var(--line-height-token-4d3a)] text-[length:var(--font-size-token-4420)]',
      style: {
        '--font-size-token-4420': fontSize,
        '--line-height-token-4d3a': lineHeight
      }
    };
  }

  return {};
};

export const useFontWeightStyles = (props, theme) => {
  if (props.weight) {
    return {
      className: 'font-[number:var(--font-weight-token-5d9e)]',
      style: {
        '--font-weight-token-5d9e': theme.fontWeights[props.weight]
      }
    };
  }

  return {};
};
