import React, { type PropsWithChildren } from 'react';
import { cn } from '../../lib';
import { Text, type TextProps } from '../Text';
import { headingCva } from './styles';
import { type HeadingSize, type HeadingLevel } from './types';

export type HeadingProps = PropsWithChildren &
  React.HTMLAttributes<HTMLHeadingElement> &
  Pick<TextProps, 'underline'> & {
    /** DEPRECATED: use level instead */
    type?: HeadingLevel;
    level?: HeadingLevel;
    /** Used for SEO to different tag than the styles applied (e.g.: render as h1 with styles of h4) */
    as?: HeadingLevel;
    size?: HeadingSize;
    className?: string;
  };

export const Heading: React.FC<HeadingProps> = ({
  type = 'h1',
  level = type,
  as = level,
  size = 'responsive',
  className,
  underline,
  children,
  ...rest
}) => {
  const HeadingElement = as === 'mega' || as === 'epic' ? 'h1' : as;

  return (
    <HeadingElement
      className={cn(headingCva({ level, size }), className)}
      data-testid='heading'
      {...rest}
    >
      {underline ? (
        <Text underline={underline} size='inherit'>
          {children}
        </Text>
      ) : (
        children
      )}
    </HeadingElement>
  );
};
