import { useEffect } from 'react';
import { useAtomMutator } from '@konsus/atoms';
import { detectFlexGapSupport } from './detectFlexGapSupport';
import { browserFeaturesAtom } from './browserFeaturesAtom';

export const BrowserFeaturesUpdater = () => {
  const setBrowserFeatures = useAtomMutator(browserFeaturesAtom);

  useEffect(() => {
    detectFlexGapSupport((flexGap) => {
      setBrowserFeatures((value) => ({
        ...value,
        flexGap
      }));
    });
  }, [setBrowserFeatures]);

  return null;
};
