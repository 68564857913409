import React, { forwardRef } from 'react';
import { Spinner } from 'phosphor-react';
import { cn } from '../../lib';
import { type ButtonSizeType, type ButtonVariantType } from './types';
import { buttonWrapCva } from './styles';
import { ButtonContent } from './ButtonContent';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariantType;
  size?: ButtonSizeType;
  className?: string;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactElement;
  reverse?: boolean;
  loading?: Boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      size = 'lg',
      className,
      children,
      label,
      icon,
      loading,
      reverse,
      disabled,
      ...rest
    },
    ref
  ) => (
    <button
      ref={ref}
      className={cn(buttonWrapCva({ variant, size }), className)}
      disabled={Boolean(loading || disabled)}
      {...rest}
    >
      <ButtonContent
        variant={variant}
        icon={icon}
        reverse={reverse}
        disabled={Boolean(loading || disabled)}
        size={size}
      >
        {loading ? (
          <span className='text-bor-neutral-400 mx-auto flex items-center justify-center gap-4 text-center'>
            <Spinner weight='fill' className='animate-spin' size={20} />
            <span>Loading....</span>
          </span>
        ) : (
          children || label
        )}
      </ButtonContent>
    </button>
  )
);

Button.displayName = 'Button';
