import React from 'react';
import { CaretRight, CaretLeft } from 'phosphor-react';
import { cn } from '../../lib';
import { type ButtonProps } from './Button';
import { spanCva } from './styles';
import { Stars } from './FlashyButton';

const DefaultRightIcon = <CaretRight weight='bold' />;
const DefaultLeftIcon = <CaretLeft weight='bold' />;

export interface ButtonContentProps
  extends Pick<ButtonProps, 'variant' | 'children' | 'icon' | 'reverse' | 'disabled' | 'size'> {}

export const ButtonContent: React.FC<ButtonContentProps> = ({
  variant,
  reverse,
  icon = reverse ? DefaultLeftIcon : DefaultRightIcon,
  children,
  disabled,
  size
}) => {
  switch (variant) {
    case 'secondary':
    case 'ghost':
      return (
        <span
          className={cn('flex items-center justify-center gap-2', reverse && 'flex-row-reverse')}
        >
          <span className={cn(spanCva({ variant, size }))} aria-disabled={disabled}>
            {children}
          </span>
          <span
            className={cn(
              'transition-transform duration-300 group-hover/button:transition-transform group-hover/button:duration-300',
              reverse ? 'group-hover/button:-translate-x-1' : 'group-hover/button:translate-x-1'
            )}
          >
            {icon}
          </span>
        </span>
      );

    case 'iconFill':
    case 'iconGhost':
      return icon;
    case 'flashy':
      return (
        <>
          <Stars />
          <div className={cn(spanCva({ variant, size }))} aria-disabled={disabled}>
            <span>{children}</span>
          </div>
        </>
      );
    default:
      return (
        <div className={cn(spanCva({ variant, size }))} aria-disabled={disabled}>
          <span>{children}</span>
        </div>
      );
  }
};
