import React, { useEffect, useState } from 'react';
import { Heading, cn } from '@superside/ui';
import { useAtomValue } from '@konsus/atoms';
import { Animation } from '../Animation';
import { Superscript } from '..';
import { type ChilipiperLoaderAtomType, chilipiperLoaderAtom } from './chilipiperLoaderAtom';
import styles from './ChilipiperLoader.module.css';

const ChilipiperLoader: React.FC = () => {
  const chilipiperLoaderData = useAtomValue(chilipiperLoaderAtom);
  const [data, setData] = useState<ChilipiperLoaderAtomType | null>();

  useEffect(() => {
    const timeout = chilipiperLoaderData?.enabled ? 0 : 1000;

    setTimeout(() => {
      setData(chilipiperLoaderData);
    }, timeout);
  }, [chilipiperLoaderData]);

  return data?.enabled ? (
    <div
      data-theme='aurora'
      className={cn(
        'fixed inset-0 z-[9999999] h-full w-full transition-all duration-1000',
        data?.enabled ? 'opacity-100' : 'opacity-0'
      )}
    >
      <div className='560:w-full <768:h-fit absolute inset-8 m-auto flex max-h-[851px] max-w-[1000px] flex-col items-center justify-center rounded-lg bg-[#F7F9FA] px-8 pb-8 pt-16'>
        <div className='max-w-[280px] -translate-x-6 translate-y-3 scale-150'>
          <Animation animationUrl={`/chilipiperLoading.json`} aspectRatio={1} />
        </div>
        <div className='flex h-48 w-full flex-col items-center'>
          <Superscript content='One final step' />
          <div className='relative w-full max-w-[304px]'>
            <Heading
              type='h5'
              className={cn('absolute inset-0 text-center', styles.phrase, styles.phrase1)}
            >
              Hang in there{data?.name ? `, ${data?.name}` : ''}
            </Heading>
            <Heading
              type='h5'
              className={cn('absolute inset-0 text-center', styles.phrase, styles.phrase2)}
            >
              Finding available slots for {data?.company ? `${data?.company}` : 'you'}
            </Heading>
            <Heading
              type='h5'
              className={cn('absolute inset-0 text-center', styles.phrase, styles.phrase3)}
            >
              Going all the way to Mars and back...
            </Heading>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ChilipiperLoader;
