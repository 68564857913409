import React, { type CSSProperties, forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '../../../lib';
import { type ButtonSizeType } from '../types';
import styles from './GlassButton.module.css';

const glassButtonCva = cva(
  `${styles.container} relative inline-block flex h-fit w-fit cursor-pointer flex-row items-center rounded-full font-semibold before:rounded-full after:rounded-full`,
  {
    variants: {
      size: {
        lg: 'gap-3 px-7 py-5 text-lg',
        md: 'gap-2 px-4  py-4 text-base',
        sm: 'gap-2 px-4 py-4 text-sm',
        base: 'gap-2 px-4 py-4 text-sm'
      }
    }
  }
);

export interface GlassButtonProps {
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  size?: ButtonSizeType;
  className?: string;
  style?: CSSProperties;
  icon?: React.ReactElement;
  onClick?: () => void;
}

export const GlassButton = forwardRef<HTMLButtonElement, GlassButtonProps>(
  ({ children, label, size = 'md', className, style, icon, ...rest }, ref) => (
    <button
      ref={ref}
      className={glassButtonCva({ size, className })}
      style={
        {
          '--transition': 'opacity 0.5s cubic-bezier(0.22, 0.68, 0, 1)',
          '--border-width': '1px',
          ...style
        } as CSSProperties
      }
      {...rest}
    >
      <div
        className={cn(
          styles.border,
          'absolute inset-0 z-0 h-full w-full rounded-full before:absolute before:inset-0 before:z-0 before:h-full before:w-full before:rounded-full after:absolute after:inset-0 after:z-0 after:h-full after:w-full after:rounded-full'
        )}
      />

      {icon}
      {children || label ? <span className='whitespace-nowrap'>{children || label}</span> : null}
    </button>
  )
);

GlassButton.displayName = 'GlassButton';
