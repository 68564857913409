import React, { type CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { type AnimationItem, type LottiePlayer } from 'lottie-web';
import { Box, cn, type BoxProps } from '@superside/ui';
import { cursorMagnifier } from '../VisualAsset/components/commonStyles';
import { load } from './cacheUtil';
import {
  getSegmentsFromMarker,
  loadLottieModule,
  type SegmentMarker,
  getScrollProgress
} from './lottieUtils';

const ANIMATION_PAUSE_OFFSET = '50px';

export interface AnimationProps extends BoxProps {
  animationUrl?: string | null;
  className?: string;
  fill?: boolean;
  aspectRatio?: number;
  style?: CSSProperties;
  stopAnimation?: boolean;
  lottieTrigger?: 'autoplay' | 'scroll';
  videoOnClickEnabled?: boolean;
  segmentMarkerToPlay?: SegmentMarker;
}

export const Animation: React.FC<AnimationProps> = ({
  animationUrl,
  aspectRatio,
  stopAnimation: initialStopAnimation,
  lottieTrigger = 'autoplay',
  videoOnClickEnabled = false,
  segmentMarkerToPlay,
  ...boxProps
}) => {
  const stopAnimation = lottieTrigger === 'scroll' ? true : initialStopAnimation;

  const [isPlayed, setPlayed] = useState(false);

  const elementRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem | null>(null);

  const isFirstRender = useRef<boolean>(true);

  const getProgress = () => getScrollProgress(elementRef.current);

  const startAnimation = ([lottie, animationData]: [LottiePlayer, object]) => {
    const element = elementRef?.current;

    if (!element) {
      return;
    }

    // Remove previous lottie animation's leftovers in case component props updated
    element.innerHTML = '';

    animationRef.current = lottie.loadAnimation({
      container: element,
      renderer: 'svg',
      loop: true,
      autoplay: !stopAnimation,
      animationData,
      rendererSettings: {
        progressiveLoad: true
      }
    });

    const instance = animationRef.current;

    instance.addEventListener('DOMLoaded', () => {
      const svg = instance.renderer.svgElement;

      svg.setAttribute('role', 'img');
    });

    const scrollProgress = getProgress();

    if (lottieTrigger === 'scroll' && stopAnimation && scrollProgress > 0.5) {
      instance.goToAndStop(instance.totalFrames, true);
      setPlayed(true);
    }

    if (segmentMarkerToPlay && !stopAnimation) {
      playSegmentMarker(segmentMarkerToPlay, true);
    }
  };

  const loadAnimation = () => {
    if (animationUrl) {
      Promise.all([loadLottieModule(), load(animationUrl)]).then(startAnimation);
    }
  };

  useEffect(() => {
    const element = elementRef?.current;

    if (element) {
      // Initial load is performed lazily inside IntersectionObserver,
      // this hook only handles case when animation changes
      if (!isFirstRender.current) {
        loadAnimation();
      }

      isFirstRender.current = false;

      return () => {
        element.innerHTML = '';
        animationRef.current?.destroy?.();
        animationRef.current = null;
      };
    }
  }, [animationUrl]);

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const observer = new IntersectionObserver(
        ([e]) => {
          const { isIntersecting } = e;

          if (animationRef.current) {
            if (isIntersecting && !stopAnimation) {
              animationRef.current.play();
            } else {
              animationRef.current.pause();
            }
          } else if (isIntersecting) {
            loadAnimation();
          }
        },
        {
          threshold: 0.1,
          rootMargin: ANIMATION_PAUSE_OFFSET
        }
      );

      observer.observe(element);

      return () => {
        if (observer) {
          observer.unobserve(element);
        }
      };
    }
  }, [stopAnimation]);

  useEffect(() => {
    if (lottieTrigger === 'scroll') {
      const onScroll = () => {
        const totalFrames = animationRef?.current?.totalFrames as number;
        const progress = getProgress();

        if (progress > 0 && progress < 1 && !isPlayed) {
          const frame = progress * totalFrames;

          setTimeout(() => {
            animationRef?.current?.goToAndStop(frame, true);
          }, 500);

          if (Math.abs(frame - totalFrames) <= 1) {
            setPlayed(true);
          }
        }
      };

      window.addEventListener('scroll', onScroll);

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [isPlayed, lottieTrigger]);

  const playSegmentMarker = (segmentMarker: SegmentMarker | undefined, forcePlay = false) => {
    const segments = getSegmentsFromMarker(animationRef.current, segmentMarker);

    animationRef.current?.playSegments(segments, forcePlay);
  };

  useEffect(() => {
    if (segmentMarkerToPlay) {
      playSegmentMarker(segmentMarkerToPlay, false);
    }
  }, [segmentMarkerToPlay]);

  const style = useMemo(
    () =>
      aspectRatio
        ? {
            aspectRatio: aspectRatio.toFixed(4),
            ...boxProps.style
          }
        : {},
    [aspectRatio, boxProps.style]
  );

  return (
    <Box
      {...boxProps}
      style={{ ...style, width: boxProps.width, height: boxProps.height }}
      ref={elementRef}
      className={cn('self-stretch', videoOnClickEnabled && cursorMagnifier, boxProps.className)}
    />
  );
};

export default Animation;
