import { useCallback, useState } from 'react';

export type UseLocalStorageTypeProps<T> = {
  key: string;
  initialValue?: T;
  generation?: string | number;
  daysToExpire?: number;
};

export const useLocalStorage = <T,>({
  key,
  initialValue,
  generation,
  daysToExpire
}: UseLocalStorageTypeProps<T>) => {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      if (typeof window === 'undefined') {
        // console.warn('Can`t use local storage with SSR. Initial value will be used.');
        return initialValue;
      }

      const stringValue = window && window.localStorage.getItem(key);

      if (!stringValue) {
        return initialValue;
      }

      const value = JSON.parse(stringValue);

      // reset if different generation
      if (typeof generation !== 'undefined' && value?.generation !== generation) {
        setValue(initialValue);

        return initialValue;
      }

      // reset if expired (now > lastChanged + daysToExpire)
      if (
        typeof daysToExpire !== 'undefined' &&
        (!value.lastChanged || value.lastChanged + daysToExpire * 86400000 < Date.now()) // 86400000ms = 1 day
      ) {
        setValue(initialValue);

        return initialValue;
      }

      return value.value;
    } catch (error) {
      console.error(error);

      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | undefined | ((val: T | undefined) => T)) => {
      try {
        setStoredValue((storedValue) => {
          const nextValue =
            typeof value === 'function' ? (value as (val: T | undefined) => T)(storedValue) : value;

          if (window && window.localStorage) {
            localStorage.setItem(
              key,
              JSON.stringify({
                value: nextValue,
                lastChanged: Date.now(),
                generation
              })
            );
          }

          return nextValue;
        });
      } catch (error) {
        console.error(error);
      }
    },
    [key, generation]
  );

  return [storedValue, setValue] as const;
};

export default useLocalStorage;
