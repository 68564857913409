import { cva } from 'class-variance-authority';
import * as glassStyles from './Glass.module.css';

export const boxCva = cva('flex max-w-full flex-[0_0_auto] flex-col', {
  variants: {
    glassyBackground: {
      border: '',
      fill: glassStyles.bgGlassFill,
      glare: glassStyles.bgGlassGlare,
      colored: glassStyles.bgGlassColored
    },
    glassyBorder: {
      true: '',
      false: ''
    }
  },
  compoundVariants: [
    {
      glassyBackground: 'border',
      glassyBorder: true,
      className: glassStyles.borderGlass
    },
    {
      glassyBackground: 'fill',
      glassyBorder: true,
      className: glassStyles.borderGlass
    },
    {
      glassyBackground: 'glare',
      glassyBorder: true,
      className: glassStyles.borderGlass
    },
    {
      glassyBackground: 'colored',
      glassyBorder: true,
      className: glassStyles.borderGlassColored
    }
  ]
});
