export class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotFoundError';
  }
}

export const errorToState = (error: Error) => ({
  name: error.name,
  message: error.toString()
});
