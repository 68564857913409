import { datadogRum, type RumInitConfiguration } from '@datadog/browser-rum';
import { datadogLogs, type LogsInitConfiguration } from '@datadog/browser-logs';

const IS_DEBUG = process.env.DD_DEBUG === 'true';
const ENABLE_DATADOG = process.env.NODE_ENV === 'production' || IS_DEBUG;

export const setupDatadog = (
  options: { service: string } & Partial<RumInitConfiguration> & Partial<LogsInitConfiguration>
) => {
  if (ENABLE_DATADOG) {
    setupRum(options);
    setupLogs(options);
  }
};

export const setupRum = (options?: Partial<RumInitConfiguration>) => {
  datadogRum.init({
    // service: 'superside-website',
    // TODO add dynamic version to better identify merges to issues
    // version: '2.28.5', // TODO: get from env, https://github.com/orgs/vercel/discussions/458
    applicationId: `${process.env.DD_APPLICATION_ID}`,
    clientToken: `${process.env.DD_CLIENT_TOKEN}`,
    site: 'datadoghq.com',
    env: `${process.env.DD_ENV}`,
    sessionSampleRate: IS_DEBUG ? 100 : 25,
    sessionReplaySampleRate: IS_DEBUG ? 100 : 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    ...options
  });
};

export const setupLogs = (options?: Partial<LogsInitConfiguration>) => {
  datadogLogs.init({
    // service: 'superside-website',
    // TODO add dynamic version to better identify merges to issues
    // version: '2.28.5', // TODO: get from env, https://github.com/orgs/vercel/discussions/458
    clientToken: `${process.env.DD_CLIENT_TOKEN}`,
    site: 'datadoghq.com',
    env: `${process.env.DD_ENV}`,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: IS_DEBUG ? 100 : 25,
    ...options
  });
};
