import React from 'react';
import BaseLink, { type LinkProps as BaseLinkProps } from 'adapter/next/link';
import { type Icon } from 'phosphor-react';
import { cn } from '../../lib';
import {
  type ButtonVariantType as LinkVariantType,
  type ButtonSizeType as LinkSizeType
} from './types';
import { buttonWrapCva as linkWrapCva } from './styles';
import { ButtonContent as LinkContent } from './ButtonContent';

export interface LinkProps extends BaseLinkProps {
  variant?: LinkVariantType;
  size?: LinkSizeType;
  className?: string;
  label?: string;
  children?: React.ReactNode;
  icon?: React.ReactElement<Icon>;
  target?: '_blank' | '_self' | '_parent' | '_top';
  href: string;
}

export const Link: React.FC<LinkProps> = ({
  variant = 'primary',
  size = 'lg',
  className,
  icon,
  label,
  children,
  ...rest
}) => (
  <BaseLink className={cn(linkWrapCva({ variant, size }), className)} {...rest}>
    <LinkContent variant={variant} icon={icon}>
      {children || label}
    </LinkContent>
  </BaseLink>
);
