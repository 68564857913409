import React, { useEffect, useState } from 'react';
import Img from 'adapter/next/legacy/image';
import Image from 'adapter/next/image';
import { useNextSanityImage, type UseNextSanityImageBuilderOptions } from 'next-sanity-image';
import { type ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { defaultSanityClient } from '@konsus/sanity-client';
import atom, { useAtomValue } from '@konsus/atoms';
import type {
  NextFutureImageProps,
  NextImageProps,
  SanityAsset,
  SanityImage,
  UseNextSanityImagePropsExtended
} from './types';

export const lazyRootAtom = atom<React.RefObject<HTMLElement> | null>(null);
export const imagesAltTextAtom = atom<React.RefObject<HTMLElement> | null>(null);

const SANITY_SIZE_RE = /\d+x\d+/;

function tryToExtractDimensions(maybeSize?: string) {
  if (typeof maybeSize === 'string' && SANITY_SIZE_RE.test(maybeSize)) {
    const [width, height] = maybeSize.split('x').map((size) => parseInt(size));
    const aspectRatio = width / height;

    return {
      width,
      height,
      aspectRatio
    };
  }

  return {
    width: '100%',
    height: '100%',
    aspectRatio: 1
  };
}

export function tryToExtractImageDetails(image: SanityImage | SanityAsset) {
  const maybeImageRef = image.asset?._ref ?? '';
  const [type, name, size, ext] = maybeImageRef.split('-');
  const { width, height, aspectRatio } = tryToExtractDimensions(size);

  return {
    type,
    name,
    ext,
    width,
    height,
    aspectRatio
  };
}

export function getFormatSpecificImageProps(
  image: SanityImage | SanityAsset,
  imageDetails?: ReturnType<typeof tryToExtractImageDetails>
): Partial<NextFutureImageProps> {
  if (!imageDetails) {
    imageDetails = tryToExtractImageDetails(image);
  }

  if (imageDetails?.ext === 'svg') {
    return {
      width: imageDetails.width as number,
      height: imageDetails.height as number,
      unoptimized: true,
      placeholder: 'empty',
      loading: 'eager'
    };
  }

  return {};
}

const customImageBuilder = (
  imageUrlBuilder: ImageUrlBuilder,
  options?: UseNextSanityImageBuilderOptions,
  size?: number,
  fetchPng?: boolean
) => {
  const img = imageUrlBuilder.width(
    size || Math.min(options?.originalImageDimensions?.width || 0, 1920)
  );

  if (fetchPng) {
    return img.format('png');
  }

  return img.auto('format');
};

export const NextImage: React.FC<NextImageProps> = ({ image, requestWidth, fetchPng, ...rest }) => {
  const lazyRoot = useAtomValue(lazyRootAtom);
  const imagesAltText: any = useAtomValue(imagesAltTextAtom);
  const imageProps: UseNextSanityImagePropsExtended = useNextSanityImage(
    defaultSanityClient,
    image,
    {
      imageBuilder: (build, options) => customImageBuilder(build, options, requestWidth, fetchPng)
    }
  );
  const [lazyBoundary, setLazyBoundary] = useState(600);
  const [title, setTitle] = useState(rest.title || '');
  const [alt, setAlt] = useState(rest.alt || '');
  const imgAttr = { ...imageProps, ...rest };

  useEffect(() => {
    setLazyBoundary(window.innerHeight);

    if (!imagesAltText || !imagesAltText[image?.asset?._ref]) {
      return;
    }

    if (!rest.alt) {
      setAlt(imagesAltText[image?.asset?._ref].alt);
    }

    if (!rest.title) {
      setTitle(imagesAltText[image?.asset?._ref].title);
    }
  }, [image?.asset?._ref, imagesAltText, rest.alt, rest.title]);

  if (imgAttr.layout === 'fill' && (imgAttr.width || imgAttr.height)) {
    // @ts-expect-error
    delete imgAttr.width;
    // @ts-expect-error
    delete imgAttr.height;
  }

  return (
    <Img
      {...imgAttr}
      lazyRoot={lazyRoot}
      lazyBoundary={`${lazyBoundary}px`}
      title={title}
      alt={alt}
    />
  );
};

export const NextFutureImage: React.FC<NextFutureImageProps & { fetchPng?: boolean }> = ({
  image,
  requestWidth,
  fetchPng,
  ...rest
}) => {
  const imageProps: UseNextSanityImagePropsExtended = useNextSanityImage(
    defaultSanityClient,
    image,
    {
      imageBuilder: (build, options) => customImageBuilder(build, options, requestWidth, fetchPng)
    }
  );
  const imgAttr = { ...imageProps, ...rest };
  const imagesAltText: any = useAtomValue(imagesAltTextAtom);
  const [alt, setAlt] = useState(rest.alt || '');
  const [title, setTitle] = useState(rest.title || '');
  const imageData = imagesAltText ? imagesAltText[image?.asset?._ref] : null;

  useEffect(() => {
    if (!imagesAltText || !imageData) {
      return;
    }

    if (!rest.alt) {
      setAlt(imageData.alt);
    }

    if (!rest.title) {
      setTitle(imageData.title);
    }
  }, [image?.asset?._ref, imageData, imagesAltText, rest.alt, rest.title]);

  if (rest.layout || rest.fill) {
    // @ts-expect-error
    delete imgAttr.width;
    // @ts-expect-error
    delete imgAttr.height;
  }

  return <Image {...imgAttr} title={title} alt={alt} />;
};

export * from './types';
export default NextImage;
