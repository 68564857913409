import { type TextProps } from '../Text';

export const TagSizes = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xs: 'xs'
} as const;

export const TagVariants = {
  fill: 'fill',
  outline: 'outline',
  glassy: 'glassy'
} as const;

export type TagSize = keyof typeof TagSizes;
export type TagVariant = keyof typeof TagVariants;

/** Mapping of Tag sizes to Text sizes */
export const TagToTextSize: { [key in TagSize]: TextProps['size'] } = {
  lg: 'base',
  md: 'sm',
  sm: 'xs',
  xs: 'xs'
};

/** Mapping of Tag sizes to Text icon sizes */
export const TagToTextIconSize: { [key in TagSize]: TextProps['size'] } = {
  lg: 'lg',
  md: 'base',
  sm: 'sm',
  xs: 'xs'
};
