import React from 'react';
import { type BoxProps } from '../../Layout';
import { SuperadsLogo } from './SuperadsLogo';
import { SupersideLogo } from './SupersideLogo';

export type LogoProps = BoxProps & {
  app?: 'superside' | 'superads';
};

export const Logo: React.FC<LogoProps> = ({ app = 'superside', ...rest }) => {
  if (app === 'superads') {
    return (
      <div className='flex items-center justify-center gap-2'>
        <SuperadsLogo {...rest} />
        <div className='dark:text-black-100 mt-1 inline-block h-1/2 rounded-xl bg-[#e3e0d7] px-2 py-1 text-[0.6rem] font-bold tracking-widest'>
          BETA
        </div>
      </div>
    );
  }

  return <SupersideLogo {...rest} />;
};

export default Logo;
