import { type Schema, validate } from 'jsonschema';

interface Action {
  name?: string; // matched to (req.query.action || req.method)
  schema?: Schema; // validates query
  fn: (req, res, { params }) => any | Promise<any>;
}

/**
 * Api requests controller
 * Validates query, handles errors, processes response
 * @example:
   // define
   const myGate = new ApiGate('myGate')
    .action({
      name: 'get',
      schema: {
        required: ['myParam'],
        properties: { myParam: { type: 'string' } }
      },
      fn: (req, res, { params }) => {
        if (needToSend404) {
          throw this.createNotFoundError('smth not found')
        }
        return 'get response'
      }
    })
    .action({
      name: 'post',
      fn: (req,res,{ params }) => {
        if (needToSend400) {
          throw this.createBadRequestError('smth bad with params')
        }
        return 'post response'
      }
    })
    .action({
      name: 'customAction',
      fn: (req,res,{ params }) => {
        return 'custom action response'
      }
    });

  // use
  myGate.handler(res, res);
 */

class ApiGate {
  _name = null;

  _actions = {};

  constructor(name) {
    this._name = name;
  }

  action(action: Action | Action['fn']) {
    if (typeof action === 'function') {
      action = { fn: action } as Action;
    }

    const { name = 'get', fn, schema } = action;

    if (typeof fn !== 'function') {
      throw new Error(this.buildErrorMessage(`Action's fn is required`));
    }
    if (this._actions[name]) {
      throw new Error(this.buildErrorMessage(`Action ${name} already defined`));
    }

    this._actions[name] = (req, res, { params }) => {
      if (schema) {
        const { valid, errors } = validate(params || {}, schema);

        if (!valid) {
          throw this.createBadRequestError(
            this._buildValidationErrorMessage(`${this._name}.${name}`, errors)
          );
        }
      }

      return fn.call(this, req, res, { params });
    };

    return this;
  }

  async handleRequest(req, res) {
    const { actionName = req.method.toLowerCase(), ...params } = req.query || {};

    let data;
    let error;
    let statusCode;

    try {
      const action = this._actions[actionName];

      if (!action) {
        throw this.createNotFoundError(
          this.buildErrorMessage(`Action ${actionName} is not defined`)
        );
      }

      data = await action(req, res, { params });
    } catch (e) {
      if (typeof e === 'string') {
        error = e;
      } else {
        statusCode = e.statusCode;
        error = e.message;
      }
    }

    if (error) {
      res.status(statusCode || 500).json({ error });
    } else {
      res.status(statusCode || 200).json(data || {});
    }
  }

  get handler() {
    return this.handleRequest.bind(this);
  }

  createNotFoundError(message) {
    return {
      statusCode: 404,
      message
    };
  }

  createBadRequestError(message) {
    return {
      statusCode: 400,
      message
    };
  }

  buildErrorMessage(message) {
    return `${this._name}: ${message}`;
  }

  _buildValidationErrorMessage(prefix, errors) {
    if (errors && errors.length) {
      return errors
        .map((error) => {
          if (error.subErrors) {
            return this._buildValidationErrorMessage(prefix, error.subErrors);
          }

          return `${prefix}.${error.message}`;
        })
        .join(', ');
    }

    return `${prefix} validation error`;
  }
}

export default ApiGate;
