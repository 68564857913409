import { cva } from 'class-variance-authority';

export const textCva = cva('', {
  variants: {
    size: {
      lg: 'text-bor-lg 768:text-bor-xl 1280:text-bor-2xl',
      base: 'text-bor-base 768:text-bor-base 1280:text-bor-lg',
      sm: 'text-bor-sm 768:text-bor-sm 1280:text-bor-base',
      xs: 'text-bor-xs 768:text-bor-xs 1280:text-bor-sm',
      inherit: ''
    },
    underline: {
      true: 'underline',
      false: '',
      undefined: '',
      animated:
        'bg-[linear-gradient(currentColor,currentColor)] bg-[length:0%_var(--underline-height)] bg-left-bottom bg-no-repeat transition-[background] duration-[var(--underline-speed)] hover:bg-[length:100%_var(--underline-height)] group-hover:bg-[length:100%_var(--underline-height)]'
    }
  },
  defaultVariants: {
    size: 'base',
    underline: false
  }
});
