import React, { type InputHTMLAttributes } from 'react';
import { cn } from '../../lib';
import { baseClasses } from './styles';

// TODO replace deprecated onKeyPress events
export interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onKeyPress'> {
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, ...rest }, ref) => {
    // @ts-ignore: can't match onKeyPress types
    return <input ref={ref} {...rest} className={cn(baseClasses, className)} />;
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
