import dynamic from 'adapter/next/dynamic';

const PricingPlansV3 = dynamic(
  () => import(/* webpackChunkName: "Lego_PricingPlansV3" */ './PricingPlansV3')
);
const OurWorkSamplesV2 = dynamic(
  () => import(/* webpackChunkName: "Lego_OurWorkSamplesV2" */ './OurWorkSamplesV2')
);
const Faq = dynamic(() => import(/* webpackChunkName: "Lego_Faq" */ './Faq'));
const TilesDeck = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_TilesDeck" */ '../blocks/TilesDeck')
);
const Statistics = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_Statistics" */ './Statistics')
);
const LogoSalad = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_LogoSalad" */ './LogoSalad')
);
const Accordion = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_Accordion" */ './Accordion')
);
const JobsList = dynamic(() => import(/* webpackChunkName: "New_Blocks_JobsList" */ './JobsList'));
const HeroCenter = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_HeroCenter" */ './HeroCenter')
);
const SuperHero = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_SuperHero" */ './SuperHero')
);
const NewShowcase = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_Showcase" */ './Showcase')
);
const KeyFeatures = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_KeyFeatures" */ './KeyFeatures')
);

const KeyFeaturesV2 = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_KeyFeaturesV2" */ './KeyFeaturesV2')
);
const VisualAssetSection = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_VisualAssetSection" */ './VisualAssetSection')
);
const PeopleCardsSection = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_PeopleCardsSection" */ './PeopleCardsSection')
);
const Banner = dynamic(() => import(/* webpackChunkName: "New_Blocks_Banner" */ './Banner'));
const Quotes = dynamic(() => import(/* webpackChunkName: "New_Blocks_Quotes" */ './Quotes'));
const QuoteSingular = dynamic(() =>
  import(/* webpackChunkName: "SingularQuote" */ './QuoteSingular/QuoteSingular').then(
    (module) => module.QuoteSingular
  )
);
const BookACallForm = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_BookACallForm" */ './BookACallForm')
);
const ContentGridSnippet = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_ContentGridSnippet" */ './ContentGridSnippet')
);
const HeroFormSection = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_HeroFormSection" */ './HeroFormSection')
);
const HeroTicket = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_HeroTicket" */ './HeroTicket')
);
const TextBlockSection = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_TextBlockSection" */ './TextBlockSection')
);
const HorizontalTilesDeck = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_HorizontalTilesDeck" */ './HorizontalTilesDeck')
);
const EventSchedule = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_EventSchedule" */ './EventSchedule')
);
const DataBlocks = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_DataBlocks" */ './DataBlocks')
);
const HeroLeft = dynamic(() => import(/* webpackChunkName: "New_Blocks_HeroLeft" */ './HeroLeft'));
const AssetsGrid = dynamic(
  () => import(/* webpackChunkName: "OurWorkPreview_Blocks_AssetsGrid" */ './AssetsGrid')
);
const ScrollableImage = dynamic(
  () => import(/* webpackChunkName: "OurWorkPreview_Blocks_ScrollableImage" */ './ScrollableImage')
);
const InternalLinkingSection = dynamic(
  () =>
    import(/* webpackChunkName: "New_Blocks_InternalLinkingSection" */ './InternalLinkingSection')
);
const Introduction = dynamic(
  () => import(/* webpackChunkName: "OurWorkPreview_Blocks_Introduction" */ './Introduction')
);

const ComparisonTableSection = dynamic(
  () =>
    import(/* webpackChunkName: "New_Blocks_ComparisonTableSection" */ './ComparisonTableSection')
);
const ExitPopup = dynamic(() => import(/* webpackChunkName: "Exit_Popup" */ './ExitPopup'));
const VideoWithQuote = dynamic(
  () => import(/* webpackChunkName: "New Blocks" */ './VideoWithQuote')
);
const Personas = dynamic(() => import(/* webpackChunkName: "New_Blocks_Personas" */ './Personas'));
const CardsSection = dynamic(
  () => import(/* webpackChunkName: "New Blocks_CardsSection" */ './CardsSection')
);
const Pillars = dynamic(() => import(/* webpackChunkName: "New_Blocks_Pillars" */ './Pillars'));
const AllContentGrid = dynamic(
  () => import(/* webpackChunkName: "New Blocks_AllContentGrid" */ './AllContentGrid')
);
const OurWorkShowcase = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_OurWorkShowcase" */ './OurWorkShowcase')
);
const SamplesCarousel = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_SamplesCarousel" */ './SamplesCarousel')
);
const SectionHeader = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_SectionHeader" */ './SectionHeader')
);
const PreFooterLinks = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_PreFooterLinks" */ './PreFooterLinks')
);
const PricingPlansV4 = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_PricingPlansV4" */ './PricingPlansV4')
);
const ListColumns = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_PreFooterLinks" */ './ListColumns')
);
const QuotesWithCustomerStories = dynamic(
  () =>
    import(
      /* webpackChunkName: 'New_Blocks_QuotesWithCustomerStory' */ './QuotesWithCustomerStories'
    )
);
const Timeline = dynamic(() => import(/* webpackChunkName: "New_Blocks_Timeline" */ './Timeline'));
const ServicesTiles = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_ServicesTiles" */ './ServicesTiles')
);
const PeopleSection = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_PeopleSection" */ './PeopleSection')
);

const MultiCards = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_MultiCards" */ './MultiCards')
);
const SlidesComponent = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_SlidesComponent" */ './SlidesComponent')
);

const VibeSwitch = dynamic(
  () => import(/* webpackChunkName: "New_Blocks_MultiCards" */ './VibeSwitch')
);

const HighlightColumns = dynamic(() =>
  import(/* webpackChunkName: "HighlightColumns" */ './HighlightColumns').then(
    (module) => module.HighlightColumns
  )
);

const components = {
  superHero: SuperHero,
  heroCenter: HeroCenter,
  heroLeft: HeroLeft,
  heroWithForm: HeroFormSection,
  heroWithTicket: HeroTicket,
  logoSalad: LogoSalad,
  accordion: Accordion,
  tilesDeck: TilesDeck,
  horizontalTilesDeck: HorizontalTilesDeck,
  statistics: Statistics,
  showcase: NewShowcase,
  keyFeatures: KeyFeatures,
  contentGridSnippet: ContentGridSnippet,
  fourColumnTextBlock: TextBlockSection,
  quotes: Quotes,
  quoteSingular: QuoteSingular,
  bannerWithCta: Banner,
  faqAccordion: Faq,
  bookACallForm: BookACallForm,
  pricingPlansV3: PricingPlansV3,
  ourWorkSamplesV2: OurWorkSamplesV2,
  jobsList: JobsList,
  visualAssetSection: VisualAssetSection,
  peopleCards: PeopleCardsSection,
  internalLinking: InternalLinkingSection,
  comparisonTable: ComparisonTableSection,
  scrollableImage: ScrollableImage,
  assetsGrid: AssetsGrid,
  exitPopup: ExitPopup,
  eventSchedule: EventSchedule,
  videoWithQuote: VideoWithQuote,
  personasSection: Personas,
  cardsSection: CardsSection,
  dataBlocks: DataBlocks,
  pillars: Pillars,
  keyFeaturesV2: KeyFeaturesV2,
  introduction: Introduction,
  allContentGrid: AllContentGrid,
  ourWorkShowcase: OurWorkShowcase,
  samplesCarousel: SamplesCarousel,
  quotesWithCustomerStories: QuotesWithCustomerStories,
  sectionHeader: SectionHeader,
  preFooterLinks: PreFooterLinks,
  listColumns: ListColumns,
  timeline: Timeline,
  servicesTiles: ServicesTiles,
  pricingPlansV4: PricingPlansV4,
  peopleSection: PeopleSection,
  multiCards: MultiCards,
  vibeSwitch: VibeSwitch,
  slidesComponent: SlidesComponent,
  highlightColumns: HighlightColumns
};

if (process.env.NODE_ENV !== 'production') {
  Object.entries(components).forEach(([key, value]) => {
    value.displayName = `Loadable(${key.charAt(0).toUpperCase()}${key.slice(1)})`;
  });
}

export default components;
