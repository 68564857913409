import colors from './colors';
import textSize from './text-size';
import { ralewayFontFamily } from './font';
import { breakpointsMaxWidth, newBreakpoints } from './breakpoints';
import maxWidth from './max-width';
import edgeSize from './edge-size';
import iconSize from './icon-size';
import spaces from './spaces';

export const theme = {
  global: {
    font: {
      ...textSize.medium,
      family: ralewayFontFamily
    },
    colors: {
      ...colors,
      text: {
        dark: colors.white,
        light: colors.secondary
      },
      heading: {
        dark: colors.white,
        light: colors.secondary
      },
      focus: {
        dark: colors.primary,
        light: colors.secondary
      },
      placeholder: {
        dark: colors.white,
        light: colors['neutral-6']
      },
      icon: {
        dark: colors.white,
        light: colors.secondary
      },
      'light-border': {
        dark: 'rgba(255, 255, 255, 0.1)',
        light: 'rgba(0, 0, 0, 0.1)'
      },
      main: {
        dark: 'white',
        light: 'white'
      },
      space: '#1d1929'
    },
    heading: {
      font: {
        family: ralewayFontFamily
      },
      level: {
        display1: {
          small: {
            size: '36px',
            height: '46px',
            maxWidth: '880px'
          },
          medium: {
            size: '52px',
            height: '64px',
            maxWidth: '880px'
          },
          large: {
            size: '64px',
            height: '88px',
            maxWidth: '880px'
          }
        },
        1: {
          xxsmall: {
            size: '22px',
            height: '30px',
            maxWidth: '880px'
          },
          xsmall: {
            size: '32px',
            height: '42px',
            maxWidth: '880px'
          },
          small: {
            size: '36px',
            height: '46px',
            maxWidth: '880px'
          },
          medium: {
            size: '44px',
            height: '57px',
            maxWidth: '880px'
          },
          large: {
            size: '52px',
            height: '64px',
            maxWidth: '880px'
          }
        },
        2: {
          xxsmall: {
            ...textSize.medium,
            maxWidth: '100%'
          },
          xsmall: {
            size: '22px',
            height: '30px',
            maxWidth: '880px'
          },
          small: {
            size: '26px',
            height: '34px',
            maxWidth: '880px'
          },
          medium: {
            size: '32px',
            height: '41px',
            maxWidth: '880px'
          },
          large: {
            size: '44px',
            height: '57px',
            maxWidth: '880px'
          }
        },
        3: {
          xsmall: {
            ...textSize.large,
            maxWidth: '100%'
          },
          small: {
            size: '24px',
            height: '36px',
            maxWidth: '880px'
          },
          medium: {
            size: '24px',
            height: '36px',
            maxWidth: '880px'
          },
          large: {
            size: '32px',
            height: '41px',
            maxWidth: '880px'
          }
        },
        4: {
          xsmall: {
            ...textSize.medium,
            maxWidth: '100%'
          },
          small: {
            ...textSize.large,
            maxWidth: '100%'
          },
          medium: {
            ...textSize.xlarge,
            maxWidth: '100%'
          },
          large: {
            ...textSize.xxlarge,
            maxWidth: '100%'
          }
        },
        5: {
          small: {
            ...textSize.medium,
            maxWidth: '100%'
          },
          medium: {
            ...textSize.large,
            maxWidth: '100%'
          },
          large: {
            ...textSize.xlarge,
            maxWidth: '100%'
          }
        },
        6: {
          small: {
            ...textSize.small,
            maxWidth: '100%'
          },
          medium: {
            ...textSize.medium,
            maxWidth: '100%'
          },
          large: {
            ...textSize.large,
            maxWidth: '100%'
          }
        }
      }
    },
    size: {
      xxsmall: '48px',
      xsmall: '96px',
      small: '192px',
      medium: '384px',
      large: '768px',
      xlarge: '1152px',
      xxlarge: '1536px',
      full: '100%'
    }
  },
  anchor: {
    color: {
      dark: 'primary-light',
      light: 'primary-medium'
    },
    fontWeight: 500,
    hover: {
      textDecoration: 'underline'
    }
  },
  breakpoints: newBreakpoints,
  breakpointsMaxWidth,
  colors,
  edgeSize,
  text: {
    ...textSize
  },
  heading: {
    font: {
      family: ralewayFontFamily
    }
  },
  fonts: {
    body: ralewayFontFamily,
    heading: ralewayFontFamily
  },
  fontWeights: { body: 400, heading: 700, bold: 700, medium: 600 },
  radii: {},
  maxWidth,
  parts: {
    header: {
      height: '74px'
    }
  },
  icon: {
    size: iconSize
  },
  space: spaces,
  placeholder: {
    colors: {
      base: {
        dark: 'neutral-light',
        light: 'neutral-light'
      },
      pulse: {
        dark: 'neutral-lighter',
        light: '#F2F2F2'
      }
    }
  },
  paragraph: {
    ...textSize,
    medium: {
      ...textSize.medium,
      maxWidth: '580px'
    }
  }
};

export default theme;
