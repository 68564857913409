import { parse } from 'query-string';
import Cookies from 'js-cookie';

let clientConfig = {};

function getCookieDomain(hostName) {
  if (hostName.includes('localhost')) {
    return undefined;
  }

  if (hostName.endsWith('superside.com')) {
    return '.superside.com';
  }

  if (hostName.endsWith('konsus.com')) {
    return '.konsus.com';
  }

  if (hostName.endsWith('.superside.dev')) {
    return '.superside.dev';
  }
}

if (process.browser) {
  const query = parse(location.search);

  const getAndStoreValue = (queryKey: string, cookieKey: string = queryKey): string => {
    let value = query[queryKey];

    if (value) {
      Cookies.set(cookieKey, value, {
        domain: getCookieDomain(window.location.hostname),
        expires: cookieKey === 'k-session' ? 100000 : undefined
      });
    } else {
      value = Cookies.get(cookieKey);
    }

    return value as string;
  };

  const utmSource = getAndStoreValue('utm_source');

  clientConfig = {
    utm: utmSource
      ? {
          utm_source: utmSource,
          utm_medium: getAndStoreValue('utm_medium'),
          utm_campaign: getAndStoreValue('utm_campaign'),
          utm_content: getAndStoreValue('utm_content')
        }
      : null,
    gclid: getAndStoreValue('gclid'),
    dclid: getAndStoreValue('dclid'),
    authToken: getAndStoreValue('authToken', 'k-session')
  };
}

export const config = {
  utm: null,
  gclid: null,
  dclid: null,
  authToken: null,
  apiUrl: process.env.APIURL || 'http://localhost:8080',
  searchUrl: process.env.SEARCHURL || 'http://localhost:9091',
  ...clientConfig
};
