import { useEffect } from 'react';
import { useAtomMutator } from '@konsus/atoms';
import { screenSizeAtom } from './screenSizeAtom';
import { getActiveBreakpoint, mediaQueries } from './getActiveBreakpoint';

export const ScreenSizeUpdater = () => {
  const setScreenSize = useAtomMutator(screenSizeAtom);

  useEffect(() => {
    const handlers = {};

    mediaQueries.forEach(({ name, mediaQuery }) => {
      handlers[name] = (e) => {
        setScreenSize((currentValue) => {
          const nextBreakpoints = {
            ...currentValue.breakpoints,
            [name]: e.matches
          };

          return {
            breakpoint: getActiveBreakpoint(nextBreakpoints),
            breakpoints: nextBreakpoints
          };
        });
      };

      if (mediaQuery) {
        if (mediaQuery.addEventListener) {
          mediaQuery.addEventListener('change', handlers[name]);
        } else if (mediaQuery.addListener) {
          // older Safari
          mediaQuery.addListener(handlers[name]);
        }
      }
    });

    return () => {
      mediaQueries.forEach(({ name, mediaQuery }) => {
        if (mediaQuery.removeEventListener) {
          mediaQuery.removeEventListener('change', handlers[name]);
        } else if (mediaQuery.removeListener) {
          mediaQuery.removeListener(handlers[name]);
        }
      });
    };
  }, [setScreenSize]);

  return null;
};
