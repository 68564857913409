import React from 'react';

export interface AnimatedTextProps {
  mark: {
    words: string[];
    speed?: number;
    _key?: string;
    _type?: 'animatedText';
  };
  node?: {
    id: string;
  };
  children?: React.ReactNode;
}

export const AnimatedText: React.FC<AnimatedTextProps> = (props) => {
  const { mark = {} as AnimatedTextProps['mark'], children } = props;
  const { words = [], speed = 3 } = mark;

  const allWords = [children, ...words].filter(Boolean);
  const animationDuration = allWords.length * speed;

  return (
    <>
      <span
        className='768:h-12 1280:h-15 relative inline-block h-10 w-full'
        data-testid='animated-text-wrapper'
      >
        {allWords.map((word, i) => (
          <span
            className='animate-slide-up-loop absolute inset-0 w-full opacity-0'
            key={`${word}-${i}`}
            style={
              {
                '--slide-up-duration': animationDuration + 's',
                '--slide-up-delay': i * speed + 's'
              } as React.CSSProperties
            }
            data-testid='animated-text-word'
          >
            {word}
          </span>
        ))}
      </span>
      {/* TODO: remove line-break component */}
      <br />
    </>
  );
};
