import React from 'react';
import { Toaster, type ToasterProps, type DefaultToastOptions } from 'react-hot-toast';

export const Toast: React.FC<ToasterProps> = (props) => {
  const { position = 'bottom-center' } = props;

  const toastOptions: DefaultToastOptions = {
    className: 'px-6 py-4 rounded-lg',
    duration: 5000
  };

  return <Toaster {...props} toastOptions={toastOptions} position={position} />;
};
