import React, { useCallback } from 'react';
import { type Toast, toast } from 'react-hot-toast';
import { X } from 'phosphor-react';
import { Button, cn } from '../..';
import { CountdownTimer } from './CountdownTimer';
import { closeIconCva, customToastCva, textCva } from './styles';

export type ToastBodyType = 'success' | 'error' | 'assetInternal' | 'assetExternal';
type RenderCta = (args?: any) => JSX.Element;

export type ToastBodyPropsType = {
  toastId: Toast['id'];
  config: {
    type: ToastBodyType;
    text: string | JSX.Element;
    asset?: React.ReactNode;
    showCta?: boolean;
    renderCta?: RenderCta; // renderCta is a render Prop so you can pass any custom JSX element for the cta if needed.
    onCtaClick?: () => void;
    onAssetClose?: () => void;
    ctaText?: string;
    countDownDuration?: number;
  };
};

export const ToastBody: React.FC<ToastBodyPropsType> = ({ toastId, config }) => {
  const {
    asset,
    ctaText,
    onAssetClose,
    onCtaClick,
    renderCta,
    text,
    type,
    countDownDuration = 5
  } = config;

  const isSuccessOrFailure = type === 'success' || type === 'error';
  const isExternal = type === 'assetExternal' && !!asset;
  const isInternal = type === 'assetInternal' && !!asset;

  const handleToastRemove = useCallback(() => toast.remove(toastId), [toastId]);

  return (
    <div className='768:w-auto relative flex w-full items-center gap-6'>
      {isExternal && (
        <div className='<1024:hidden w-18 h-18 absolute -left-[8%]' data-testid='external-asset'>
          {asset}
        </div>
      )}
      <div className={cn(customToastCva({ type }))}>
        <ToastAsset asset={asset} isExternal={isExternal} isInternal={isInternal} />

        <div className='768:flex-row 768:gap-8 768:items-center <1024:pr-10 flex w-full flex-col items-start justify-between gap-2  p-3 '>
          <div data-testid='body-text' className={cn(textCva({ type }))}>
            {text}
          </div>
          <div className='flex items-center gap-8'>
            <Cta type={type} ctaText={ctaText} onCtaClick={onCtaClick} renderCta={renderCta} />
            <div className='<1024:absolute <1024:right-1 <1024:top-1 flex'>
              {isSuccessOrFailure ? (
                <CountdownTimer
                  className={cn(closeIconCva({ type }))}
                  onClick={handleToastRemove}
                  seconds={countDownDuration}
                  type={type}
                />
              ) : (
                <Button
                  onClick={() => {
                    onAssetClose?.();
                    handleToastRemove();
                  }}
                  variant='iconGhost'
                  size='base'
                  icon={<X weight='bold' />}
                  data-testid='close-button'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ToastAsset: React.FC<{
  asset?: React.ReactNode;
  isExternal: boolean;
  isInternal: boolean;
}> = ({ asset, isExternal, isInternal }) =>
  isExternal ? (
    <div
      className='<1024:hidden bg-bor-tertiary dark absolute left-[-10px] top-[50%] h-4 w-4 origin-top-left -rotate-45 rounded'
      data-testid='external-asset-arrow'
    />
  ) : isInternal ? (
    <div className='<1024:hidden max-h-full object-contain' data-testid='internal-asset'>
      {asset}
    </div>
  ) : null;

const Cta: React.FC<
  Pick<ToastBodyPropsType['config'], 'ctaText' | 'onCtaClick' | 'renderCta' | 'type'>
> = ({ ctaText, onCtaClick, renderCta, type }) => {
  if (!renderCta && !ctaText) {
    return null;
  }

  if (renderCta) {
    return renderCta();
  }

  return (
    <span
      className={cn(textCva({ type, className: 'cursor-pointer font-semibold' }))}
      onClick={onCtaClick}
      data-testid='cta-text'
    >
      {ctaText}
    </span>
  );
};
