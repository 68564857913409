/* eslint-disable no-console */
import { isValidSignature, SIGNATURE_HEADER_NAME } from '@sanity/webhook';

const secret = process.env.SANITY_REVALIDATION_SECRET || '';

export function createHandler(getPagesToRevalidate) {
  return async (req, res) => {
    const body = await readBody(req);
    const signature = req.headers[SIGNATURE_HEADER_NAME];

    if (!isValidSignature(body, signature, secret)) {
      console.error(`Invalid signature`);
      console.error(body);

      res.status(401).json({
        success: false,
        message: 'Invalid signature'
      });

      return;
    }

    const data = JSON.parse(body);
    const pages = await getPagesToRevalidate(data);

    if (pages === null || pages === undefined) {
      return res.status(400).send(`No pages to revalidate`);
    }

    if (typeof pages[Symbol.iterator] !== 'function') {
      return res.status(500).send(`Error while revalidating: not iterable pages`);
    }

    for (const page of pages) {
      console.log(`Revalidating ${page}`);
      try {
        await res.revalidate(page);
      } catch (e) {
        console.log(`Error while revalidating ${page}: `, e);

        return res.status(500).send(`Error while revalidating ${page}`);
      }
    }

    return res.status(200).json({ revalidated: true });
  };
}

// Next.js will by default parse the body, which can lead to invalid signatures
export const defaultConfig = {
  api: {
    bodyParser: false
  }
};

async function readBody(readable) {
  const chunks: any[] = [];

  for await (const chunk of readable) {
    chunks.push(typeof chunk === 'string' ? Buffer.from(chunk) : chunk);
  }

  return Buffer.concat(chunks).toString('utf8');
}
