type AnimationData = object;
type AnimationCachedRequest = { promise: Promise<AnimationData> };
type AnimationCachedData = { data: AnimationData };
type AnimationCacheEntry = AnimationCachedData | AnimationCachedRequest;
type AnimationCache = Map<string, AnimationCacheEntry>;

export const LOTTIE_CACHE: AnimationCache = new Map();

export function flushCache() {
  LOTTIE_CACHE.clear();
}

export function load(url: string): Promise<object> {
  if (LOTTIE_CACHE.has(url)) {
    const cacheHit = LOTTIE_CACHE.get(url);

    if ('data' in cacheHit) {
      return Promise.resolve(cacheHit.data);
    }
    if ('promise' in cacheHit) {
      return cacheHit.promise;
    }
  }

  const promise = fetch(url)
    .then((res) => res.json())
    .then((animationData) => {
      LOTTIE_CACHE.set(url, { data: animationData });

      return animationData;
    });

  LOTTIE_CACHE.set(url, { promise });

  return promise;
}
