import { type GridSelectValue } from './types';

export const isOptionInValue = (optionValue: string | undefined, allValues: GridSelectValue) => {
  if (!(optionValue && allValues)) {
    return false;
  }

  return Array.isArray(allValues)
    ? allValues.some((value) => value === optionValue)
    : allValues === optionValue;
};

export const toggleOptionInMultipleValue = (
  optionValue: string | undefined,
  allValues: GridSelectValue
) => {
  if (!optionValue) {
    return allValues;
  }

  if (!allValues) {
    return [optionValue];
  }

  if (!Array.isArray(allValues)) {
    allValues = [allValues];
  }

  return isOptionInValue(optionValue, allValues)
    ? allValues.filter((oldValue) => oldValue !== optionValue)
    : [...allValues, optionValue];
};
