import React from 'react';
import { Box, type BoxProps } from '../../Layout';
import { cn } from '../../lib';

export const SuperadsLogo: React.FC<BoxProps> = ({ className, ...rest }) => {
  return (
    <Box className={cn('self-start', className)} {...rest}>
      <svg
        className='h-full w-full flex-[0_0_auto]'
        viewBox='0 0 172 40'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M31.2409 9.42482C26.0206 9.42482 21.7955 13.6392 21.7955 18.8462C21.7955 13.6392 17.5669 9.42482 12.3467 9.42482C17.5669 9.42482 21.7955 5.20699 21.7955 0C21.7955 5.20699 26.0206 9.42482 31.2409 9.42482Z'
          fill='#FB523D'
        />
        <path
          d='M10.2311 32.5961C15.4885 32.5961 19.7504 28.3342 19.7504 23.0768C19.7504 17.8195 15.4885 13.5576 10.2311 13.5576C4.97382 13.5576 0.711914 17.8195 0.711914 23.0768C0.711914 28.3342 4.97382 32.5961 10.2311 32.5961Z'
          fill='#766AF0'
        />
        <path
          d='M34.1108 32.548C32.4126 32.548 30.951 32.2794 29.7191 31.7386C28.4872 31.1978 27.5197 30.4616 26.8168 29.5266C26.1104 28.5915 25.7102 27.5379 25.6162 26.3621H30.9579C31.0519 26.7703 31.2259 27.1366 31.4765 27.4576C31.727 27.7821 32.075 28.0333 32.517 28.2112C32.9589 28.3892 33.4566 28.4799 34.0029 28.4799C34.6015 28.4799 35.0852 28.4031 35.4541 28.2461C35.8264 28.0891 36.1048 27.8798 36.2962 27.6181C36.4876 27.3564 36.5816 27.0773 36.5816 26.7912C36.5816 26.3377 36.4424 25.9888 36.1709 25.748C35.896 25.5073 35.4958 25.3119 34.9669 25.1549C34.4414 24.9979 33.808 24.8479 33.0668 24.7048C32.2073 24.5129 31.3512 24.2896 30.5021 24.0384C29.653 23.7872 28.9013 23.4697 28.2436 23.086C27.5859 22.7022 27.0604 22.2032 26.6672 21.5927C26.2739 20.9821 26.0756 20.232 26.0756 19.3458C26.0756 18.2677 26.3748 17.2908 26.9699 16.4151C27.5685 15.5393 28.428 14.845 29.552 14.3287C30.6761 13.8123 32.0367 13.5576 33.641 13.5576C35.9099 13.5576 37.6917 14.06 38.9827 15.0683C40.2738 16.0732 41.0394 17.4304 41.276 19.1295H36.2544C36.1118 18.6515 35.8125 18.2852 35.3566 18.0339C34.9042 17.7827 34.33 17.6571 33.6375 17.6571C32.8476 17.6571 32.249 17.7897 31.8419 18.0514C31.4347 18.3166 31.2329 18.662 31.2329 19.0946C31.2329 19.3807 31.3686 19.6389 31.6435 19.8691C31.9184 20.0959 32.3186 20.2948 32.8441 20.4623C33.3696 20.6297 34.0168 20.7972 34.7789 20.9647C36.237 21.2752 37.4968 21.6101 38.5617 21.9695C39.623 22.3289 40.4548 22.8487 41.0533 23.5325C41.6519 24.2164 41.9372 25.2037 41.9129 26.4982C41.9372 27.6739 41.631 28.7136 40.9976 29.6243C40.3643 30.5349 39.463 31.2466 38.2902 31.763C37.1175 32.2794 35.7185 32.5375 34.0934 32.5375L34.1108 32.548Z'
          fill='currentColor'
        />
        <path
          d='M50.1117 32.5444C48.6047 32.5444 47.3309 32.2339 46.2903 31.6092C45.2497 30.9846 44.4631 30.0703 43.9237 28.8594C43.3842 27.6485 43.1162 26.1689 43.1162 24.4172V13.9902H48.4934V23.8798C48.4934 25.2233 48.7683 26.2457 49.3182 26.9541C49.8681 27.6625 50.7521 28.0149 51.9737 28.0149C52.715 28.0149 53.3763 27.8474 53.9645 27.5124C54.5492 27.1774 55.0051 26.6854 55.3288 26.0398C55.6524 25.3908 55.8125 24.6021 55.8125 23.6669V13.9937H61.1931V32.1152H56.4947L56.0666 29.2398C55.5411 30.2239 54.7754 31.0195 53.7696 31.6302C52.7672 32.2408 51.5456 32.5479 50.1117 32.5479V32.5444Z'
          fill='currentColor'
        />
        <path
          d='M63.1641 39.9999V13.99H67.9471L68.558 16.3608C68.9421 15.8588 69.3855 15.3916 69.8882 14.9592C70.3909 14.5269 70.9984 14.1852 71.7036 13.9342C72.4124 13.6831 73.2433 13.5576 74.2034 13.5576C75.8827 13.5576 77.3629 13.976 78.6477 14.8163C79.929 15.6565 80.9554 16.7862 81.7235 18.2122C82.4916 19.6382 82.8756 21.2595 82.8756 23.0795C82.8756 24.8995 82.4846 26.5243 81.706 27.9468C80.9275 29.3693 79.8871 30.492 78.5953 31.3044C77.3001 32.1167 75.8512 32.5247 74.2418 32.5247C72.9465 32.5247 71.8328 32.298 70.8972 31.8413C69.9615 31.388 69.183 30.75 68.558 29.9376V39.9964H63.1641V39.9999ZM72.8767 27.8213C73.739 27.8213 74.5141 27.6225 75.1984 27.2285C75.8827 26.8346 76.4168 26.2767 76.8008 25.5585C77.1849 24.8402 77.3769 24.0139 77.3769 23.0795C77.3769 22.1451 77.1849 21.3188 76.8008 20.6005C76.4168 19.8823 75.8827 19.314 75.1984 18.8956C74.5141 18.4772 73.7425 18.268 72.8767 18.268C72.0109 18.268 71.2044 18.4772 70.5201 18.8956C69.8358 19.314 69.3017 19.8823 68.9211 20.6005C68.5371 21.3188 68.3451 22.1346 68.3451 23.0446C68.3451 23.9546 68.5371 24.8123 68.9211 25.541C69.3052 26.2732 69.8393 26.8346 70.5201 27.2285C71.2009 27.6225 71.9899 27.8213 72.8767 27.8213Z'
          fill='currentColor'
        />
        <path
          d='M97.0123 31.652C95.3016 32.4016 93.6466 32.7049 92.0404 32.5619C90.4342 32.419 88.9848 31.8647 87.6852 30.9024C86.3891 29.9437 85.3752 28.6294 84.6435 26.9629C83.8944 25.2511 83.5878 23.5707 83.7237 21.9147C83.8596 20.2587 84.4066 18.7631 85.3647 17.4278C86.3229 16.0925 87.6538 15.0501 89.3645 14.3006C91.03 13.5684 92.6466 13.2895 94.2145 13.4639C95.7824 13.6382 97.19 14.189 98.4478 15.1164C99.702 16.0437 100.688 17.3162 101.399 18.9409C101.496 19.1605 101.597 19.408 101.705 19.6869C101.813 19.9658 101.911 20.2587 101.991 20.562L88.612 26.4365L87.3577 23.5742L95.8068 19.8647C95.3573 18.9618 94.7023 18.3691 93.8312 18.0832C92.9602 17.7974 92.0648 17.8566 91.145 18.261C90.4446 18.5678 89.8802 19.0141 89.4551 19.5928C89.0301 20.1715 88.7897 20.8653 88.7409 21.6741C88.6886 22.4795 88.8872 23.3894 89.3297 24.397L89.7617 25.3836C90.0997 26.1506 90.5457 26.7677 91.0997 27.2279C91.6571 27.688 92.2843 27.9635 92.9881 28.0471C93.6919 28.1308 94.427 28.0053 95.1935 27.6671C95.9183 27.3499 96.4478 26.9385 96.7893 26.4365C97.1307 25.9344 97.3328 25.394 97.3955 24.8188L102.423 22.612C102.576 23.8008 102.468 24.9792 102.092 26.1506C101.716 27.322 101.103 28.3888 100.246 29.3545C99.3885 30.3202 98.3119 31.0872 97.0192 31.6555L97.0123 31.652Z'
          fill='currentColor'
        />
        <path
          d='M103.6 32.1153V13.9904H108.38L108.883 17.2992C109.361 16.5313 109.926 15.8717 110.571 15.3202C111.22 14.7687 111.967 14.3359 112.818 14.0253C113.67 13.7147 114.587 13.5576 115.568 13.5576V19.2747H113.771C113.052 19.2747 112.4 19.3514 111.81 19.5085C111.224 19.6656 110.721 19.9238 110.299 20.2799C109.881 20.6393 109.556 21.1245 109.329 21.7353C109.103 22.3461 108.987 23.107 108.987 24.0179V32.1083H103.597L103.6 32.1153Z'
          fill='currentColor'
        />
        <path
          d='M122.165 32.548C120.656 32.548 119.405 32.3072 118.412 31.829C117.419 31.3508 116.687 30.6947 116.22 29.8675C115.753 29.0403 115.52 28.1224 115.52 27.1172C115.52 26.0387 115.788 25.0859 116.328 24.2587C116.868 23.4315 117.711 22.7718 118.858 22.2797C120.008 21.7876 121.454 21.5433 123.204 21.5433H127.584C127.584 20.7266 127.483 20.0564 127.277 19.5294C127.075 19.0024 126.744 18.608 126.291 18.3427C125.835 18.0775 125.214 17.9483 124.423 17.9483C123.583 17.9483 122.879 18.1159 122.304 18.4509C121.729 18.786 121.371 19.313 121.228 20.032H116.056C116.175 18.7371 116.6 17.6098 117.332 16.65C118.06 15.6902 119.036 14.9363 120.259 14.3848C121.479 13.8333 122.879 13.5576 124.462 13.5576C126.187 13.5576 127.682 13.8403 128.95 14.4023C130.218 14.9677 131.205 15.7879 131.912 16.8664C132.619 17.9448 132.971 19.2886 132.971 20.8941V32.1152H128.483L127.838 29.4906C127.573 29.9478 127.263 30.3596 126.904 30.7331C126.545 31.1065 126.127 31.4276 125.646 31.7034C125.169 31.9791 124.643 32.1885 124.068 32.3316C123.493 32.4747 122.859 32.548 122.165 32.548ZM123.493 28.447C124.068 28.447 124.57 28.3527 125.002 28.1608C125.434 27.9688 125.803 27.707 126.113 27.3685C126.424 27.0334 126.681 26.6425 126.887 26.1993C127.089 25.756 127.239 25.2709 127.337 24.7438V24.7089H123.855C123.256 24.7089 122.761 24.7857 122.364 24.9428C121.97 25.0998 121.674 25.3197 121.486 25.6094C121.294 25.8956 121.2 26.2342 121.2 26.6146C121.2 27.023 121.301 27.365 121.506 27.6407C121.709 27.9165 121.984 28.1224 122.332 28.2515C122.681 28.3841 123.068 28.4505 123.5 28.4505L123.493 28.447Z'
          fill='currentColor'
        />
        <path
          d='M142.821 32.5481C141.215 32.5481 139.759 32.1401 138.45 31.3277C137.144 30.5117 136.11 29.3924 135.356 27.9698C134.599 26.5437 134.222 24.9223 134.222 23.1021C134.222 21.282 134.606 19.6222 135.374 18.1961C136.142 16.7699 137.193 15.6437 138.519 14.8173C139.85 13.9909 141.333 13.5794 142.964 13.5794C144.21 13.5794 145.303 13.8026 146.239 14.2454C147.174 14.6883 147.942 15.3298 148.539 16.1667V6.25H153.933V32.1192H149.15L148.539 29.783C148.18 30.2851 147.743 30.7488 147.227 31.1673C146.71 31.5857 146.092 31.9204 145.373 32.1715C144.653 32.4226 143.802 32.5481 142.817 32.5481H142.821ZM144.224 27.8408C145.111 27.8408 145.896 27.6385 146.581 27.2306C147.265 26.8226 147.792 26.2612 148.162 25.5429C148.536 24.8246 148.721 23.9982 148.721 23.0638C148.721 22.1293 148.536 21.3029 148.162 20.5846C147.789 19.8663 147.265 19.3049 146.581 18.8969C145.896 18.489 145.111 18.2867 144.224 18.2867C143.337 18.2867 142.587 18.489 141.902 18.8969C141.218 19.3049 140.684 19.8663 140.3 20.5846C139.916 21.3029 139.724 22.1188 139.724 23.0289C139.724 23.939 139.916 24.7967 140.3 25.5255C140.684 26.2577 141.218 26.8261 141.902 27.2306C142.587 27.6385 143.358 27.8408 144.224 27.8408Z'
          fill='currentColor'
        />
        <path
          d='M163.458 32.548C161.756 32.548 160.288 32.2794 159.057 31.7386C157.823 31.1978 156.853 30.4616 156.145 29.5266C155.438 28.5915 155.037 27.5379 154.942 26.3621H160.295C160.389 26.7703 160.564 27.1366 160.815 27.4576C161.066 27.7821 161.415 28.0333 161.858 28.2112C162.3 28.3892 162.799 28.4799 163.35 28.4799C163.95 28.4799 164.435 28.4031 164.804 28.2461C165.174 28.0891 165.456 27.8798 165.648 27.6181C165.84 27.3529 165.934 27.0773 165.934 26.7912C165.934 26.3377 165.795 25.9888 165.519 25.748C165.244 25.5073 164.843 25.3119 164.316 25.1549C163.79 24.9979 163.155 24.8479 162.412 24.7048C161.551 24.5129 160.693 24.2896 159.842 24.0384C158.991 23.7872 158.234 23.4697 157.579 23.086C156.92 22.7022 156.393 22.2032 155.996 21.5927C155.598 20.9821 155.403 20.232 155.403 19.3458C155.403 18.2677 155.703 17.2908 156.302 16.4151C156.902 15.5393 157.764 14.845 158.89 14.3287C160.016 13.8123 161.38 13.5576 162.987 13.5576C165.265 13.5576 167.05 14.06 168.34 15.0683C169.634 16.0732 170.401 17.4304 170.642 19.1295H165.61C165.467 18.6515 165.167 18.2852 164.714 18.0339C164.257 17.7827 163.681 17.6571 162.987 17.6571C162.196 17.6571 161.6 17.7897 161.192 18.0514C160.784 18.3166 160.581 18.662 160.581 19.0946C160.581 19.3807 160.721 19.6389 160.996 19.8691C161.272 20.0959 161.673 20.2948 162.199 20.4623C162.726 20.6297 163.375 20.7972 164.138 20.9647C165.599 21.2752 166.862 21.6101 167.929 21.9695C168.996 22.3289 169.826 22.8487 170.426 23.5325C171.026 24.2164 171.312 25.2037 171.287 26.4982C171.312 27.6739 171.005 28.7136 170.37 29.6243C169.735 30.5349 168.832 31.2466 167.657 31.763C166.482 32.2794 165.08 32.5375 163.451 32.5375L163.458 32.548Z'
          fill='currentColor'
        />
      </svg>
    </Box>
  );
};
