import { createCache } from './sanityCache';
import { errorToState, NotFoundError } from './errors';
import { getCacheKey } from './getCacheKey';
import {
  type CreateSanityClientParams,
  type RawSanityClient,
  type SanityCache,
  type SanityClient
} from './typing';

function createClient(params: CreateSanityClientParams) {
  const { projectId, dataset, useCdn, withCredentials, apiVersion } = params;

  const clientConfig = {
    apiHost: 'https://api.sanity.io',
    useProjectHostname: true,
    gradientMode: false,
    isPromiseAPI: true,
    isDefaultApi: true,
    url: `https://${projectId}.api.sanity.io/v${apiVersion}`,
    cdnUrl: `https://${projectId}.apicdn.sanity.io/v${apiVersion}`,
    projectId,
    dataset,
    useCdn,
    withCredentials
  };

  const sanityFetch = (query, params) => {
    let queryParams = '';

    if (typeof params === 'object' && params !== null) {
      queryParams = Object.entries(params)
        .map(
          ([key, value]) =>
            `${encodeURIComponent('$' + key)}=${encodeURIComponent(
              typeof value === 'number'
                ? value
                : Array.isArray(value)
                ? `${JSON.stringify(value)}`
                : `"${value}"`
            )}`
        )
        .join('&');
    }

    return (
      fetch(
        `${clientConfig.url}/data/query/${dataset}?query=${encodeURIComponent(
          query
        )}&${queryParams}`
      )
        .then((r) => r.json())
        .then((r) => {
          if (!r.result) {
            // eslint-disable-next-line no-console
            console.log(
              `failed url: ${clientConfig.url}/data/query/${dataset}?query=${encodeURIComponent(
                query
              )}&${queryParams}`
            );
          }

          return r.result;
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.error('SimpleSanityClient error:', err))
    );
  };

  return {
    clientConfig,
    fetch: sanityFetch
  };
}

export function createSimpleClient(
  sanityClientOrConfig: CreateSanityClientParams | RawSanityClient | SanityClient,
  userCache?: SanityCache
): SanityClient {
  if ('cache' in sanityClientOrConfig) {
    return sanityClientOrConfig;
  }

  const cache = userCache || createCache();
  const sanitySdkClient = createClient(sanityClientOrConfig as CreateSanityClientParams);

  async function fetch<QueryParams, ReturnValue>(
    query: string,
    params: QueryParams
  ): Promise<ReturnValue> {
    const cacheKey = getCacheKey(query, params);
    const hit = cache.get(cacheKey);

    if (hit) {
      const { result, error, promise } = hit;

      if (result) {
        return result;
      } else if (promise) {
        return promise;
      } else {
        throw error;
      }
    }

    const sanityQuery: Promise<ReturnValue> = sanitySdkClient.fetch(query, params);
    const promise: Promise<ReturnValue> = sanityQuery
      .then(handleFetchSuccess(cache, cacheKey))
      .catch(handleFetchError(cache, cacheKey));

    cache.set(cacheKey, {
      result: undefined,
      error: undefined,
      promise
    });

    return promise;
  }

  function handleFetchSuccess<ReturnValue>(cache: SanityCache, cacheKey: string) {
    return (result: ReturnValue): ReturnValue => {
      if (result === null) {
        throw new NotFoundError('Not found');
      }
      cache.set(cacheKey, {
        result,
        error: null,
        promise: null
      });

      return result;
    };
  }

  const handleFetchError =
    (cache: SanityCache, cacheKey: string) =>
    (error): Promise<any> => {
      cache.set(cacheKey, {
        result: null,
        error: errorToState(error),
        promise: null
      });
      throw error;
    };

  return {
    cache,
    fetch,
    clientConfig: {
      apiHost: 'https://cdn.sanity.io',
      ...getConfigFromSanityClient(sanityClientOrConfig)
    }
  };
}

function getConfigFromSanityClient(
  sanityClientOrConfig: CreateSanityClientParams | RawSanityClient | SanityClient
) {
  if ('clientConfig' in sanityClientOrConfig) {
    return sanityClientOrConfig.clientConfig;
  }
  if ('projectId' in sanityClientOrConfig) {
    return sanityClientOrConfig;
  }

  return {};
}
