import React, { forwardRef } from 'react';
import { cn } from '../../lib';
import { boxCva } from './styles';
import { type BoxGlassVariant } from '.';

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  glassy?: BoxGlassVariant | { background?: BoxGlassVariant; border?: boolean };
}

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, className, glassy, ...rest }, ref) => {
    const { background: glassyBackground, border: glassyBorder } = glassy
      ? typeof glassy === 'object'
        ? glassy
        : { background: glassy, border: true }
      : { background: undefined, border: undefined };

    return (
      <div
        ref={ref}
        className={cn(boxCva({ glassyBackground, glassyBorder }), className)}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

Box.displayName = 'Box';

export default Box;
