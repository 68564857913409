import React from 'react';
// import { Partytown } from '@builder.io/partytown/react';
// import {
//   GTMScript,
//   GTM_TAG_ASSISTANT_ACCESSOR,
//   GTM_TAG_ASSISTANT_FORWARDER
// } from '@superside-oss/partytown-gtm';
// import { partytownResolveUrl } from '../../utils/thirdPartyScriptUtil';
// import { CallWorker } from './CallWorker';

export const ThirdParty = () => {
  return (
    <>
      {/* <Partytown
        forward={[
          'dataLayer.push',
          '_hsq.push',
          'fbq',
          'ttq.track',
          'ttq.page',
          'ttq.load',
          'hamsue_q.push',
          'hamstr_q.push',
          'forwardCallToWorker',
          GTM_TAG_ASSISTANT_FORWARDER
        ]}
        globalFns={['send_hamstr_q', 'send_hamsue_q']}
        mainWindowAccessors={[GTM_TAG_ASSISTANT_ACCESSOR, 'getWorkerCallResult']}
        resolveUrl={partytownResolveUrl}
      />
      <GTMScript gtmId={process.env.GTM_ID || ''} />
      <CallWorker /> */}
    </>
  );
};
