import { getEdgeSize } from './useSpacingStyles';
import styles from './useBorderRadiusStyles.module.css';

const CORNER_MAP = {
  top: [styles.topLeft, styles.topRight],
  left: [styles.topLeft, styles.bottomLeft],
  bottom: [styles.bottomLeft, styles.bottomRight],
  right: [styles.topRight, styles.bottomRight],
  'top-left': styles.topLeft,
  'top-right': styles.topRight,
  'bottom-left': styles.bottomLeft,
  'bottom-right': styles.bottomRight
} as any;

export const useBorderRadiusStyles = (props: any, theme: any) => {
  if (!props.round) {
    return {};
  }

  let className, size;

  switch (typeof props.round) {
    case 'boolean':
      className = styles.all;
      // FIXME: Use default radius from theme
      size = getEdgeSize('small', theme);
      break;
    case 'string':
      className = styles.all;
      size = props.round === 'full' ? '50%' : getEdgeSize(props.round, theme);
      break;
    case 'object':
      if (!props.round.size || !props.round.corner) {
        return {};
      }

      className = CORNER_MAP[props.round.corner];
      size = getEdgeSize(props.round.size, theme);
  }

  return {
    className,
    style: {
      '--radius': size
    }
  };
};
