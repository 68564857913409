import type React from 'react';

/*
 * Finds the closest scrollable parent.
 * It's necessary when element may be rendered in a modal or on Storybook, where the scrollable parent is not the window.
 */
export const getScrollableParent = (element: HTMLElement | null) => {
  while (element && element !== document.body) {
    const { overflowY } = window.getComputedStyle(element);

    if (overflowY === 'scroll' || overflowY === 'auto') {
      return element;
    }
    element = element.parentElement;
  }

  return window;
};

export const normalizeScale = (value: number) => {
  let scale = 0;

  // Normalize value to a 0-1 range first
  scale = (value * 2) / 100;
  scale = Math.max(0, Math.min(scale, 1));

  // Transform scale using an easing function to make it ramp up faster
  scale = 1 - Math.pow(1 - scale, 2);
  // Transform scale into a range of 0.4 to 1
  scale = 0.4 + scale * 0.6;

  return scale;
};

/* Checks for overlapping of the progress thumb and the items
 * If it's overlapping, makes the active border visible by slowly increasing its scale */
export const checkOverlappingItems = ({
  activeBordersRef,
  progressThumbRef
}: {
  progressThumbRef: React.RefObject<HTMLDivElement>;
  activeBordersRef: React.RefObject<HTMLDivElement[]>;
}) => {
  const thumb = progressThumbRef.current?.getBoundingClientRect();

  activeBordersRef.current?.forEach((item) => {
    if (item && thumb) {
      const target = item.getBoundingClientRect();
      const overlap = thumb.bottom > target.top;

      if (overlap) {
        const distance = thumb.bottom - target.top;
        const scale = normalizeScale(distance);

        item.style.setProperty('--scale', scale.toString());
      } else {
        item.style.setProperty('--scale', '0');
      }
    }
  });
};

/* Adds a marginBottom to the progressBar so it ends at the last label */
export const capProgressBarHeight = ({
  activeBordersRef,
  contentsRef,
  progressBarRef,
  callback
}: {
  activeBordersRef: React.RefObject<HTMLDivElement[]>;
  contentsRef: React.RefObject<HTMLSpanElement[]>;
  progressBarRef: React.RefObject<HTMLDivElement>;
  callback: () => void;
}) => {
  const lastContent = contentsRef.current?.[contentsRef.current.length - 1];
  const lastLabel = activeBordersRef.current?.[activeBordersRef.current.length - 1];
  const progressBar = progressBarRef.current;

  if (lastContent && lastLabel && progressBar) {
    const marginBottom = lastContent.clientHeight - lastLabel.clientHeight;

    progressBarRef.current?.style.setProperty('--marginBottom', `${marginBottom}px`);
  }

  callback();
};
