import atom, { useAtomValue } from '@konsus/atoms';

const initialState = {
  flexGap: true
};

export const browserFeaturesAtom = atom(initialState);

export const useBrowserFeatures = () => {
  return useAtomValue(browserFeaturesAtom);
};
