import { type ComponentStyles, composeStyles } from '../utils';
import { getEdgeSize } from './useSpacingStyles';

export const tokens = {
  grow: {
    name: '--grow',
    val: 'var(--grow)'
  },
  shrink: {
    name: '--shrink',
    val: 'var(--shrink)'
  },
  basis: {
    name: '--basis',
    val: 'var(--basis)'
  },
  flexGap: {
    name: '--gap',
    val: 'var(--gap)'
  }
};

const styles = {
  alignSelf: {
    start: 'self-start',
    center: 'self-center',
    end: 'self-end',
    stretch: 'self-stretch'
  },
  alignItems: {
    start: 'items-start',
    center: 'items-center',
    end: 'items-end',
    stretch: 'items-stretch',
    baseline: 'items-baseline'
  },
  alignContent: {
    start: 'content-start',
    center: 'content-center',
    end: 'content-end',
    stretch: 'content-stretch',
    between: 'content-between',
    around: 'content-around'
  },
  justifyContent: {
    start: 'justify-start',
    center: 'justify-center',
    end: 'justify-end',
    stretch: 'justify-stretch',
    between: 'justify-between',
    around: 'justify-around',
    evenly: 'justify-evenly'
  },
  direction: {
    row: 'flex-row',
    column: 'flex-col',
    'column-reverse': 'flex-col-reverse',
    'row-reverse': 'flex-row-reverse'
  },
  flexGrow: {
    zero: 'grow-0',
    one: 'grow',
    dynamic: 'grow-[var(--grow)]'
  },
  flexShrink: {
    zero: 'shrink-0',
    one: 'shrink',
    dynamic: 'shrink-[var(--shrink)]'
  },
  flexDefault: 'grow-0 shrink-0 basis-auto',
  flexBasisDynamic: 'basis-[var(--basis)]',
  flexBasisAuto: 'basis-auto',
  flexBasis: {
    auto: 'basis-auto',
    full: 'basis-full',
    '1/2': 'basis-1/2',
    '1/4': 'basis-1/4',
    '2/4': 'basis-2/4',
    '3/4': 'basis-3/4',
    '1/3': 'basis-1/3',
    '2/3': 'basis-2/3'
  },
  wrap: 'flex-wrap',
  wrapReverse: 'flex-wrap-reverse',
  gap: 'gap-[var(--gap)]'
};

export type AlignSelf = keyof typeof styles.alignSelf;
export type AlignItems = keyof typeof styles.alignItems;
export type AlignContent = keyof typeof styles.alignContent;
export type JustifyContent = keyof typeof styles.justifyContent;
export type FlexDirection = keyof typeof styles.direction;

export const createUsePropBasedStyles =
  (key: string, classNames: { [key: string]: string }, defaultClassName?: string) =>
  (props): ComponentStyles => {
    const prop = props[key];

    if (prop && classNames[prop]) {
      return {
        className: classNames[prop]
      };
    }

    return {
      className: defaultClassName
    };
  };

export const useAlignStyle = createUsePropBasedStyles('align', styles.alignItems);
export const useAlignContentStyle = createUsePropBasedStyles('alignContent', styles.alignContent);
export const useAlignSelfStyle = createUsePropBasedStyles('alignSelf', styles.alignSelf);
export const useJustifyStyle = createUsePropBasedStyles('justify', styles.justifyContent);
export const useDirectionStyle = createUsePropBasedStyles(
  'direction',
  styles.direction,
  styles.direction.column
);

const FLEX_MAP = {
  true: [1, 1],
  false: [0, 0],
  grow: [1, 0],
  shrink: [0, 1]
};

export type FlexStyle = boolean | keyof typeof FLEX_MAP | { grow?: number; shrink?: number };
export type FlexBasis = keyof typeof styles.flexBasis | string;

const getGrowShrink = (flex) => {
  if (typeof flex === 'undefined') {
    return [0, 0];
  }

  switch (typeof flex) {
    case 'object':
      return [flex.grow || 0, flex.shrink || 0];
    case 'boolean':
      return FLEX_MAP[flex ? 'true' : 'false'];
    case 'string':
      return FLEX_MAP[flex];
  }
};

interface GrowShrinkStyle extends ComponentStyles {
  className: string;
}

const getGrowShrinkStyles = (
  value: number | string,
  classNames: { [key: string]: string },
  token
): GrowShrinkStyle => {
  switch (value) {
    case 0:
      return { className: classNames.zero };
    case 1:
      return { className: classNames.one };
    default:
      return {
        style: {
          [token.name]: value.toString()
        },
        className: classNames.dynamic
      };
  }
};

export const useFlexGrowShrinkStyle = (props): ComponentStyles => {
  if (typeof props.flex === 'undefined' && !props.basis) {
    return {
      className: styles.flexDefault
    };
  }

  const [grow, shrink] = getGrowShrink(props.flex);
  const growStyles = getGrowShrinkStyles(grow, styles.flexGrow, tokens.grow);
  const shrinkStyles = getGrowShrinkStyles(shrink, styles.flexShrink, tokens.shrink);

  return {
    className: [growStyles.className, shrinkStyles.className],
    style: {
      ...growStyles.style,
      ...shrinkStyles.style
    }
  };
};

const useFlexBasisStyle = (props, theme) => {
  if (props.basis) {
    if (styles.flexBasis[props.basis]) {
      return {
        className: styles.flexBasis[props.basis]
      };
    }

    return {
      className: styles.flexBasisDynamic,
      styles: {
        [tokens.basis.name]: theme.global.size[props.basis] || props.basis
      }
    };
  }

  return {};
};

export const useWrapStyle = (props) => {
  if (props.wrap === true) {
    return {
      className: styles.wrap
    };
  } else if (props.wrap === 'reverse') {
    return {
      className: styles.wrapReverse
    };
  }

  return {};
};

export const useGapStyle = (props, theme) => {
  if (props.gap) {
    return {
      style: {
        [tokens.flexGap.name]: getEdgeSize(props.gap, theme)
      },
      className: styles.gap
    };
  }

  return {};
};

export const useFlexStyles = composeStyles(
  useDirectionStyle,
  useAlignStyle,
  useAlignContentStyle,
  useAlignSelfStyle,
  useJustifyStyle,
  useGapStyle,
  useFlexBasisStyle,
  useFlexGrowShrinkStyle,
  useWrapStyle
);
