// Slawek:
// This module used to support passing overflow as object with different
// values for overflow-x/y, however, it seems that we never used this.
// I intentionally removed this for simplicity.

const values = [
  'overflow-auto',
  'overflow-hidden',
  'overflow-clip',
  'overflow-visible',
  'overflow-scroll'
];

export const useOverflowStyles = (props: any) => {
  if (typeof props.overflow === 'string') {
    const className = `overflow-${props.overflow}`;

    if (values.includes(className)) {
      return {
        className
      };
    }
  }

  return {};
};
