import { useEffect } from 'react';
import { getBodyClassNames, type MaybePageProps } from '@konsus/utils';

export function useBackgroundImage(pageProps: MaybePageProps) {
  const bodyClassName = getBodyClassNames(pageProps);

  useEffect(() => {
    if (document.body.className !== bodyClassName) {
      document.body.className = bodyClassName;
    }
  }, [bodyClassName]);
}
