// Based on https://github.com/sanity-io/webhook-toolkit
import crypto from 'crypto';

const MINIMUM_TIMESTAMP = 1609459200000;
const SIGNATURE_HEADER_REGEX = /^t=(\d+)[, ]+v1=([^, ]+)$/;

const SIGNATURE_HEADER_NAME = 'sanity-webhook-signature';

export function assertValidSignature(stringifiedPayload: any, signature: any, secret: any) {
  const { timestamp } = decodeSignatureHeader(signature);
  const encoded = encodeSignatureHeader(stringifiedPayload, timestamp, secret);

  if (signature !== encoded) {
    throw new Error('Signature is invalid');
  }
}

export function isValidSignature(stringifiedPayload: any, signature: any, secret: any) {
  try {
    assertValidSignature(stringifiedPayload, signature, secret);

    return true;
  } catch (err) {
    return false;
  }
}

export function assertValidRequest(request: any, secret: any) {
  const signature = request.headers[SIGNATURE_HEADER_NAME];

  if (Array.isArray(signature)) {
    throw new Error('Multiple signature headers received');
  }

  if (typeof signature !== 'string') {
    throw new Error('Request contained no signature header');
  }

  if (typeof request.body === 'undefined') {
    throw new Error('Request contained no parsed request body');
  }

  const payload = JSON.stringify(request.body);

  assertValidSignature(payload, signature, secret);
}

export function isValidRequest(request: any, secret: any) {
  try {
    assertValidRequest(request, secret);

    return true;
  } catch (err) {
    return false;
  }
}

export function encodeSignatureHeader(stringifiedPayload: any, timestamp: any, secret: any) {
  const signature = createHS256Signature(stringifiedPayload, timestamp, secret);

  return `t=${timestamp},v1=${signature}`;
}

export function decodeSignatureHeader(signaturePayload: any) {
  const [, timestamp, hashedPayload] = signaturePayload.trim().match(SIGNATURE_HEADER_REGEX) || [];

  if (!timestamp || !hashedPayload) {
    throw new Error('Invalid signature payload format');
  }

  return {
    timestamp: parseInt(timestamp, 10),
    hashedPayload
  };
}

function createHS256Signature(stringifiedPayload: any, timestamp: any, secret: any) {
  if (!secret || typeof secret !== 'string') {
    throw new Error('Invalid secret provided');
  }

  if (!stringifiedPayload) {
    throw new Error('Can not create signature for empty payload');
  }

  if (typeof stringifiedPayload !== 'string') {
    throw new Error('Payload must be a JSON-encoded string');
  }

  if (typeof timestamp !== 'number' || isNaN(timestamp) || timestamp < MINIMUM_TIMESTAMP) {
    throw new Error(
      'Invalid signature timestamp, must be a unix timestamp with millisecond precision'
    );
  }

  const hmac = crypto.createHmac('sha256', secret);
  const signaturePayload = `${timestamp}.${stringifiedPayload}`;
  const signature = hmac.update(signaturePayload, 'utf8').digest();

  return toBase64Url(signature);
}

export function toBase64Url(str: any) {
  return Buffer.from(str, 'utf-8')
    .toString('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}
