import { useEffect, useState, type RefObject } from 'react';
import throttle from 'lodash/throttle';

// configured after some trial and error
export const MINIMUM_HEIGHT = 520;

interface VerticalScrollOptions {
  ref?: RefObject<HTMLElement> | null;
  minHeight?: number;
}

export function useVerticalScroll({
  ref = null,
  minHeight = MINIMUM_HEIGHT
}: VerticalScrollOptions = {}) {
  const [isDown, setIsDown] = useState<boolean>(false);

  useEffect(() => {
    let currentY = 0;
    const element: Window | HTMLElement = ref?.current || window;

    const scrollY = () =>
      element === window ? window.scrollY : (element as HTMLElement).scrollTop;

    const scrollHandler = throttle(() => {
      if (scrollY() > minHeight && scrollY() < currentY) {
        setIsDown(true);
      } else {
        setIsDown(false);
      }
      currentY = scrollY();
    }, 350);

    element.addEventListener('scroll', scrollHandler);

    return () => {
      element.removeEventListener('scroll', scrollHandler);
    };
  }, [ref, minHeight]);

  return isDown;
}
