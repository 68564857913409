import { type AnalyticClient } from './types';

export function createGtmClient(): AnalyticClient {
  return {
    track(event, props) {
      // @ts-expect-error
      window.dataLayer?.push(Object.assign({ event }, props));
    }
  };
}
