export const useGridStyle = ({
  xlgCol = 12,
  lgCol = 12,
  mdCol = 8,
  smCol = 4,
  smGap = '16px',
  mdGap = smGap,
  lgGap = '20px',
  xlgGap = '24px'
}: any) => {
  return {
    className: [
      'gap-[var(--cols-sm-gap)] grid grid-cols-[repeat(var(--cols-sm),minmax(0,1fr))] 1024:gap-[var(--cols-lg-gap)] 1024:grid-cols-[repeat(var(--cols-lg),minmax(0,1fr))] 1440:gap-[var(--cols-xlg-gap)] 768:gap-[var(--cols-md-gap)] 768:grid-cols-[repeat(var(--cols-md),minmax(0,1fr))]'
    ],
    style: {
      '--cols-xlg': xlgCol,
      '--cols-lg': lgCol,
      '--cols-md': mdCol,
      '--cols-sm': smCol,
      '--cols-xlg-gap': xlgGap,
      '--cols-lg-gap': lgGap,
      '--cols-md-gap': mdGap,
      '--cols-sm-gap': smGap
    }
  };
};

const boxColumns = {
  xlg: {
    name: '--box-xlg',
    val: 'var(--box-xlg)'
  },
  lg: {
    name: '--box-lg',
    val: 'var(--box-lg)'
  },
  md: {
    name: '--box-md',
    val: 'var(--box-md)'
  },
  sm: {
    name: '--box-sm',
    val: 'var(--box-sm)'
  }
};

export const useGridColumnsStyle = ({ xlgCol, lgCol, mdCol, smCol }: any) => {
  const finalStyles: any = {
    className: [],
    style: {}
  };

  if (xlgCol) {
    finalStyles.className.push('1440:col-[span_var(--box-xlg)]');
    finalStyles.style[boxColumns.xlg.name] = xlgCol;
  }

  if (lgCol) {
    finalStyles.className.push('1024:col-[span_var(--box-lg)]');
    finalStyles.style[boxColumns.lg.name] = lgCol;
  }

  if (mdCol) {
    finalStyles.className.push('768:col-[span_var(--box-md)]');
    finalStyles.style[boxColumns.md.name] = mdCol;
  }

  if (smCol) {
    finalStyles.className.push('col-[span_var(--box-sm)]');
    finalStyles.style[boxColumns.sm.name] = smCol;
  }

  return finalStyles;
};
