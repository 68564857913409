import { composeStyles, type StyleFn } from '../utils';
import { edgeSize, type EdgeSize, edgeSizeVars } from '../theme/edge-size';

type Sides = 'horizontal' | 'vertical' | 'top' | 'right' | 'bottom' | 'left';

export type Spacing = EdgeSize | { [key in Sides]?: EdgeSize | string }; // | false;

export const marginTokens = {
  all: {
    name: '--m',
    val: 'var(--m)'
  },
  top: {
    name: '--mt',
    val: 'var(--mt)'
  },
  bottom: {
    name: '--mb',
    val: 'var(--mb)'
  },
  left: {
    name: '--ml',
    val: 'var(--ml)'
  },
  right: {
    name: '--mr',
    val: 'var(--mr)'
  }
};

export const marginStyles = {
  all: 'm-[var(--m)]',
  top: 'mt-[var(--mt)]',
  bottom: 'mb-[var(--mb)]',
  left: 'ml-[var(--ml)]',
  right: 'mr-[var(--mr)]',
  none: 'm-0'
};

export const paddingTokens = {
  all: {
    name: '--p',
    val: 'var(--p)'
  },
  top: {
    name: '--pt',
    val: 'var(--pt)'
  },
  bottom: {
    name: '--pb',
    val: 'var(--pb)'
  },
  left: {
    name: '--pl',
    val: 'var(--pl)'
  },
  right: {
    name: '--pr',
    val: 'var(--pr)'
  }
};

export const paddingStyles = {
  all: 'p-[var(--p)]',
  top: 'pt-[var(--pt)]',
  bottom: 'pb-[var(--pb)]',
  left: 'pl-[var(--pl)]',
  right: 'pr-[var(--pr)]',
  none: 'p-0'
};

const sides = ['top', 'right', 'bottom', 'left'];

export const getEdgeSize = (value, responsive) => {
  if (edgeSize[value]) {
    return responsive !== false ? edgeSizeVars[value].val : edgeSize[value];
  }

  return value;
};

const setStyles = (output, className, cssVar, propValue, responsive) => {
  output.className.push(className);
  output.style[cssVar] = getEdgeSize(propValue, responsive);
};

const spaceStylesFactory =
  (classNames, tokens, baseReactProp, allowResponsive): StyleFn =>
  (props) => {
    // const { all, top, right, bottom, left, none } = cssProps;
    const prop = props[baseReactProp];
    const responsive = allowResponsive && props.responsive;

    const finalStyles = {
      className: [],
      style: {}
    };

    if (!prop) {
      return {
        className: classNames.none
      };
    }

    if (typeof prop === 'string') {
      setStyles(finalStyles, classNames.all, tokens.all.name, prop, responsive);
    }

    if (typeof prop?.vertical === 'string') {
      setStyles(finalStyles, classNames.top, tokens.top.name, prop.vertical, responsive);
      setStyles(finalStyles, classNames.bottom, tokens.bottom.name, prop.vertical, responsive);
    }

    if (typeof prop?.horizontal === 'string') {
      setStyles(finalStyles, classNames.left, tokens.left.name, prop.horizontal, responsive);
      setStyles(finalStyles, classNames.right, tokens.right.name, prop.horizontal, responsive);
    }

    sides.forEach((side) => {
      if (typeof prop[side] === 'string') {
        setStyles(finalStyles, classNames[side], tokens[side].name, prop[side], responsive);
      }
    });

    return finalStyles;
  };

export const usePadStyles: StyleFn = spaceStylesFactory(paddingStyles, paddingTokens, 'pad', true);
export const useMarginStyles: StyleFn = spaceStylesFactory(
  marginStyles,
  marginTokens,
  'margin',
  false
);
export const useSpaceStyles = composeStyles(usePadStyles, useMarginStyles);
