import { useState, useEffect, useCallback } from 'react';
import { useAnalytics } from '@konsus/analytics/lib';

const TRACKED_SCROLL_POSITIONS = [10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 100];

export function useScrollTracking() {
  const { track } = useAnalytics();

  const [scrollProgress, setScrollProgress] = useState(0);
  const [, setVisitedScrollPositions] = useState([]);

  const scrollHandler = useCallback(() => {
    const scrollTop = window.scrollY;
    const docHeight = document.body.querySelector('div').offsetHeight;
    const winHeight = window.innerHeight;

    const scrollPercent = scrollTop / (docHeight - winHeight);
    const scrollPercentRounded = Math.round((scrollPercent * 100) / 5) * 5;

    setScrollProgress(scrollPercentRounded);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  const runScrollEvent = useCallback(
    (percent) => {
      setVisitedScrollPositions((visitedScrollPositions) =>
        TRACKED_SCROLL_POSITIONS.filter((trackedScrollProgress) => {
          // Position was reached before, don't track it again
          if (visitedScrollPositions.includes(trackedScrollProgress)) {
            return true;
          }

          const shouldTrack = percent && scrollProgress >= trackedScrollProgress;

          // Position is reached for the first time
          if (shouldTrack) {
            track(`Page scroll ${trackedScrollProgress}%`);

            return true;
          }

          // Position was not reached yet
          return false;
        })
      );
    },
    [scrollProgress, track]
  );

  useEffect(() => {
    runScrollEvent(scrollProgress);
  }, [runScrollEvent, scrollProgress]);
}
