import type React from 'react';
import { useEffect } from 'react';
import { useResponsiveContext } from '@konsus/superside-kit';

export const useHorizontalScroll = (ref: React.RefObject<HTMLDivElement>) => {
  const screenSize = useResponsiveContext();
  const isMobile = screenSize === 'small' || screenSize === 'xsmall';

  useEffect(() => {
    function handleMouseMove(e: MouseEvent) {
      if (!ref.current || isMobile) {
        return;
      }
      const windowInnerWidth = window.innerWidth;
      const containerScrollWidth = ref.current.scrollWidth;

      ref.current.style.left = `${(
        (windowInnerWidth - containerScrollWidth) *
        (e.pageX / windowInnerWidth)
      ).toFixed(3)}px`;
    }

    window.addEventListener('mousemove', handleMouseMove);

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [ref, isMobile]);
};
