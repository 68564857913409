export type EdgeSize =
  | 'none'
  | 'hair'
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge'
  | 'xxxxxlarge'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

export const edgeSizeVars = {
  none: { name: '--edge-none', val: 'var(--edge-none)' },
  hair: { name: '--edge-hair', val: 'var(--edge-hair)' },
  xxsmall: { name: '--edge-xxsmall', val: 'var(--edge-xxsmall)' },
  xsmall: { name: '--edge-xsmall', val: 'var(--edge-xsmall)' },
  small: { name: '--edge-small', val: 'var(--edge-small)' },
  medium: { name: '--edge-medium', val: 'var(--edge-medium)' },
  large: { name: '--edge-large', val: 'var(--edge-large)' },
  xlarge: { name: '--edge-xlarge', val: 'var(--edge-xlarge)' },
  xxlarge: { name: '--edge-xxlarge', val: 'var(--edge-xxlarge)' },
  xxxlarge: { name: '--edge-xxxlarge', val: 'var(--edge-xxxlarge)' },
  xxxxlarge: { name: '--edge-xxxxlarge', val: 'var(--edge-xxxxlarge)' },
  xxxxxlarge: { name: '--edge-xxxxxlarge', val: 'var(--edge-xxxxxlarge)' },
  1: { name: '--edge-1', val: 'var(--edge-1)' },
  2: { name: '--edge-2', val: 'var(--edge-2)' },
  3: { name: '--edge-3', val: 'var(--edge-3)' },
  4: { name: '--edge-4', val: 'var(--edge-4)' },
  5: { name: '--edge-5', val: 'var(--edge-5)' },
  6: { name: '--edge-6', val: 'var(--edge-6)' },
  7: { name: '--edge-7', val: 'var(--edge-7)' },
  8: { name: '--edge-8', val: 'var(--edge-8)' },
  9: { name: '--edge-9', val: 'var(--edge-9)' },
  10: { name: '--edge-10', val: 'var(--edge-10)' },
  11: { name: '--edge-11', val: 'var(--edge-11)' },
  12: { name: '--edge-12', val: 'var(--edge-12)' }
};

export const edgeSize = {
  none: '0px',
  hair: '1px',
  xxsmall: '4px',
  xsmall: '8px',
  small: '16px',
  medium: '24px',
  large: '32px',
  xlarge: '48px',
  xxlarge: '64px',
  xxxlarge: '88px',
  xxxxlarge: '128px',
  xxxxxlarge: '168px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '20px',
  6: '24px',
  7: '28px',
  8: '32px',
  9: '60px',
  10: '80px',
  11: '120px',
  12: '180px',
  responsiveBreakpoint: 'medium'
};

export const smallEdgeSize = {
  none: '0px',
  hair: '1px',
  xxsmall: '2px',
  xsmall: '4px',
  small: '8px',
  medium: '16px',
  large: '24px',
  xlarge: '32px',
  xxlarge: '48px',
  xxxlarge: '64px',
  xxxxlarge: '88px',
  xxxxxlarge: '128px',
  1: edgeSize['1'],
  2: edgeSize['2'],
  3: edgeSize['3'],
  4: edgeSize['4'],
  5: edgeSize['5'],
  6: edgeSize['6'],
  7: edgeSize['7'],
  8: '32px',
  9: '48px',
  10: '60px',
  11: '92px',
  12: '136px'
};

export default edgeSize;
