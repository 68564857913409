import { useEffect, useState } from 'react';

type AtomValue =
  | object
  | boolean
  | string
  | number
  | null
  | undefined
  | any[]
  | { [key: string]: any };
type AtomSetterArg<T extends AtomValue> = ((currentValue: T) => T) | T;
type AtomSetter<T extends AtomValue> = (nextValue: AtomSetterArg<T>) => void;
type AtomValueSetter<T extends AtomValue> = (nextValue: T) => void;

export interface Atom<T extends AtomValue> {
  value: T;
  setValue: AtomSetter<T>;
  subscribe: (setter: AtomValueSetter<T>) => void;
  unsubscribe: (setter: AtomValueSetter<T>) => void;
}

export function atom<T extends AtomValue>(initialValue: T): Atom<T> {
  const subscribers = new Set<AtomValueSetter<T>>();
  let value: T = initialValue;

  return Object.freeze({
    get value() {
      return value;
    },
    setValue: (nextValue: AtomSetterArg<T>) => {
      const finalNextValue: T = typeof nextValue === 'function' ? nextValue(value) : nextValue;

      value = finalNextValue;
      subscribers.forEach((fn) => fn(finalNextValue));
    },
    subscribe: (fnToAdd: AtomValueSetter<T>) => {
      subscribers.add(fnToAdd);
    },
    unsubscribe: (fnToRemove: AtomValueSetter<T>) => {
      subscribers.delete(fnToRemove);
    }
  });
}

export function useAtom<T extends AtomValue>(atom: Atom<T>): [T, AtomSetter<T>] {
  const [value, setValue] = useState<T>(atom.value);

  useEffect(() => {
    atom.subscribe(setValue);

    // This updates component state when atom was replaced with a different atom
    setValue(atom.value);

    return () => {
      atom.unsubscribe(setValue);
    };
  }, [atom]);

  return [value, atom.setValue];
}

export function useAtomValue<T extends AtomValue>(atom: Atom<T>) {
  return useAtom<T>(atom)[0];
}

export function useAtomMutator<T extends AtomValue>(atom: Atom<T>): AtomSetter<T> {
  // This can probably be replaced with a simple atom.setValue
  return useAtom<T>(atom)[1];
}

export default atom;
