import { SANITY_PROJECT_ID, SANITY_DATASET } from 'adapter/sanity';
import { isPreview } from './const';
import { createAsyncClient } from './createAsyncClient';
import { type SanityClient } from './typing';

const defaultClient: SanityClient = createAsyncClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET,
  apiVersion: '2021-08-31',
  useCdn: !isPreview,
  withCredentials: isPreview
});

function configureDefaultClient(options = {}) {
  defaultClient.sanityClient?.config(options);
}

export { defaultClient, configureDefaultClient };
