export function getCacheKey<QueryParams>(query: string, params: QueryParams): string {
  return (
    query +
    ':' +
    Object.keys(params || {})
      .sort()
      .map<string>((key) => params[key])
      .join(',')
  );
}
