import { useEffect, useState } from 'react';
import Router from 'adapter/next/router';

export const usePagesTransitionTracker = ({ track }: { track: Function }) => {
  const [pageTransitionInProgress, setPageTransitionInProgress] = useState(false);

  useEffect(() => {
    const handleRouteChangeStart = (_url: string, { shallow }: { shallow: boolean }) => {
      setPageTransitionInProgress(!shallow);
    };
    const handleRouteChangeComplete = () => {
      setPageTransitionInProgress(false);
      track('Loaded a Page', { landingPage: false });
    };

    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [track]);

  return {
    pageTransitionInProgress
  };
};
