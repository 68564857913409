import React, { createContext } from 'react';
import { type QuoteProps } from '../blocks/Quotes/types';

const GlobalContext = createContext<{
  utm?: { [key: string]: string };
  gclid?: string;
  useGeoLocation?: () => any;
  quotesData?: { quote: QuoteProps }[];
  track?: (event, props?) => void;
  blockByType?;
  horizontalTilesDeckListData?: { items: any };
} | null>(null);

export const GlobalContextProvider = ({ children, ...rest }) => {
  return <GlobalContext.Provider value={rest}>{children}</GlobalContext.Provider>;
};

export default GlobalContext;
