import { SANITY_PROJECT_ID, SANITY_TOKEN } from 'adapter/sanity';
import { sanityConfig } from './sanityConfig';

export const previewSanityConfig = {
  ...sanityConfig,
  projectId: SANITY_PROJECT_ID,
  token: SANITY_TOKEN,
  useCdn: false,
  ignoreBrowserTokenWarning: true
};
