import React from 'react';
import { cn } from '../../lib';
import { Text, type TextProps } from '.';

export interface SuperscriptProps extends TextProps {}

export const Superscript: React.FC<SuperscriptProps> = ({ className, size = 'sm', ...rest }) => {
  return (
    <Text className={cn('tracking-15 font-semibold uppercase', className)} size={size} {...rest} />
  );
};
