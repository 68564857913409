import React from 'react';
import Link from 'adapter/next/link';
import { cn } from '@superside/ui';
import { type Spacing, Text, type TextProps } from '@konsus/superside-kit';

interface SuperscriptProps extends TextProps {
  content: string | JSX.Element;
  link?: string;
  overflow?: boolean;
  bold?: boolean;
  margin?: Spacing;
}

export const Superscript: React.FC<SuperscriptProps> = (props) => {
  const {
    content,
    link,
    overflow = false,
    margin = { bottom: '2' },
    size = 'small',
    className,
    ...rest
  } = props;

  return (
    <Text
      className={cn(
        'tracking-15',
        overflow && 'overflow-hidden text-ellipsis whitespace-nowrap',
        className
      )}
      margin={margin}
      size={size}
      uppercase
      semibold
      {...rest}
    >
      {link ? (
        <Link legacyBehavior href={link} passHref>
          <a
            className={
              '[.light_&,.light-variant-1_&,.light-variant-2_&]:text-dark text-light inline-block no-underline [.light_&,.light-variant-1_&,.light-variant-2_&_&:hover]:text-indigo-500'
            }
          >
            {content}
          </a>
        </Link>
      ) : (
        content
      )}
    </Text>
  );
};

export default Superscript;
