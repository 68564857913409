import { useEffect, useState } from 'react';
import { Api } from '@konsus/api-utils';

let location = null;
let promise = null;

export const useGeoLocation = () => {
  const [state, setState] = useState(location);

  useEffect(() => {
    if (!location) {
      promise || (promise = Api.get('geo-location'));
      promise.then((res) => {
        location = res.location;
        setState(location);
      });
    }
  }, []);

  return state;
};
