const iconSize = {
  xxsmall: '12px',
  xsmall: '16px',
  small: '24px',
  medium: '32px',
  large: '48px',
  xlarge: '64px',
  xxlarge: '96px'
};

export default iconSize;
