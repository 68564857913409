export const breakpoints = ['460px', '576px', '768px', '992px', '1200px'];

export const grommetBreakpoints = {
  xsmall: 460,
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
  xxlarge: null
};

export const mobileFirstBreakpoints = {
  xsmall: '0px',
  small: '461px',
  medium: '577px',
  large: '769px',
  xlarge: '993px',
  xxlarge: '1201px'
};

export const newBreakpoints = {
  xsmall: '0px',
  small: '360px',
  medium: '768px',
  large: '1024px',
  desktop: '1280px',
  xlarge: '1440px',
  xmlarge: '1650px',
  xxlarge: '1920px'
};

export const breakpointsMaxWidth = {
  small: '359px',
  medium: '767px',
  large: '1023px',
  desktop: '1279px',
  xlarge: '1439px',
  xmlarge: '1649px',
  xxlarge: '1919px'
};

export default breakpoints;
