import type React from 'react';
import { createElement, forwardRef } from 'react';
import { cn } from '@superside/ui';
import { composeStyles } from '../../utils';
import {
  type Spacing,
  type TextAlign,
  useMarginStyles,
  useColorStyles,
  useFontWeightStyles,
  useTextAlignStyles,
  useTextSizeStyles,
  useWidthStyles
} from '../../styles';
import { omitStyleProps } from '../Box';
import theme from '../../theme/theme';
import { alternativeFontStyle, uppercaseStyle, semiBoldStyles, boldStyles } from './textStyles';

export interface TextProps {
  as?: string;
  uppercase?: boolean;
  alternative?: boolean;
  semibold?: boolean;
  bold?: boolean;
  size?: string | { fontSize?: string; lineHeight?: string };
  weight?: string | number;
  margin?: Spacing;
  display?: 'inline' | 'block' | 'inline-block';
  color?: any;
  ref?: any;
  style?;
  className?: string;
  maxWidth?: string;
  textAlign?: TextAlign;
  dangerouslySetInnerHTML?: { __html: string };
  children?: React.ReactNode;
}

const useStyles = composeStyles(
  useMarginStyles,
  useColorStyles,
  useTextAlignStyles,
  useTextSizeStyles,
  useFontWeightStyles,
  useWidthStyles
);

export const Text: React.FC<TextProps> = forwardRef((props, ref) => {
  const {
    as = 'span',
    size = 'medium',
    weight, // = 'normal',
    display = 'inline',
    maxWidth,
    color,
    margin,
    children,
    textAlign,
    alternative,
    uppercase,
    semibold,
    bold,
    ...rest
  } = props;

  const { style, className } = useStyles(
    { size, margin, textAlign, color, weight, maxWidth },
    theme
  );

  const finalProps = {
    ...omitStyleProps(rest),
    ref,
    display,
    color,
    style: {
      ...props.style,
      ...style
    },
    className: cn(
      rest.className,
      semibold && semiBoldStyles,
      bold && boldStyles,
      uppercase && uppercaseStyle,
      alternative && alternativeFontStyle,
      className
    )
  };

  return createElement(as || 'span', finalProps, children);
});

Text.displayName = 'Text';
