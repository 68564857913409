import React, { type FC } from 'react';
import { X } from 'phosphor-react';
import { cn } from '../../lib';

type CountdownTimerPropsType = {
  seconds: number;
  onClick?: () => void;
  className?: string;
  type: 'success' | 'error' | 'asset';
};

export const CountdownTimer: FC<CountdownTimerPropsType> = ({
  seconds = 5,
  onClick,
  className
}) => {
  const size = 32;
  const radius = size / 2;
  const circumference = size * Math.PI;

  return (
    <div className='cursor-pointer' onClick={onClick} data-testid='countdown-timer'>
      <div className='relative m-auto flex h-8 w-8 items-center justify-center'>
        <X weight='bold' className={cn('text-dark dark:text-light <1024:text-light', className)} />
        <svg
          className='absolute left-0 top-0 h-full w-full overflow-visible'
          style={{
            transform: 'rotateY(-180deg) rotateZ(-90deg)'
          }}
        >
          <circle
            strokeDasharray={circumference}
            r={radius}
            cx={radius}
            cy={radius}
            fill='none'
            strokeLinecap='round'
            strokeWidth={2}
            className='dark:stroke-white-100 stroke-black-500 <1024:stroke-white-100'
            onAnimationEnd={onClick}
            style={{
              animation: `countdownAnimation ${seconds}s linear forwards`
            }}
          ></circle>
        </svg>
      </div>
    </div>
  );
};
