import { type ComponentStyles, composeStyles, type StyleFn } from '../utils';

// Width
const widthTokens = {
  size: {
    name: '--w',
    val: 'var(--w)'
  },
  min: {
    name: '--min-w',
    val: 'var(--min-w)'
  },
  max: {
    name: '--max-w',
    val: 'var(--max-w)'
  }
};

const widthStyles = {
  size: 'w-[var(--w)]',
  min: 'min-w-[var(--min-w)]',
  max: 'max-w-[var(--max-w)]'
};

const widthPropsAccessor = propsAccessorFactory(['width', 'minWidth', 'maxWidth']);

// Height
const heightTokens = {
  size: {
    name: '--h',
    val: 'var(--h)'
  },
  min: {
    name: '--min-h',
    val: 'var(--min-h)'
  },
  max: {
    name: '--max-h',
    val: 'var(--max-h)'
  }
};

const heightStyles = {
  size: 'h-[var(--h)]',
  min: 'min-h-[var(--min-h)]',
  max: 'max-h-[var(--max-h)]'
};

const heightPropsAccessor = propsAccessorFactory(['height', 'minHeight', 'maxHeight']);

export const getSize = (size, theme) => theme.global.size[size] || size;

const setStyles = (output: ComponentStyles, theme, className: string, cssVar: string, value) => {
  (output.className as string[]).push(className);
  output.style[cssVar] = getSize(value, theme);
};

const sizeStyleFactory =
  (classNames, tokens, propsAccessor): StyleFn =>
  (props, theme) => {
    const [size, min, max] = propsAccessor(props);

    const finalStyles = {
      className: [],
      style: {}
    };

    if (typeof max === 'string') {
      setStyles(finalStyles, theme, classNames.max, tokens.max.name, max);
    }

    if (typeof min === 'string') {
      setStyles(finalStyles, theme, classNames.min, tokens.min.name, min);
    }

    // prop?.[size] || typeof prop === 'string'
    if (size) {
      setStyles(finalStyles, theme, classNames.size, tokens.size.name, size);
    }

    return finalStyles;
  };

export const useFillStyle: StyleFn = (props) => {
  switch (props.fill) {
    case true:
      return {
        style: {
          [widthTokens.size.name]: '100%',
          [heightTokens.size.name]: '100%'
        },
        className: [widthStyles.size, heightStyles.size]
      };
    case 'horizontal':
      return {
        style: {
          [widthTokens.size.name]: '100%'
        },
        className: widthStyles.size
      };
    case 'vertical':
      return {
        style: {
          [heightTokens.size.name]: '100%'
        },
        className: heightStyles.size
      };
  }

  return {};
};

export const useWidthStyles = sizeStyleFactory(widthStyles, widthTokens, widthPropsAccessor);
export const useHeightStyles = sizeStyleFactory(heightStyles, heightTokens, heightPropsAccessor);

export const useSizeStyles = composeStyles(useWidthStyles, useHeightStyles, useFillStyle);

function propsAccessorFactory([size, min, max]) {
  return (props) => {
    const sizeValue = typeof props[size] === 'string' ? props[size] : null;

    const minValue =
      typeof props[size]?.min === 'string'
        ? props[size].min
        : typeof props[min] === 'string'
        ? props[min]
        : null;

    const maxValue =
      typeof props[size]?.max === 'string'
        ? props[size].max
        : typeof props[max] === 'string'
        ? props[max]
        : null;

    return [sizeValue, minValue, maxValue];
  };
}
