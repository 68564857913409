import getBrightness from 'getbrightness';

export const isDark = (color) => {
  if (color) {
    if (typeof color === 'object' && 'dark' in color) {
      return color.dark;
    }

    if (color === 'transparent-on-dark') {
      return true;
    }
    if (color === 'transparent-on-light') {
      return false;
    }

    return getBrightness(color) <= 0.6;
  }

  return false;
};
