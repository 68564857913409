import React, { Children, createElement, forwardRef, useMemo } from 'react';
import { isFragment } from 'react-is';
import { omit } from 'ramda';
import { cn } from '@superside/ui';
import { DarkContextProvider, useBrowserFeatures } from '../../providers';
import theme from '../../theme/theme';
import { composeStyles, hexToRgb, isDark, normalizeColor } from '../../utils';
import {
  type AlignContent,
  type AlignItems,
  type AlignSelf,
  type FlexDirection,
  type FlexStyle,
  type JustifyContent,
  type Spacing,
  tokens as flexTokens,
  useBackgroundStyles,
  useBorderRadiusStyles,
  useBorderStyles,
  useColorStyles,
  useFlexStyles,
  useOverflowStyles,
  useGridColumnsStyle,
  useSizeStyles,
  useSpaceStyles
} from '../../styles';
import colors from '../../theme/colors';
import { type EdgeSize } from '../../theme/edge-size';
import * as boxStyles from './boxStyles';
import Gap from './Gap';

const useStyles = composeStyles(
  useBackgroundStyles,
  useBorderRadiusStyles,
  useBorderStyles,
  useColorStyles,
  useFlexStyles,
  useOverflowStyles,
  useGridColumnsStyle,
  useSizeStyles,
  useSpaceStyles
);

export interface BoxProps {
  as?: string;
  align?: AlignItems;
  alignSelf?: AlignSelf;
  alignContent?: AlignContent;
  className?: string;
  children?: any;
  justify?: JustifyContent;
  direction?: FlexDirection;
  flex?: FlexStyle;
  gap?: string | EdgeSize;
  margin?: Spacing;
  pad?: Spacing;
  height?: any;
  width?: any;
  maxWidth?: any;
  maxHeight?: any;
  minWidth?: any;
  minHeight?: any;
  background?: any;
  border?: any;
  bg?: any;
  color?: any;
  fill?: any;
  id?: string;
  overflow?: any;
  round?: any;
  sx?: any;
  tag?: string;
  wrap?: boolean;
  flexBox?: boolean;
  responsive?: boolean;
  zIndex?: any;
  onClick?: () => void;
  elevation?: EdgeSize;
  ref?: any;
}

export const Box: React.FC<JSX.IntrinsicElements['div'] & BoxProps> = forwardRef((props, ref) => {
  const { as, flexBox = true, background, children, tag, ...rest } = props;
  const dark = useMemo(() => isDark(colors[background] || background), [background]);

  const finalColor = useMemo(() => {
    if (theme.global?.colors[background] || background) {
      return dark ? theme.global.colors.text.dark : theme.global.colors.text.light;
    }

    return null;
  }, [background, dark]);

  const finalBackground = useMemo(() => {
    if (typeof background === 'object') {
      const { color, opacity } = background || {};

      if (opacity) {
        const rgbObj = hexToRgb(normalizeColor(color, theme, dark) || color);

        return `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${opacity})`;
      }

      return theme.global.colors[color] || color;
    }

    return theme.global.colors[background] || background;
  }, [background, dark]);

  const { className, style } = useStyles(
    { ...props, background: finalBackground, color: finalColor },
    theme
  );

  const finalProps = {
    ...omitStyleProps(rest),
    ref,
    style: { ...style, ...props.style },
    className: cn(className, boxStyles.base, flexBox && boxStyles.flexBase, props.className)
  };

  const finalChildren = useFlexGapPolyfillWhenNeeded(
    children,
    props.direction,
    props.wrap,
    finalProps.style
  );

  const box = createElement(as || tag || 'div', finalProps, finalChildren);

  if (className.indexOf('dark') || className.indexOf('light')) {
    return <DarkContextProvider isDark={dark}>{box}</DarkContextProvider>;
  }

  return box;
});

Box.displayName = 'Box';

export const RelativeBox: React.FC<JSX.IntrinsicElements['div'] & BoxProps> = forwardRef(
  (props, ref) => {
    const { zIndex, className, style, ...rest } = props;

    const finalStyle = useMemo(() => {
      if (zIndex) {
        return { ...style, zIndex };
      }

      return { ...style };
    }, [zIndex, style]);

    return (
      <Box
        ref={ref as any}
        className={cn(boxStyles.relative, className)}
        style={finalStyle}
        {...rest}
      />
    );
  }
);

RelativeBox.displayName = 'RelativeBox';

export const omitStyleProps = omit([
  'align',
  'alignContent',
  'alignSelf',
  'backgroundImage',
  'backgroundVariant',
  'basis',
  'border',
  'bottom',
  'color',
  'current',
  'direction',
  'display',
  'fill',
  'flex',
  'fluid',
  'gap',
  'height',
  'isDropdown',
  'justify',
  'left',
  'lg',
  'loading',
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'maxWidth',
  'md',
  'originalHeight',
  'originalWidth',
  'overflow',
  'pad',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'position',
  'round',
  'sm',
  'sticky',
  'top',
  'width',
  'withLogo',
  'wrap',
  'xlg',
  'xs',
  'xsmWidth',
  'xxlg',
  'zIndex',
  'isInline'
]);

function useFlexGapPolyfillWhenNeeded(children, direction = 'column', wrap, style) {
  const { flexGap } = useBrowserFeatures();
  const gap = style[flexTokens.flexGap.name];

  if (flexGap || !gap) {
    return children;
  }

  const injectGap = (children) => {
    let finalChildren = [];

    Children.forEach(children, (child, index) => {
      if (index > 0) {
        finalChildren.push(
          <Gap key={`flex-gap-${index}`} direction={direction} gap={gap} wrap={wrap} />
        );
      }

      if (isFragment(child)) {
        finalChildren = [...finalChildren, ...injectGap(child.props.children)];
      } else {
        finalChildren.push(child);
      }
    });

    return finalChildren;
  };

  return injectGap(children);
}

export default Box;
