export const ButtonVariants = {
  primary: 'primary',
  outline: 'outline',
  isolated: 'isolated',
  secondary: 'secondary',
  ghost: 'ghost',
  iconFill: 'iconFill',
  iconGhost: 'iconGhost',
  secondaryGhost: 'secondaryGhost',
  glowy: 'glowy',
  flashy: 'flashy'
} as const;

export const ButtonSizes = {
  lg: 'lg',
  md: 'md',
  base: 'base',
  sm: 'sm'
} as const;

export type ButtonVariantType = keyof typeof ButtonVariants;
export type ButtonSizeType = keyof typeof ButtonSizes;
